import { Reducer } from '@reduxjs/toolkit';

import { EnableIdCardAction, Types } from 'actions/idCard';

import { createReducer } from 'utils/redux';

export interface IdCardState {
  isEnabled: boolean;
}

const INITIAL_STATE = {
  isEnabled: true
};

const handlers = {
  [Types.ENABLE_ID_CARD]: (state = INITIAL_STATE, action: EnableIdCardAction) => ({
    ...state,
    isEnabled: action.status
  })
};

export const idCard: Reducer<IdCardState, EnableIdCardAction> = createReducer(
  INITIAL_STATE,
  handlers
);
