export const mfaTypes = {
  text: 'TEXT',
  html: 'HTML',
  chiptan: 'CHIPTAN',
  phototan: 'PHOTOTAN',
  decoupled: 'DECOUPLED'
} as const;

export const registrationStatus = {
  ok: 'ok',
  mfa: 'mfa',
  failure: 'failure',
  pending: 'pending',
  invalid: 'invalid',
  consent: 'consent'
} as const;

export const carouselStyle = {
  static: 'static',
  modal: 'modal'
} as const;

export const userInitialState = {
  new: 'new',
  active: 'active',
  registered: 'registered',
  registeredFromLink: 'registered_from_link',
  registeredDemo: 'registered_demo'
} as const;
