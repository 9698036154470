enum NODE_ENVIRONMENTS {
  development = 'development',
  production = 'production'
}

export const isDevelopment = () => {
  return (
    import.meta.env.MODE === NODE_ENVIRONMENTS.development ||
    window.location.origin.indexOf('-dev') > -1 // TODO: Replace environment check
  );
};

export const isProduction = () => {
  const { NODE_ENV } = window.serverEnvConfig;
  return NODE_ENV === NODE_ENVIRONMENTS.production;
};

export const enableManualSwitchOnEnv = () => {
  const { VITE_ENABLE_MANUAL_SWITCH, MODE } = import.meta.env;
  if (MODE === NODE_ENVIRONMENTS.development && VITE_ENABLE_MANUAL_SWITCH === 'true') return true;

  const { REACT_APP_ENABLE_MANUAL_SWITCH } = window.serverEnvConfig;
  return REACT_APP_ENABLE_MANUAL_SWITCH === 'true';
};
