import { select, takeEvery } from 'redux-saga/effects';
import { history } from 'routes';

import { Types } from 'actions/account';
import { fetchSaga } from 'api/fetchSaga';
import { appSelector } from 'selectors/appSelectors';
import { personalDetailsResponseData } from 'selectors/personalDetailsSelector';
import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

import { checkIsMagicLinkRequiredUserDataIncomplete, getRouteForMagicLink } from 'utils/magicLink';

const { apis } = config;
const { REGISTER_IBAN, GET_IBAN } = Types;

export function* registerIban({ payload, action }) {
  const params = {
    path: apis.accountIban,
    method: httpMethods.PUT,
    data: {
      user: { ...payload }
    }
  };

  action.setSubmitting(true);

  const response = yield fetchSaga({
    actionType: REGISTER_IBAN,
    params,
    payload
  });

  action.setSubmitting(false);

  const { isRegisteredFromLink } = yield select(appSelector);
  const { tenantSettings } = yield select(tenantSettingsSelector);
  const userData = yield select(personalDetailsResponseData);

  const isIncompleteMagicLinkRequiredUserData =
    checkIsMagicLinkRequiredUserDataIncomplete(userData);

  if (response) {
    const nextRoute = getRouteForMagicLink({
      tenantSettings,
      isIncompleteMagicLinkRequiredUserData,
      isRegisteredFromLink
    });

    nextRoute && history.push(nextRoute);
  }

  return response;
}

export function* getIban() {
  const params = {
    path: apis.accountIban,
    method: httpMethods.GET
  };

  return yield fetchSaga({
    actionType: GET_IBAN,
    fields: 'user',
    params
  });
}

export function* ibanSagas() {
  yield takeEvery(REGISTER_IBAN, registerIban);
  yield takeEvery(GET_IBAN, getIban);
}
