import clsx from 'clsx';
import { connect, ConnectedProps } from 'react-redux';
import { t } from 'locale';

import { Lock } from 'components/Icons/Lock';
import { getAppBarMobileClasses } from 'components/AppBar/appBarMobileUtils';
import { MarkDown } from 'components/MarkDown';
import { RootState } from 'store/store';
import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';

import { localeKeys } from 'enums/localeKeys';

import { parseStringTranslationKeys } from 'utils/stringTemplate';

import { FooterStyled } from './FooterStyled';

interface FooterProps extends FooterPropsFromRedux {
  isProtectedRouteLayout: boolean;
}

export const FooterComponent = ({ isProtectedRouteLayout, tenantSettings }: FooterProps) => {
  const classes = getAppBarMobileClasses();
  const { mobileAsk: { footerHtml } = {} } = tenantSettings;

  return (
    <div
      className={clsx(classes.content, {
        [classes.contentShift]: isProtectedRouteLayout
      })}
    >
      <FooterStyled>
        {footerHtml ? (
          <MarkDown
            htmlTag="div"
            text={parseStringTranslationKeys(footerHtml)}
            className="tenant-custom-footer"
            data-id="tenant-custom-footer"
          />
        ) : null}
        <div className="powered-by-footer" data-id="powered-by-footer">
          <div className="lock-icon-container">
            <hr />
            <Lock />
            <hr />
          </div>
          <span>{t(localeKeys.footerEncryption)}</span>
          <span>{t(localeKeys.footerSecureServer)}</span>
          <span>{t(localeKeys.footerAudits)}</span>
          <MarkDown
            text={t(localeKeys.footerText)}
            htmlTag="div"
            className="powered-by-footer-text"
            data-id="powered-by-footer-text"
          />
          <span data-id="powered-by-footer-app-version">ASK V2.0</span>
        </div>
      </FooterStyled>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { tenantSettings } = tenantSettingsSelector(state);

  return { tenantSettings };
};

const connector = connect(mapStateToProps, null);

type FooterPropsFromRedux = ConnectedProps<typeof connector>;

export const Footer = connector(FooterComponent);
