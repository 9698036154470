import { Route } from 'routes';

import { LoaderLayout } from 'components/Loaders/LoaderLayout';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const incomingPaymentsRoutes: Record<string, Route> = {
  incomingPayments: {
    id: 'incomingPayments',
    title: 'navigationIncomingPayments',
    path: '/incoming-payments',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./IncomingPaymentsContainer')
    })
  },
  incomingPaymentItem: {
    id: 'incomingPaymentItem',
    title: 'navigationIncomingPayments',
    basePath: '/incoming-payments/',
    path: '/incoming-payments/:id',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./IncomingPaymentDetailsContainer')
    })
  }
};

export default incomingPaymentsRoutes;
