import { Route } from 'routes';

import Loadable from 'utils/Loadable';

const autoLoginRoutes: Record<string, Route> = {
  autoLogin: {
    id: 'autoLogin',
    title: 'Registration',
    path: '/(auto-login|register_user)',
    isProtected: false,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('./AutoLoginContainer')
    })
  }
};

export default autoLoginRoutes;
