import dayjs from 'dayjs';
import isUndefined from 'lodash/isUndefined';
import { put, takeEvery, select } from 'redux-saga/effects';
import { history, routes } from 'routes';
import { t } from 'locale';

import { Creators, Types } from 'actions/mandates';
import { Creators as SnackbarCreators } from 'actions/snackbar';
import { fetchSaga } from 'api/fetchSaga';
import { getDirectDebits } from 'selectors/directDebitsSelectors';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';
import { localeKeys } from 'enums/localeKeys';

import { formatDate } from 'utils/date';
import { generateFieldName, getFieldName } from 'utils/formField';
import { goBackToPreviousPage } from 'utils/history';

const { apis } = config;
const {
  NOTIFY_DIRECT_DEBIT,
  NOTIFY_INCOMING_PAYMENTS,
  GET_INCOMING_PAYMENTS,
  GET_DIRECT_DEBITS,
  SAVE_INCOMING_PAYMENTS_PARTNER,
  SAVE_DIRECT_DEBIT_PARTNER,
  IGNORE_DIRECT_DEBIT,
  IGNORE_INCOMING_PAYMENT,
  MARK_DIRECT_DEBIT_AS_DONE,
  MARK_DIRECT_DEBIT_AS_UNDONE,
  MARK_INCOMING_PAYMENT_AS_DONE,
  MARK_INCOMING_PAYMENT_AS_UNDONE,
  NOTIFY_MANUAL_MANDATE,
  DELETE_MANDATE,
  GET_MANDATE_HINT
} = Types;

export function* getCreditorMandates() {
  yield getDirectDebitsMandates();
  yield getIncomingPaymentsMandates();
}

export function* getIncomingPaymentsMandates() {
  const params = {
    path: apis.incomingPayments
  };

  return yield fetchSaga({
    params,
    actionType: GET_INCOMING_PAYMENTS,
    fields: 'incomingPayments'
  });
}

export function* getDirectDebitsMandates() {
  const params = {
    path: apis.directDebits
  };

  return yield fetchSaga({
    params,
    actionType: GET_DIRECT_DEBITS,
    fields: 'debitMandates'
  });
}

export function* createManualMandateAndNotify({
  payload: { values, mandate, isDirectDebit, suppressNotify },
  action
}) {
  const {
    city,
    country,
    countryCode,
    number,
    postalCode,
    street,
    manualEmail,
    name: creditorName,
    department,
    id: suggestedPaymentPartnerId
  } = values;

  const { paymentPartnerId, displayTitle: title } = mandate;

  const params = {
    path: apis.createMandate,
    method: httpMethods.POST,
    data: {
      mandate: {
        title: title || creditorName,
        debit: isDirectDebit,
        creditor: { id: paymentPartnerId || suggestedPaymentPartnerId },
        manualEmail,
        manualAddress: {
          ...mandate.address,
          city,
          country,
          countryCode,
          number,
          postalCode,
          street,
          department
        }
      }
    }
  };

  action.setSubmitting(true);

  const response = yield fetchSaga({
    params,
    actionType: NOTIFY_MANUAL_MANDATE,
    fields: 'mandate'
  });

  if (response) {
    const type = isDirectDebit ? NOTIFY_DIRECT_DEBIT : NOTIFY_INCOMING_PAYMENTS;

    const customerId = values[generateFieldName('customer_id', mandate.id)];
    const affectedContractId = values[generateFieldName('affected_contracts', mandate.id)];

    if (customerId) {
      values[generateFieldName('customer_id', response.id)] = customerId;
    }

    if (affectedContractId) {
      values[generateFieldName('affected_contracts', response.id)] = affectedContractId;
    }

    // createMandateAndNotify function is called in normal switch flow and manual switch flow
    // in manual switch flow mandate has to be just created and not notified therefore "suppressNotify" flag
    if (suppressNotify) {
      history.replace(
        type === NOTIFY_DIRECT_DEBIT ? routes.directDebits.path : routes.incomingPayments.path
      );
      yield getMandatesByActionType(type, NOTIFY_DIRECT_DEBIT);
    } else {
      yield notifyMandate({
        payload: {
          mandate: response,
          values
        },
        action,
        type
      });
    }
  }

  action.setSubmitting(false);
}

export function* notifyMandate({ payload: { values, mandate }, action, type }) {
  const { id: mandateId, paymentPartnerId, additionalFields, displayTitle, address } = mandate;

  const { validityDate, includeBirthDate, id: suggestedPaymentPartnerId } = values;

  const affectedContracts = getUpdatedAdditionalFields(
    [
      {
        name: 'affected_contracts'
      }
    ],
    values,
    mandateId
  )[0];

  const affectedContractsValue = affectedContracts && affectedContracts.value;

  const updatedAdditionalFields = getUpdatedAdditionalFields(additionalFields, values, mandateId);

  const { toSign } = yield select(getDirectDebits);
  const isToSignDirectDebitMandate = toSign.some(toSignMandate => toSignMandate.id === mandate.id);

  const params = {
    path: apis.mandatesTransfer(mandateId),
    method: httpMethods.POST,
    data: {
      ...(!isToSignDirectDebitMandate && {
        creditor: {
          id: paymentPartnerId || suggestedPaymentPartnerId,
          displayTitle,
          name: displayTitle,
          address
        }
      }),
      letter: {
        additionalFields: updatedAdditionalFields,
        includeBirthDate: includeBirthDate || false
      },
      validityDate: validityDate || formatDate(dayjs()),
      ...(affectedContractsValue && {
        mandate: { affectedContracts: affectedContractsValue }
      })
    }
  };

  action.setSubmitting(true);

  const response = yield fetchSaga({
    params,
    actionType: type,
    fields: 'mandate'
  });

  action.setSubmitting(false);

  if (response) {
    history.replace(
      type === NOTIFY_DIRECT_DEBIT ? routes.directDebits.path : routes.incomingPayments.path
    );
    yield put(SnackbarCreators.triggerSnack(t(localeKeys.notificationSent)));
    yield getMandatesByActionType(type, NOTIFY_DIRECT_DEBIT);
  }

  return response;
}

export function* updateMandate({ payload: { values, mandate }, action, type }) {
  const {
    city,
    country,
    countryCode,
    number,
    postalCode,
    street,
    manualEmail,
    name: title,
    department,
    id: suggestedPaymentPartnerId
  } = values;
  const { id: mandateId, paymentPartnerId, debit, classicCategory } = mandate;

  action.setSubmitting(true);

  const params = {
    path: apis.mandates(mandateId),
    method: httpMethods.PUT,
    data: {
      mandate: {
        title,
        debit,
        manualAddress: {
          ...mandate.address,
          city,
          country,
          countryCode,
          number,
          postalCode,
          street,
          department
        },
        manualEmail,
        classicCategory,
        creditor: { id: paymentPartnerId || suggestedPaymentPartnerId }
      }
    }
  };

  const response = yield fetchSaga({
    params,
    actionType: type,
    fields: 'mandate'
  });

  action.setSubmitting(false);

  if (response) {
    yield put(SnackbarCreators.triggerSnack(t(localeKeys.paymentPartnerDetailsUpdated)));
    yield getMandatesByActionType(type, SAVE_DIRECT_DEBIT_PARTNER);
  }
}

export function* ignoreMandate({ id, type }) {
  const params = {
    path: apis.mandatesIgnore(id),
    method: httpMethods.PATCH
  };

  const response = yield fetchSaga({
    params,
    actionType: type,
    fields: 'mandate'
  });

  if (response) {
    yield put(SnackbarCreators.triggerSnack(t(localeKeys.ignoredInfoText)));
    yield getMandatesByActionType(type, IGNORE_DIRECT_DEBIT);
  }

  return response;
}

export function* deleteMandate({ id, type }) {
  const params = {
    path: apis.mandates(id),
    method: httpMethods.DELETE
  };

  const response = yield fetchSaga({
    params,
    actionType: type,
    fields: 'mandate'
  });

  if (response) {
    const isDirectDebit = window.location.pathname.includes('direct-debits');
    const actionType = isDirectDebit ? NOTIFY_DIRECT_DEBIT : NOTIFY_INCOMING_PAYMENTS;
    yield put(SnackbarCreators.triggerSnack(t(localeKeys.deleteInfoText)));
    yield getMandatesByActionType(actionType, NOTIFY_DIRECT_DEBIT);
  }

  return response;
}

export function* markMandateAsDone({ id, type }) {
  const params = {
    path: apis.mandatesMarkDone(id),
    method: httpMethods.PATCH
  };

  const response = yield fetchSaga({
    params,
    actionType: type,
    fields: 'mandate'
  });

  if (response) {
    goBackToPreviousPage();
    yield getMandatesByActionType(type, MARK_DIRECT_DEBIT_AS_DONE);
  }

  return response;
}

export function* markMandateAsUndone({ id, type }) {
  const params = {
    path: apis.mandatesMarkUndone(id),
    method: httpMethods.PATCH
  };

  const response = yield fetchSaga({
    params,
    actionType: type,
    fields: 'mandate'
  });

  if (response) {
    yield put(SnackbarCreators.triggerSnack(t(localeKeys.dontIgnoreInfoText)));
    yield getMandatesByActionType(type, MARK_DIRECT_DEBIT_AS_UNDONE);
  }

  return response;
}

function* getMandatesByActionType(actualActionType, directDebitsActionType) {
  yield put(
    actualActionType === directDebitsActionType
      ? Creators.getDirectDebits()
      : Creators.getIncomingPayments()
  );
}

function getUpdatedAdditionalFields(
  additionalFields,
  formValues,
  mandateId = null // Used for notify all since every mandate needs generic form field names for additional fields
) {
  return additionalFields
    .filter(({ name }) => {
      return !isUndefined(formValues[getFieldName(name, mandateId)]);
    })
    .map(field => ({
      ...field,
      value: formValues[getFieldName(field.name, mandateId)]
    }));
}

export function* getPaymentPartnerHint({ id }) {
  const params = {
    path: apis.hints(id),
    method: httpMethods.GET
  };

  return yield fetchSaga({
    params,
    actionType: GET_MANDATE_HINT
  });
}

export function* getMandateSagas() {
  yield takeEvery(NOTIFY_DIRECT_DEBIT, notifyMandate);
  yield takeEvery(NOTIFY_INCOMING_PAYMENTS, notifyMandate);
  yield takeEvery(SAVE_DIRECT_DEBIT_PARTNER, updateMandate);
  yield takeEvery(SAVE_INCOMING_PAYMENTS_PARTNER, updateMandate);
  yield takeEvery(GET_INCOMING_PAYMENTS, getIncomingPaymentsMandates);
  yield takeEvery(GET_DIRECT_DEBITS, getDirectDebitsMandates);
  yield takeEvery(IGNORE_DIRECT_DEBIT, ignoreMandate);
  yield takeEvery(IGNORE_INCOMING_PAYMENT, ignoreMandate);
  yield takeEvery(DELETE_MANDATE, deleteMandate);
  yield takeEvery(MARK_DIRECT_DEBIT_AS_DONE, markMandateAsDone);
  yield takeEvery(MARK_DIRECT_DEBIT_AS_UNDONE, markMandateAsUndone);
  yield takeEvery(MARK_INCOMING_PAYMENT_AS_DONE, markMandateAsDone);
  yield takeEvery(MARK_INCOMING_PAYMENT_AS_UNDONE, markMandateAsUndone);
  yield takeEvery(NOTIFY_MANUAL_MANDATE, createManualMandateAndNotify);
  yield takeEvery(GET_MANDATE_HINT, getPaymentPartnerHint);
}
