import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useState } from 'react';
import { t } from 'locale';

import { IconButtonText, MenuItemButton, MenuItemWrapper } from 'components/AppBar/AppBarStyled';
import { DrawerTitle } from 'components/Drawer/DrawerStyled';
import { Faq } from 'components/Icons/Faq';
import { Imprint } from 'components/Icons/Imprint';
import { Privacy } from 'components/Icons/Privacy';
import { MarkDown } from 'components/MarkDown';
import { Modal } from 'components/Modal/Modal';

import { config } from 'config';

import { localeKeys } from 'enums/localeKeys';
import { anchorReference } from 'enums/variants';

import { getPrivacyPolicyUrlAndTitle } from 'utils/url';
import { parseStringAsTemplate } from 'utils/stringTemplate';

const INITIAL_MODAl_STATE = {
  modalTitle: '',
  modalContent: '',
  unHighlightedModalContent: ''
};

export const AppBarHelpMenu = bankName => {
  const [menuLeftOffset, setMenuLeftOffset] = useState();
  const [menuTopOffset, setMenuTopOffset] = useState();
  const isOpen = Boolean(menuLeftOffset);

  const openFaqMenu = ({ currentTarget }) => {
    const anchorElement = currentTarget.getBoundingClientRect();
    setMenuTopOffset(anchorElement.top);
    setMenuLeftOffset(anchorElement.left);
  };
  const handleClose = () => setMenuLeftOffset();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(INITIAL_MODAl_STATE);
  const { modalTitle, modalContent } = modalData;

  const imprintTitle = t(localeKeys.textsImprintTitle);
  const imprintContent = t(localeKeys.textsImprint);

  const faqTitle = t(localeKeys.textsFaqTitle);
  const faqContent = parseStringAsTemplate(
    localeKeys.textsFaq,
    {
      new_bank: bankName
    },
    config.defaultStringTemplateDelimiter
  );

  const { pp: privacyPolicy } = config.oldBankLoginFormLinkIdsToModal;
  const { title: privacyPolicyTitle, url: privacyPolicyUrl } = getPrivacyPolicyUrlAndTitle(
    t(privacyPolicy)
  );

  const openModal = (modalTitle, modalContent) => event => {
    event.preventDefault();
    setIsModalOpen(true);
    setModalData({
      modalTitle,
      modalContent,
      unHighlightedModalContent: modalContent
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    handleClose();
    setModalData(INITIAL_MODAl_STATE);
  };

  const openLinkInNewTab = url => event => {
    event.preventDefault();
    handleClose();
    window.open(url);
  };

  const updateModalData = content => {
    setModalData(prevState => ({
      ...prevState,
      modalContent: content
    }));
  };

  const helpMenu = [
    {
      title: faqTitle,
      icon: <Faq />,
      open: openModal(faqTitle, faqContent),
      dataId: 'app-bar-help-item-faq'
    },
    {
      title: imprintTitle,
      icon: <Imprint />,
      open: openModal(imprintTitle, imprintContent),
      dataId: 'app-bar-help-item-imprint'
    },
    {
      title: privacyPolicyTitle,
      icon: <Privacy />,
      open: openLinkInNewTab(privacyPolicyUrl),
      dataId: 'app-bar-help-item-privacy'
    }
  ];

  return (
    <React.Fragment>
      <IconButtonText
        size="large"
        aria-label={t(localeKeys.appMenuHelp)}
        onClick={openFaqMenu}
        data-id="app-bar-help-menu"
      >
        <HelpOutlineIcon style={{ height: '20px' }} />
      </IconButtonText>
      <Menu
        open={isOpen}
        anchorReference={anchorReference.anchorPosition}
        anchorPosition={{
          top: menuTopOffset + 45 || 0,
          left: menuLeftOffset - 50 || 0
        }}
        onClose={handleClose}
      >
        {helpMenu.map(menuItem => {
          return (
            <MenuItem onClick={menuItem.open} data-id={menuItem.dataId} key={menuItem.title}>
              <MenuItemButton>
                <MenuItemWrapper>
                  <DrawerTitle>
                    {menuItem.icon}
                    {menuItem.title}
                  </DrawerTitle>
                </MenuItemWrapper>
              </MenuItemButton>
            </MenuItem>
          );
        })}
      </Menu>
      <Modal
        title={modalTitle}
        isOpen={isModalOpen}
        onClose={closeModal}
        searchProps={{
          updateModalData,
          modalData
        }}
      >
        <MarkDown text={modalContent} />
      </Modal>
    </React.Fragment>
  );
};
