/**
 * Helper function to calculate total length of received arguments that are type of array
 * @param theArgs
 * @returns {number}
 */
export const calculateTotalLengthOfArrays = (...theArgs: unknown[]) =>
  theArgs.reduce(
    (totalLength: number, item) => (Array.isArray(item) ? totalLength + item.length : totalLength),
    0
  );

export const isLastIndex = (index: number, items: unknown[]) => {
  return index === items.length - 1;
};
