import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

import { H1Title, H2Title } from 'components/Text/TextStyled';

import { themeBreakPoints } from 'enums/keys';

const mobile = ['mobile', 'both'];
const desktop = ['desktop', 'both'];

const getBannerDisplay = (banner, allowedDisplay) => {
  if (banner.url && banner.display) {
    return allowedDisplay.includes(banner.display) ? 'block' : 'none';
  }
  return banner.url ? 'block' : 'none';
};

const getTitleDisplay = (banner, allowedDisplay) => {
  const display = getBannerDisplay(banner, allowedDisplay);
  return display === 'block' ? 'none' : 'block';
};

export const getClasses = makeStyles(({ banner = {}, breakpoints }) => ({
  banner: {
    [breakpoints.up(themeBreakPoints.sm)]: {
      display: getBannerDisplay(banner, desktop)
    },
    [breakpoints.down(themeBreakPoints.sm)]: {
      display: getBannerDisplay(banner, mobile)
    }
  },
  title: {
    [breakpoints.up(themeBreakPoints.sm)]: {
      display: getTitleDisplay(banner, desktop)
    },
    [breakpoints.down(themeBreakPoints.sm)]: {
      display: getTitleDisplay(banner, mobile)
    }
  }
}));

export const ImageAndTitle = styled.div`
  background: url(${({ theme: { banner = {} } }) => banner.url});
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 !important;
  height: ${({ theme: { banner = {} } }) => banner.height};
`;

export const TitleDiv = styled.div`
  max-width: ${({ theme: { appMainContainer } }) => appMainContainer.maxWidth};
  text-align: center;
  margin: 30px auto 0;
  ${H1Title},
  ${H2Title} {
    padding: 0 15px;
  }
`;

export const Content = styled(H2Title)`
  &:before {
    content: '${({ theme: { banner = {} } }) => banner.title}';
  }
`;

export const Icon = styled.div`
  position: absolute;
  padding: 30px 30px 30px 100px;
  text-align: right;
  margin-top: 15px;
  background-position: 25px 20px;
  background-repeat: no-repeat;
  background-size: 50px;
  background-image: url(${({ theme: { banner = {} } }) => banner.iconUrl});
  background-color: ${({ theme: { palette } }) => palette.primary.main};
  display: ${({ theme: { banner = {} } }) => (banner.iconUrl ? '' : 'none')};
  h2 {
    line-height: 24px;
    font-size: ${({ theme }) => theme.typography.fontSizes.M};
    font-weight: ${({ theme }) => theme.typography.fontWeights.normal};
    color: ${({ theme: { palette } }) => palette.common.white};
  }
`;
