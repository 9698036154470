import { marked } from 'marked';
import React from 'react';

interface MarkDownProps {
  text?: string;
  htmlTag?: keyof JSX.IntrinsicElements;
  [key: string]: unknown;
}

export const MarkDown = ({ text = '', htmlTag, ...rest }: MarkDownProps) => {
  const html = text && marked.parse(text);

  const Component = htmlTag ? htmlTag : 'span';

  return <Component dangerouslySetInnerHTML={{ __html: html }} {...rest} />;
};
