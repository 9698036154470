export function normalizeString(str: string) {
  // Convert the string to lowercase
  let normalized = str.toLowerCase();

  // Remove diacritical marks using Unicode normalization
  normalized = normalized.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Normalize whitespace
  normalized = normalized.trim().replace(/\s+/g, ' ');

  return normalized;
}
