import { Types } from 'actions/mandates';

import { createReducer } from 'utils/redux';

const INITIAL_STATE = {};

const handlers = {
  [Types.SELECT_PAYMENT_PARTNER]: (state = INITIAL_STATE, action) => ({
    ...state,
    selectPaymentPartner: action.payload
  })
};

export const paymentPartner = createReducer(INITIAL_STATE, handlers);
