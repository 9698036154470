import useMediaQuery from '@mui/material/useMediaQuery';

import { sideMenuVariants } from 'enums/variants';

import { isPublicRoute } from 'utils/routes';

import { AppBarMenuItems } from './AppBarMenuItems';
import { AppBarOverlayMenu } from './AppBarOverlayMenu';
import { AppBarSlideMenu } from './AppBarSlideMenu';
import { AppBarStickyMenu } from './AppBarStickyMenu';

export const AppBarMenu = ({ classes, showMenuAtBottom }) => {
  const isMobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  if (!isMobileView && !isPublicRoute()) {
    return (
      <AppBarStickyMenu classes={classes} isOpen>
        <AppBarMenuItems variant={sideMenuVariants.sticky} />
      </AppBarStickyMenu>
    );
  }

  return showMenuAtBottom ? (
    <AppBarSlideMenu classes={classes}>
      <AppBarMenuItems variant={sideMenuVariants.slide} />
    </AppBarSlideMenu>
  ) : (
    <AppBarOverlayMenu classes={classes} modalProps={{ keepMounted: true }}>
      <AppBarMenuItems variant={sideMenuVariants.overlay} enableDrawerClick />
    </AppBarOverlayMenu>
  );
};
