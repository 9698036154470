import IconButton from '@mui/material/IconButton';
import { useContext } from 'react';
import { t } from 'locale';

import { Close } from 'components/Icons/Close';

import { localeKeys } from 'enums/localeKeys';
import { drawer } from 'enums/variants';

import { AppBarContext } from '../AppBarContext';
import { DrawerOverlay, Toolbar } from '../AppBarStyled';

export const AppBarOverlayMenu = ({ classes, modalProps, children: menuItems }) => {
  const { toggleDrawer, isMenuOpen } = useContext(AppBarContext);

  return (
    <DrawerOverlay
      open={isMenuOpen}
      onClose={toggleDrawer}
      className={classes.drawer}
      variant={drawer.temporary}
      ModalProps={modalProps}
    >
      <Toolbar>
        {isMenuOpen && (
          <IconButton
            size="large"
            aria-label={t(localeKeys.menuText)}
            onClick={toggleDrawer}
            data-id="mobile-menu-close-button"
          >
            <Close />
          </IconButton>
        )}
      </Toolbar>
      {menuItems}
    </DrawerOverlay>
  );
};
