import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Bank } from 'actions/banks';

export interface BanksState {
  banks: Bank[];
  selectedBank: Bank | null;
}

const INITIAL_STATE: BanksState = {
  banks: [],
  selectedBank: null
};

export const banksSlice = createSlice({
  name: 'banks',
  initialState: INITIAL_STATE,
  reducers: {
    selectBank(state, action: PayloadAction<{ selectedBank: Bank }>) {
      state.selectedBank = action.payload.selectedBank;
    },
    clearBankSelection(state) {
      state.selectedBank = null;
    }
  },
  selectors: {
    selectedBank: state => state.selectedBank
  }
});

export const { selectBank, clearBankSelection } = banksSlice.actions;
export const { selectedBank } = banksSlice.selectors;
