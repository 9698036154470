import { restrictRoute } from 'routes/restrictRoute';
import { protectedRoutes } from 'routes/config';

import { Routes } from './Routes';

const ProtectedRoutesComponent = props => <Routes routes={protectedRoutes} {...props} />;

export const ProtectedRoutes = restrictRoute(
  protectedRoutes,
  protectedRoutes.overview.path
)(ProtectedRoutesComponent);
