import { useEffect, useState } from 'react';

export function useIsUpdated(method: VoidFunction, deps: unknown) {
  const [hasRun, setHasRun] = useState(false);

  useEffect(() => {
    if (hasRun) {
      method();
    } else {
      setHasRun(true);
    }
  }, [deps]);
}
