import { history, routes } from 'routes';

import { MagicLinkSettings, TenantSettings } from 'actions/app';
import { Account } from 'models/Account';
import { MagicLinkMissingPersonalAccountDetailsFormState } from 'components/PersonalDetails/personalDetailsTypes';

interface GetRouteForMagicLink {
  tenantSettings: TenantSettings;
  isEmptyIbanOrNewUser?: boolean;
  isIncompleteMagicLinkRequiredUserData?: boolean;
  isRegisteredFromLink: boolean;
}

export const getRouteForMagicLink = ({
  tenantSettings,
  isEmptyIbanOrNewUser,
  isIncompleteMagicLinkRequiredUserData,
  isRegisteredFromLink
}: GetRouteForMagicLink) => {
  const currentPathName = history.location.pathname;
  const { magicLinkSettings } = tenantSettings;

  const { allowConventionalLogin, allowEditingPersonalData, showPersonalDataFormAfterSignup } =
    magicLinkSettings;

  switch (currentPathName) {
    case routes.oldBankSelection.path:
      if (!isRegisteredFromLink) {
        return routes.signupIban.path;
      }

      if (isEmptyIbanOrNewUser || showPersonalDataFormAfterSignup) {
        return routes.signupIban.path;
      } else if (allowEditingPersonalData || isIncompleteMagicLinkRequiredUserData) {
        return routes.signUpPersonalDetails.path;
      } else if (allowConventionalLogin) {
        return routes.signupLoginDetails.path;
      }
      return routes.accountAnalysis.path;

    case routes.signupIban.path:
      if (
        !isRegisteredFromLink ||
        allowEditingPersonalData ||
        isIncompleteMagicLinkRequiredUserData
      ) {
        return routes.signUpPersonalDetails.path;
      } else if (allowConventionalLogin) {
        return routes.signupLoginDetails.path;
      }
      return routes.accountAnalysis.path;

    case routes.signUpPersonalDetails.path:
      if (!isRegisteredFromLink || allowConventionalLogin) {
        return routes.signupLoginDetails.path;
      }
      return routes.accountAnalysis.path;

    default:
      return routes.accountAnalysis.path;
  }
};

interface GetPreviousRouteForMagicLink {
  isRegisteredFromLink: boolean;
  magicLinkSettings: MagicLinkSettings;
  currentPathName: string;
  hasIbanScreen?: boolean;
}

export const getPreviousRouteForMagicLink = ({
  isRegisteredFromLink,
  magicLinkSettings,
  currentPathName,
  hasIbanScreen
}: GetPreviousRouteForMagicLink): string | null => {
  const { allowEditingPersonalData } = magicLinkSettings;

  const previousRouteMap: Record<string, string> = {
    [routes.signupIban.path]: routes.oldBankSelection.path,
    [routes.signUpPersonalDetails.path]: routes.signupIban.path,
    [routes.signupLoginDetails.path]: routes.signUpPersonalDetails.path
  };

  if (!isRegisteredFromLink) {
    return previousRouteMap[currentPathName] ?? null;
  }

  switch (currentPathName) {
    case routes.signupIban.path: {
      return routes.oldBankSelection.path;
    }
    case routes.signUpPersonalDetails.path: {
      return hasIbanScreen ? routes.signupIban.path : routes.oldBankSelection.path;
    }

    case routes.signupLoginDetails.path: {
      if (allowEditingPersonalData) {
        return routes.signUpPersonalDetails.path;
      }

      if (!allowEditingPersonalData && hasIbanScreen) {
        return routes.signupIban.path;
      }

      if (!allowEditingPersonalData && !hasIbanScreen) {
        return routes.oldBankSelection.path;
      }
      return null;
    }
    default: {
      return null;
    }
  }
};

type UserData = Pick<
  Account,
  'firstName' | 'lastName' | 'street' | 'number' | 'city' | 'dateOfBirth'
>;

export const checkIsMagicLinkRequiredUserDataIncomplete = ({
  firstName,
  lastName,
  street,
  number,
  city,
  dateOfBirth
}: UserData) => !firstName || !lastName || !street || !number || !city || !dateOfBirth;

interface CreatePersonalDetailsIncompletePayloadParams {
  initialValues: MagicLinkMissingPersonalAccountDetailsFormState;
  formValues: MagicLinkMissingPersonalAccountDetailsFormState;
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

// Function that ensures only missing fields are being updated. This ensures that if user
// manipulates disabled input fields and changes the values it will not send those values to the API
export const createMagicLinkMissingPersonalAccountDetailsPayload = ({
  initialValues,
  formValues
}: CreatePersonalDetailsIncompletePayloadParams) => {
  return (
    Object.entries(formValues) as Entries<MagicLinkMissingPersonalAccountDetailsFormState>
  ).reduce<Partial<MagicLinkMissingPersonalAccountDetailsFormState>>(
    (accumulator, [key, value]) => {
      if (!initialValues[key]) {
        // @ts-ignore
        accumulator[key] = value;
      }
      return accumulator;
    },
    {}
  );
};
