import { select, takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';

import { Types } from 'actions/account';
import { fetchSaga } from 'api/fetchSaga';
import { clearStore } from 'sagas/app';
import { appSelector } from 'selectors/appSelectors';
import { personalDetailsSelector } from 'selectors/personalDetailsSelector';
import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

import { getRouteForMagicLink } from 'utils/magicLink';

const { apis } = config;
const {
  GET_PERSONAL_DETAILS,
  CREATE_PERSONAL_DETAILS,
  UPDATE_PERSONAL_DETAILS,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  POLL_ACCOUNT_STATUS
} = Types;

function* savePersonalDetails(formData, actionType) {
  const {
    countryDialingCode: formCountryDialingCode,
    nationalNumber,
    phoneNumber,
    ...personalDetails
  } = formData;

  // TODO: This ensures that countryDialingCode exists even if user submitted empty phone prefix with nationalNumber.
  // TODO: It's possible to submit empty field due to an issue which will be addressed and fixed in ticket number
  // TODO: https://finleap-connect.atlassian.net/browse/KIT-6931
  // TODO: When that's done this here will be removed and left pure as-is because form will always have countryDialingCode.
  const {
    personalDetails: { countryDialingCode: stateCountryDialingCode }
  } = yield select(personalDetailsSelector);
  const countryDialingCode = formCountryDialingCode || stateCountryDialingCode;

  const payload = {
    ...personalDetails,
    phoneNumber: nationalNumber ? `${countryDialingCode}${nationalNumber}` : ''
  };

  const params = {
    path: apis.account,
    method: httpMethods.PUT,
    data: {
      user: payload
    }
  };

  return yield fetchSaga({
    params,
    payload,
    actionType
  });
}

export function* createPersonalDetails({ payload, action }) {
  action.setSubmitting(true);

  const response = yield savePersonalDetails(payload, CREATE_PERSONAL_DETAILS);

  action.setSubmitting(false);

  const { isRegisteredFromLink } = yield select(appSelector);
  const { tenantSettings } = yield select(tenantSettingsSelector);

  if (response) {
    yield getPersonalDetails();

    const nextRoute = getRouteForMagicLink({
      tenantSettings,
      isRegisteredFromLink
    });

    history.push(nextRoute);
  }
}

export function* updatePersonalDetails({ payload, action }) {
  action.setSubmitting(true);

  const response = yield savePersonalDetails(payload, UPDATE_PERSONAL_DETAILS);

  action.setSubmitting(false);

  if (response) {
    yield getPersonalDetails();
  }
}

export function* getPersonalDetails() {
  const params = {
    path: apis.account
  };

  return yield fetchSaga({
    params,
    actionType: GET_PERSONAL_DETAILS,
    fields: 'user'
  });
}

export function* pollAccountStatus() {
  const params = {
    path: apis.account
  };

  const response = yield fetchSaga({
    params,
    actionType: POLL_ACCOUNT_STATUS,
    fields: 'user'
  });

  if (!response) {
    yield clearStore();
  }
}

export function* updateEmail({ payload, action }) {
  action.setSubmitting(true);

  const params = {
    path: apis.accountEmail,
    method: httpMethods.PUT,
    data: {
      user: payload
    }
  };

  const response = yield fetchSaga({
    params,
    actionType: UPDATE_EMAIL
  });

  action.setSubmitting(false);

  if (response) {
    response && history.push(routes.emailUpdateSuccess.path);
    yield getPersonalDetails();
  }

  return response;
}

export function* updatePassword({ payload, action }) {
  action.setSubmitting(true);

  const params = {
    path: apis.accountPassword,
    method: httpMethods.PUT,
    data: {
      user: payload
    }
  };

  const response = yield fetchSaga({
    params,
    actionType: UPDATE_PASSWORD
  });

  action.setSubmitting(false);

  response && history.push(routes.passwordUpdateSuccess.path);
  return response;
}

export function* personalDetailsSagas() {
  yield takeEvery(CREATE_PERSONAL_DETAILS, createPersonalDetails);
  yield takeEvery(GET_PERSONAL_DETAILS, getPersonalDetails);
  yield takeEvery(UPDATE_PERSONAL_DETAILS, updatePersonalDetails);
  yield takeEvery(UPDATE_EMAIL, updateEmail);
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
  yield takeEvery(POLL_ACCOUNT_STATUS, pollAccountStatus);
}
