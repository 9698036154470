import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useMemo, useState } from 'react';
import { t } from 'locale';

import { TextButton } from 'components/Buttons/ButtonsStyled';
import { modalTitleId } from 'enums/modal';
import { ErrorDialogPropsFromRedux } from 'components/Dialog/ErrorDialogContainer';
import { LocaleKey } from 'locale/types';

import { config } from 'config';

import { errorDialog } from 'enums/errorDialogEnum';
import { localeKeys } from 'enums/localeKeys';

import { useIsUpdated } from 'hooks/useIsUpdated';

import { Dialog, DialogActions } from './DialogStyled';

export const ErrorDialog = ({
  content,
  reload,
  networkUnavailable,
  redirected,
  clearError
}: ErrorDialogPropsFromRedux) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDialog = () => {
    setIsOpen(false);
    setTimeout(() => clearError(), config.errorDialogCloseClearErrorDelay);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const networkErrorReload = () => {
    setIsOpen(false);
    setTimeout(() => {
      if (!window.navigator.onLine) {
        setIsOpen(true);
      } else {
        reloadPage();
      }
    }, config.networkConnectionErrorReloadDelay);
  };

  const memoizedErrorContent = useMemo(() => {
    return {
      content,
      redirected
    };
  }, [content, redirected]);

  useIsUpdated(() => {
    const { content, redirected } = memoizedErrorContent;
    if (content || redirected) {
      setIsOpen(true);
    }
  }, memoizedErrorContent);

  const renderMessage = (messages: { message: LocaleKey }[] = []) => {
    return messages.map(({ message }, index) => (
      <div key={index} dangerouslySetInnerHTML={{ __html: t(message) }} />
    ));
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby={modalTitleId.modalTitle}
      onClose={(event, reason) => {
        if (reason !== errorDialog.backDropClick) {
          closeDialog();
        }
      }}
      disableEscapeKeyDown={Boolean(reload)}
    >
      <DialogTitle id={modalTitleId.modalTitle}>{t(localeKeys.errorText)}</DialogTitle>
      <DialogContent>{renderMessage(content)}</DialogContent>
      <DialogActions>
        {reload ? (
          <TextButton
            uppercase
            semiBold
            onClick={networkUnavailable ? networkErrorReload : reloadPage}
          >
            {t(localeKeys.errorReloadButton)}
          </TextButton>
        ) : (
          <TextButton uppercase semiBold onClick={closeDialog}>
            {t(localeKeys.closeButton)}
          </TextButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
