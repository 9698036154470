import MuiCircularProgress from '@mui/material/CircularProgress';
import { Component } from 'react';
import { connect } from 'react-redux';
import { clearError, triggerError } from 'slices/errors';

import { Creators as ApiCreators } from 'api/actions';

import { config } from 'config';

import { errorMessages } from 'enums/errorMessages';

import { CircularProgressStyledDiv } from './CircularLoaderStyled';

const MAX_LOADING_RETRY = 3;

class Loader extends Component {
  state = {
    retryCounter: 0
  };

  interval = null;

  componentDidUpdate() {
    const { error, triggerError, clearAppError, clearApiError, retry } = this.props;
    const { retryCounter } = this.state;

    if (!this.interval && error) {
      this.interval = setInterval(() => {
        this.setState(prevState => ({ retryCounter: prevState.retryCounter + 1 }), retry);
      }, config.networkConnectionCheckDelay);
    }

    if (retryCounter === MAX_LOADING_RETRY) {
      clearAppError();
      clearApiError();
      triggerError({
        message: errorMessages.networkUnavailable,
        options: {
          reload: true,
          redirected: true,
          networkUnavailable: true
        }
      });
      this.stopPolling();
    }
  }

  stopPolling() {
    clearInterval(this.interval);
    this.interval = null;
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  render() {
    return (
      <CircularProgressStyledDiv id="circularLoader">
        <MuiCircularProgress size={40} />
      </CircularProgressStyledDiv>
    );
  }
}

export const CircularProgress = connect(null, {
  clearAppError: clearError,
  clearApiError: ApiCreators.apiErrorReset,
  triggerError
})(Loader);
