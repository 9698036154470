import { localeKeys } from 'enums/localeKeys';

export type Countries = typeof countries;

export const countries = [
  {
    value: 'AW',
    code: '+297',
    label: localeKeys.countryAruba
  },
  {
    value: 'AF',
    code: '+93',
    label: localeKeys.countryAfghanistan
  },
  {
    value: 'AO',
    code: '+244',
    label: localeKeys.countryAngola
  },
  {
    value: 'AI',
    code: '+1264',
    label: localeKeys.countryAnguilla
  },
  {
    value: 'AX',
    code: '+358',
    label: localeKeys.countryAlandIslands
  },
  {
    value: 'AL',
    code: '+355',
    label: localeKeys.countryAlbania
  },
  {
    value: 'AD',
    code: '+376',
    label: localeKeys.countryAndorra
  },
  {
    value: 'AE',
    code: '+971',
    label: localeKeys.countryUnitedArabEmirates
  },
  {
    value: 'AR',
    code: '+54',
    label: localeKeys.countryArgentina
  },
  {
    value: 'AM',
    code: '+374',
    label: localeKeys.countryArmenia
  },
  {
    value: 'AS',
    code: '+1684',
    label: localeKeys.countryAmericanSamoa
  },
  {
    value: 'AG',
    code: '+1268',
    label: localeKeys.countryAntiguaAndBarbuda
  },
  {
    value: 'AU',
    code: '+61',
    label: localeKeys.countryAustralia
  },
  {
    value: 'AT',
    code: '+43',
    label: localeKeys.countryAustria
  },
  {
    value: 'AZ',
    code: '+994',
    label: localeKeys.countryAzerbaijan
  },
  {
    value: 'BI',
    code: '+257',
    label: localeKeys.countryBurundi
  },
  {
    value: 'BE',
    code: '+32',
    label: localeKeys.countryBelgium
  },
  {
    value: 'BJ',
    code: '+229',
    label: localeKeys.countryBenin
  },
  {
    value: 'BF',
    code: '+226',
    label: localeKeys.countryBurkinaFaso
  },
  {
    value: 'BD',
    code: '+880',
    label: localeKeys.countryBangladesh
  },
  {
    value: 'BG',
    code: '+359',
    label: localeKeys.countryBulgaria
  },
  {
    value: 'BH',
    code: '+973',
    label: localeKeys.countryBahrain
  },
  {
    value: 'BS',
    code: '+1242',
    label: localeKeys.countryBahamas
  },
  {
    value: 'BA',
    code: '+387',
    label: localeKeys.countryBosniaAndHerzegovina
  },
  {
    value: 'BL',
    code: '+590',
    label: localeKeys.countrySaintBarthelemy
  },
  {
    value: 'BY',
    code: '+375',
    label: localeKeys.countryBelarus
  },
  {
    value: 'BZ',
    code: '+501',
    label: localeKeys.countryBelize
  },
  {
    value: 'BM',
    code: '+1441',
    label: localeKeys.countryBermuda
  },
  {
    value: 'BO',
    code: '+591',
    label: localeKeys.countryBolivia
  },
  {
    value: 'BR',
    code: '+55',
    label: localeKeys.countryBrazil
  },
  {
    value: 'BB',
    code: '+1246',
    label: localeKeys.countryBarbados
  },
  {
    value: 'BN',
    code: '+673',
    label: localeKeys.countryBruneiDarussalam
  },
  {
    value: 'BT',
    code: '+975',
    label: localeKeys.countryBhutan
  },
  {
    value: 'BW',
    code: '+267',
    label: localeKeys.countryBotswana
  },
  {
    value: 'CF',
    code: '+236',
    label: localeKeys.countryCentralAfricanRepublic
  },
  {
    value: 'CA',
    code: '+1',
    label: localeKeys.countryCanada
  },
  {
    value: 'CC',
    code: '+61',
    label: localeKeys.countryCocosKeelingIslands
  },
  {
    value: 'CH',
    code: '+41',
    label: localeKeys.countrySwitzerland
  },
  {
    value: 'CL',
    code: '+56',
    label: localeKeys.countryChile
  },
  {
    value: 'CN',
    code: '+86',
    label: localeKeys.countryChina
  },
  {
    value: 'CI',
    code: '+225',
    label: localeKeys.countryCoteDIvoire
  },
  {
    value: 'CM',
    code: '+237',
    label: localeKeys.countryCameroon
  },
  {
    value: 'CD',
    code: '+243',
    label: localeKeys.countryCongoDemocraticRepublic
  },
  {
    value: 'CG',
    code: '+242',
    label: localeKeys.countryCongo
  },
  {
    value: 'CK',
    code: '+682',
    label: localeKeys.countryCookIslands
  },
  {
    value: 'CO',
    code: '+57',
    label: localeKeys.countryColombia
  },
  {
    value: 'KM',
    code: '+269',
    label: localeKeys.countryComoros
  },
  {
    value: 'CV',
    code: '+238',
    label: localeKeys.countryCapeVerde
  },
  {
    value: 'CR',
    code: '+506',
    label: localeKeys.countryCostaRica
  },
  {
    value: 'CU',
    code: '+53',
    label: localeKeys.countryCuba
  },
  {
    value: 'CW',
    code: '+5999',
    label: localeKeys.countryCuracao
  },
  {
    value: 'CX',
    code: '+61',
    label: localeKeys.countryChristmasIsland
  },
  {
    value: 'KY',
    code: '+1345',
    label: localeKeys.countryCaymanIslands
  },
  {
    value: 'CY',
    code: '+357',
    label: localeKeys.countryCyprus
  },
  {
    value: 'CZ',
    code: '+420',
    label: localeKeys.countryCzechRepublic
  },
  {
    value: 'DE',
    code: '+49',
    label: localeKeys.countryGermany
  },
  {
    value: 'DJ',
    code: '+253',
    label: localeKeys.countryDjibouti
  },
  {
    value: 'DM',
    code: '+1767',
    label: localeKeys.countryDominica
  },
  {
    value: 'DK',
    code: '+45',
    label: localeKeys.countryDenmark
  },
  {
    value: 'DO',
    code: '+1809',
    label: localeKeys.countryDominicanRepublic
  },
  {
    value: 'DO',
    code: '+1829',
    label: localeKeys.countryDominicanRepublic
  },
  {
    value: 'DO',
    code: '+1849',
    label: localeKeys.countryDominicanRepublic
  },
  {
    value: 'DZ',
    code: '+213',
    label: localeKeys.countryAlgeria
  },
  {
    value: 'EC',
    code: '+593',
    label: localeKeys.countryEcuador
  },
  {
    value: 'EG',
    code: '+20',
    label: localeKeys.countryEgypt
  },
  {
    value: 'ER',
    code: '+291',
    label: localeKeys.countryEritrea
  },
  {
    value: 'EH',
    code: '+212',
    label: localeKeys.countryWesternSahara
  },
  {
    value: 'ES',
    code: '+34',
    label: localeKeys.countrySpain
  },
  {
    value: 'EE',
    code: '+372',
    label: localeKeys.countryEstonia
  },
  {
    value: 'ET',
    code: '+251',
    label: localeKeys.countryEthiopia
  },
  {
    value: 'FI',
    code: '+358',
    label: localeKeys.countryFinland
  },
  {
    value: 'FJ',
    code: '+679',
    label: localeKeys.countryFiji
  },
  {
    value: 'FK',
    code: '+500',
    label: localeKeys.countryFalklandIslandsMalvinas
  },
  {
    value: 'FR',
    code: '+33',
    label: localeKeys.countryFrance
  },
  {
    value: 'FO',
    code: '+298',
    label: localeKeys.countryFaroeIslands
  },
  {
    value: 'FM',
    code: '+691',
    label: localeKeys.countryMicronesia
  },
  {
    value: 'GA',
    code: '+241',
    label: localeKeys.countryGabon
  },
  {
    value: 'GB',
    code: '+44',
    label: localeKeys.countryUnitedKingdom
  },
  {
    value: 'GE',
    code: '+995',
    label: localeKeys.countryGeorgia
  },
  {
    value: 'GG',
    code: '+44',
    label: localeKeys.countryGuernsey
  },
  {
    value: 'GH',
    code: '+233',
    label: localeKeys.countryGhana
  },
  {
    value: 'GI',
    code: '+350',
    label: localeKeys.countryGibraltar
  },
  {
    value: 'GN',
    code: '+224',
    label: localeKeys.countryGuinea
  },
  {
    value: 'GP',
    code: '+590',
    label: localeKeys.countryGuadeloupe
  },
  {
    value: 'GM',
    code: '+220',
    label: localeKeys.countryGambia
  },
  {
    value: 'GW',
    code: '+245',
    label: localeKeys.countryGuineaBissau
  },
  {
    value: 'GQ',
    code: '+240',
    label: localeKeys.countryEquatorialGuinea
  },
  {
    value: 'GR',
    code: '+30',
    label: localeKeys.countryGreece
  },
  {
    value: 'GD',
    code: '+1473',
    label: localeKeys.countryGrenada
  },
  {
    value: 'GL',
    code: '+299',
    label: localeKeys.countryGreenland
  },
  {
    value: 'GT',
    code: '+502',
    label: localeKeys.countryGuatemala
  },
  {
    value: 'GF',
    code: '+594',
    label: localeKeys.countryFrenchGuiana
  },
  {
    value: 'GU',
    code: '+1671',
    label: localeKeys.countryGuam
  },
  {
    value: 'GY',
    code: '+592',
    label: localeKeys.countryGuyana
  },
  {
    value: 'HK',
    code: '+852',
    label: localeKeys.countryHongKong
  },
  {
    value: 'HN',
    code: '+504',
    label: localeKeys.countryHonduras
  },
  {
    value: 'HR',
    code: '+385',
    label: localeKeys.countryCroatia
  },
  {
    value: 'HT',
    code: '+509',
    label: localeKeys.countryHaiti
  },
  {
    value: 'HU',
    code: '+36',
    label: localeKeys.countryHungary
  },
  {
    value: 'ID',
    code: '+62',
    label: localeKeys.countryIndonesia
  },
  {
    value: 'IM',
    code: '+44',
    label: localeKeys.countryIsleOfMan
  },
  {
    value: 'IN',
    code: '+91',
    label: localeKeys.countryIndia
  },
  {
    value: 'IO',
    code: '+246',
    label: localeKeys.countryBritishIndianOceanTerritory
  },
  {
    value: 'IE',
    code: '+353',
    label: localeKeys.countryIreland
  },
  {
    value: 'IR',
    code: '+98',
    label: localeKeys.countryIran
  },
  {
    value: 'IQ',
    code: '+964',
    label: localeKeys.countryIraq
  },
  {
    value: 'IS',
    code: '+354',
    label: localeKeys.countryIceland
  },
  {
    value: 'IL',
    code: '+972',
    label: localeKeys.countryIsrael
  },
  {
    value: 'IT',
    code: '+39',
    label: localeKeys.countryItaly
  },
  {
    value: 'JM',
    code: '+1876',
    label: localeKeys.countryJamaica
  },
  {
    value: 'JE',
    code: '+44',
    label: localeKeys.countryJersey
  },
  {
    value: 'JO',
    code: '+962',
    label: localeKeys.countryJordan
  },
  {
    value: 'JP',
    code: '+81',
    label: localeKeys.countryJapan
  },
  {
    value: 'KZ',
    code: '+76',
    label: localeKeys.countryKazakhstan
  },
  {
    value: 'KZ',
    code: '+77',
    label: localeKeys.countryKazakhstan
  },
  {
    value: 'KE',
    code: '+254',
    label: localeKeys.countryKenya
  },
  {
    value: 'KG',
    code: '+996',
    label: localeKeys.countryKyrgyzstan
  },
  {
    value: 'KH',
    code: '+855',
    label: localeKeys.countryCambodia
  },
  {
    value: 'KI',
    code: '+686',
    label: localeKeys.countryKiribati
  },
  {
    value: 'KN',
    code: '+1869',
    label: localeKeys.countrySaintKittsAndNevis
  },
  {
    value: 'KR',
    code: '+82',
    label: localeKeys.countryKorea
  },
  {
    value: 'XK',
    code: '+383',
    label: localeKeys.countryKosovo
  },
  {
    value: 'KW',
    code: '+965',
    label: localeKeys.countryKuwait
  },
  {
    value: 'LA',
    code: '+856',
    label: localeKeys.countryLaoPeoplesDemocraticRepublic
  },
  {
    value: 'LB',
    code: '+961',
    label: localeKeys.countryLebanon
  },
  {
    value: 'LR',
    code: '+231',
    label: localeKeys.countryLiberia
  },
  {
    value: 'LY',
    code: '+218',
    label: localeKeys.countryLibyanArabJamahiriya
  },
  {
    value: 'LC',
    code: '+1758',
    label: localeKeys.countrySaintLucia
  },
  {
    value: 'LI',
    code: '+423',
    label: localeKeys.countryLiechtenstein
  },
  {
    value: 'LK',
    code: '+94',
    label: localeKeys.countrySriLanka
  },
  {
    value: 'LS',
    code: '+266',
    label: localeKeys.countryLesotho
  },
  {
    value: 'LT',
    code: '+370',
    label: localeKeys.countryLithuania
  },
  {
    value: 'LU',
    code: '+352',
    label: localeKeys.countryLuxembourg
  },
  {
    value: 'LV',
    code: '+371',
    label: localeKeys.countryLatvia
  },
  {
    value: 'MO',
    code: '+853',
    label: localeKeys.countryMacao
  },
  {
    value: 'MF',
    code: '+590',
    label: localeKeys.countrySaintMartin
  },
  {
    value: 'MA',
    code: '+212',
    label: localeKeys.countryMorocco
  },
  {
    value: 'MC',
    code: '+377',
    label: localeKeys.countryMonaco
  },
  {
    value: 'MD',
    code: '+373',
    label: localeKeys.countryMoldova
  },
  {
    value: 'MG',
    code: '+261',
    label: localeKeys.countryMadagascar
  },
  {
    value: 'MV',
    code: '+960',
    label: localeKeys.countryMaldives
  },
  {
    value: 'MX',
    code: '+52',
    label: localeKeys.countryMexico
  },
  {
    value: 'MH',
    code: '+692',
    label: localeKeys.countryMarshallIslands
  },
  {
    value: 'MK',
    code: '+389',
    label: localeKeys.countryMacedonia
  },
  {
    value: 'ML',
    code: '+223',
    label: localeKeys.countryMali
  },
  {
    value: 'MT',
    code: '+356',
    label: localeKeys.countryMalta
  },
  {
    value: 'MM',
    code: '+95',
    label: localeKeys.countryMyanmar
  },
  {
    value: 'ME',
    code: '+382',
    label: localeKeys.countryMontenegro
  },
  {
    value: 'MN',
    code: '+976',
    label: localeKeys.countryMongolia
  },
  {
    value: 'MP',
    code: '+1670',
    label: localeKeys.countryNorthernMarianaIslands
  },
  {
    value: 'MZ',
    code: '+258',
    label: localeKeys.countryMozambique
  },
  {
    value: 'MR',
    code: '+222',
    label: localeKeys.countryMauritania
  },
  {
    value: 'MS',
    code: '+1664',
    label: localeKeys.countryMontserrat
  },
  {
    value: 'MQ',
    code: '+596',
    label: localeKeys.countryMartinique
  },
  {
    value: 'MU',
    code: '+230',
    label: localeKeys.countryMauritius
  },
  {
    value: 'MW',
    code: '+265',
    label: localeKeys.countryMalawi
  },
  {
    value: 'MY',
    code: '+60',
    label: localeKeys.countryMalaysia
  },
  {
    value: 'YT',
    code: '+262',
    label: localeKeys.countryMayotte
  },
  {
    value: 'NA',
    code: '+264',
    label: localeKeys.countryNamibia
  },
  {
    value: 'NC',
    code: '+687',
    label: localeKeys.countryNewCaledonia
  },
  {
    value: 'NE',
    code: '+227',
    label: localeKeys.countryNiger
  },
  {
    value: 'NF',
    code: '+672',
    label: localeKeys.countryNorfolkIsland
  },
  {
    value: 'NG',
    code: '+234',
    label: localeKeys.countryNigeria
  },
  {
    value: 'NI',
    code: '+505',
    label: localeKeys.countryNicaragua
  },
  {
    value: 'NU',
    code: '+683',
    label: localeKeys.countryNiue
  },
  {
    value: 'NL',
    code: '+31',
    label: localeKeys.countryNetherlands
  },
  {
    value: 'NO',
    code: '+47',
    label: localeKeys.countryNorway
  },
  {
    value: 'NP',
    code: '+977',
    label: localeKeys.countryNepal
  },
  {
    value: 'NR',
    code: '+674',
    label: localeKeys.countryNauru
  },
  {
    value: 'NZ',
    code: '+64',
    label: localeKeys.countryNewZealand
  },
  {
    value: 'OM',
    code: '+968',
    label: localeKeys.countryOman
  },
  {
    value: 'PK',
    code: '+92',
    label: localeKeys.countryPakistan
  },
  {
    value: 'PA',
    code: '+507',
    label: localeKeys.countryPanama
  },
  {
    value: 'PN',
    code: '+64',
    label: localeKeys.countryPitcairn
  },
  {
    value: 'PE',
    code: '+51',
    label: localeKeys.countryPeru
  },
  {
    value: 'PH',
    code: '+63',
    label: localeKeys.countryPhilippines
  },
  {
    value: 'PW',
    code: '+680',
    label: localeKeys.countryPalau
  },
  {
    value: 'PG',
    code: '+675',
    label: localeKeys.countryPapuaNewGuinea
  },
  {
    value: 'PL',
    code: '+48',
    label: localeKeys.countryPoland
  },
  {
    value: 'PR',
    code: '+1787',
    label: localeKeys.countryPuertoRico
  },
  {
    value: 'PR',
    code: '+1939',
    label: localeKeys.countryPuertoRico
  },
  {
    value: 'KP',
    code: '+850',
    label: localeKeys.countryNorthKorea
  },
  {
    value: 'PT',
    code: '+351',
    label: localeKeys.countryPortugal
  },
  {
    value: 'PY',
    code: '+595',
    label: localeKeys.countryParaguay
  },
  {
    value: 'PS',
    code: '+970',
    label: localeKeys.countryPalestinianTerritoryOccupied
  },
  {
    value: 'PF',
    code: '+689',
    label: localeKeys.countryFrenchPolynesia
  },
  {
    value: 'QA',
    code: '+974',
    label: localeKeys.countryQatar
  },
  {
    value: 'RE',
    code: '+262',
    label: localeKeys.countryReunion
  },
  {
    value: 'RO',
    code: '+40',
    label: localeKeys.countryRomania
  },
  {
    value: 'RU',
    code: '+7',
    label: localeKeys.countryRussianFederation
  },
  {
    value: 'RW',
    code: '+250',
    label: localeKeys.countryRwanda
  },
  {
    value: 'SA',
    code: '+966',
    label: localeKeys.countrySaudiArabia
  },
  {
    value: 'SD',
    code: '+249',
    label: localeKeys.countrySudan
  },
  {
    value: 'SN',
    code: '+221',
    label: localeKeys.countrySenegal
  },
  {
    value: 'SG',
    code: '+65',
    label: localeKeys.countrySingapore
  },
  {
    value: 'GS',
    code: '+500',
    label: localeKeys.countrySouthGeorgiaAndSandwichIsl
  },
  {
    value: 'SJ',
    code: '+4779',
    label: localeKeys.countrySvalbardAndJanMayen
  },
  {
    value: 'SB',
    code: '+677',
    label: localeKeys.countrySolomonIslands
  },
  {
    value: 'SL',
    code: '+232',
    label: localeKeys.countrySierraLeone
  },
  {
    value: 'SV',
    code: '+503',
    label: localeKeys.countryElSalvador
  },
  {
    value: 'SM',
    code: '+378',
    label: localeKeys.countrySanMarino
  },
  {
    value: 'SO',
    code: '+252',
    label: localeKeys.countrySomalia
  },
  {
    value: 'PM',
    code: '+508',
    label: localeKeys.countrySaintPierreAndMiquelon
  },
  {
    value: 'RS',
    code: '+381',
    label: localeKeys.countrySerbia
  },
  {
    value: 'SS',
    code: '+211',
    label: localeKeys.countrySouthSudan
  },
  {
    value: 'ST',
    code: '+239',
    label: localeKeys.countrySaoTomeAndPrincipe
  },
  {
    value: 'SR',
    code: '+597',
    label: localeKeys.countrySuriname
  },
  {
    value: 'SK',
    code: '+421',
    label: localeKeys.countrySlovakia
  },
  {
    value: 'SI',
    code: '+386',
    label: localeKeys.countrySlovenia
  },
  {
    value: 'SE',
    code: '+46',
    label: localeKeys.countrySweden
  },
  {
    value: 'SZ',
    code: '+268',
    label: localeKeys.countrySwaziland
  },
  {
    value: 'SX',
    code: '+1721',
    label: localeKeys.countrySintMaarten
  },
  {
    value: 'SC',
    code: '+248',
    label: localeKeys.countrySeychelles
  },
  {
    value: 'SY',
    code: '+963',
    label: localeKeys.countrySyrianArabRepublic
  },
  {
    value: 'TC',
    code: '+1649',
    label: localeKeys.countryTurksAndCaicosIslands
  },
  {
    value: 'TD',
    code: '+235',
    label: localeKeys.countryChad
  },
  {
    value: 'TG',
    code: '+228',
    label: localeKeys.countryTogo
  },
  {
    value: 'TH',
    code: '+66',
    label: localeKeys.countryThailand
  },
  {
    value: 'TJ',
    code: '+992',
    label: localeKeys.countryTajikistan
  },
  {
    value: 'TK',
    code: '+690',
    label: localeKeys.countryTokelau
  },
  {
    value: 'TM',
    code: '+993',
    label: localeKeys.countryTurkmenistan
  },
  {
    value: 'TL',
    code: '+670',
    label: localeKeys.countryTimorLeste
  },
  {
    value: 'TO',
    code: '+676',
    label: localeKeys.countryTonga
  },
  {
    value: 'TT',
    code: '+1868',
    label: localeKeys.countryTrinidadAndTobago
  },
  {
    value: 'TN',
    code: '+216',
    label: localeKeys.countryTunisia
  },
  {
    value: 'TR',
    code: '+90',
    label: localeKeys.countryTurkey
  },
  {
    value: 'TV',
    code: '+688',
    label: localeKeys.countryTuvalu
  },
  {
    value: 'TW',
    code: '+886',
    label: localeKeys.countryTaiwan
  },
  {
    value: 'TZ',
    code: '+255',
    label: localeKeys.countryTanzania
  },
  {
    value: 'UG',
    code: '+256',
    label: localeKeys.countryUganda
  },
  {
    value: 'UA',
    code: '+380',
    label: localeKeys.countryUkraine
  },
  {
    value: 'UY',
    code: '+598',
    label: localeKeys.countryUruguay
  },
  {
    value: 'US',
    code: '+1',
    label: localeKeys.countryUnitedStates
  },
  {
    value: 'UZ',
    code: '+998',
    label: localeKeys.countryUzbekistan
  },
  {
    value: 'VA',
    code: '+3906698',
    label: localeKeys.countryHolySeeVaticanCityState
  },
  {
    value: 'VA',
    code: '+379',
    label: localeKeys.countryHolySeeVaticanCityState
  },
  {
    value: 'VC',
    code: '+1784',
    label: localeKeys.countrySaintVincentAndGrenadines
  },
  {
    value: 'VE',
    code: '+58',
    label: localeKeys.countryVenezuela
  },
  {
    value: 'VG',
    code: '+1284',
    label: localeKeys.countryVirginIslandsBritish
  },
  {
    value: 'VI',
    code: '+1340',
    label: localeKeys.countryVirginIslandsUs
  },
  {
    value: 'VN',
    code: '+84',
    label: localeKeys.countryVietNam
  },
  {
    value: 'VU',
    code: '+678',
    label: localeKeys.countryVanuatu
  },
  {
    value: 'WF',
    code: '+681',
    label: localeKeys.countryWallisAndFutuna
  },
  {
    value: 'WS',
    code: '+685',
    label: localeKeys.countrySamoa
  },
  {
    value: 'YE',
    code: '+967',
    label: localeKeys.countryYemen
  },
  {
    value: 'ZA',
    code: '+27',
    label: localeKeys.countrySouthAfrica
  },
  {
    value: 'ZM',
    code: '+260',
    label: localeKeys.countryZambia
  },
  {
    value: 'ZW',
    code: '+263',
    label: localeKeys.countryZimbabwe
  }
];
