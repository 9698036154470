import Divider from '@mui/material/Divider';
import React, { useContext } from 'react';
import { routes } from 'routes';
import { t } from 'locale';

import { FullButton } from 'components/Buttons/ButtonsStyled';
import { Container } from 'components/Container/ContainerStyled';
import { DrawerItem } from 'components/Drawer/DrawerItem';
import { CloseAccount } from 'components/Icons/CloseAccount';
import { DirectDebit } from 'components/Icons/DirectDebit';
import { IncomingPayments } from 'components/Icons/IncomingPayments';
import { Overview } from 'components/Icons/Overview';
import { StandingOrders } from 'components/Icons/StandingOrders';
import { Transfer } from 'components/Icons/Transfer';

import { config } from 'config';

import { localeKeys } from 'enums/localeKeys';
import { elementType, sideMenuVariants } from 'enums/variants';

import { AppBarHelp } from './AppBarHelp';

import { AppBarContext } from '../AppBarContext';

export const AppBarMenuItems = ({ variant, enableDrawerClick }) => {
  const {
    tenantSettings: {
      bankName,
      disableStandingOrders,
      disableBalanceTransfer,
      disableCloseAccount
    },
    toggleDrawer,
    isAuthorized,
    isAuthenticated,
    isManualSwitch
  } = useContext(AppBarContext);

  const drawerItems = [
    {
      title: t(localeKeys.appMenuOverviewText),
      routeTo: routes.overview.path,
      icon: <Overview />,
      hidden: variant === sideMenuVariants.slide,
      dataId: 'sidebar-item-overview'
    },
    {
      title: t(localeKeys.directDebitsText),
      routeTo: routes.directDebits.path,
      icon: <DirectDebit />,
      dataId: 'sidebar-item-direct-debits'
    },
    {
      title: t(localeKeys.incomingPaymentsText),
      routeTo: routes.incomingPayments.path,
      icon: <IncomingPayments />,
      dataId: 'sidebar-item-incoming-payments'
    },
    {
      title: t(localeKeys.standingOrdersText),
      routeTo: routes.standingOrders.path,
      icon: <StandingOrders />,
      hidden: disableStandingOrders || isManualSwitch,
      dataId: 'sidebar-item-standing-orders'
    },
    {
      title: t(localeKeys.transferBalanceText),
      routeTo: routes.transferBalance.path,
      icon: <Transfer />,
      hidden: disableBalanceTransfer || isManualSwitch,
      dataId: 'sidebar-item-transfer-balance'
    },
    {
      title: t(localeKeys.closeAccountText),
      routeTo: routes.closeAccount.path,
      icon: <CloseAccount />,
      hidden: disableCloseAccount,
      dataId: 'sidebar-item-close-account'
    }
  ];

  const showHelpMenu =
    variant === sideMenuVariants.overlay ||
    (isAuthenticated && variant === sideMenuVariants.sticky);

  const { apis, apiBaseUrl } = config;

  return (
    <React.Fragment>
      {isAuthorized && (
        <React.Fragment>
          {drawerItems.map(item => {
            return (
              <React.Fragment key={item.dataId}>
                {!item.hidden && (
                  <DrawerItem
                    isColumnLayout={variant === sideMenuVariants.slide}
                    title={item.title}
                    dataId={item.dataId}
                    to={item.routeTo}
                    icon={item.icon}
                    onClick={enableDrawerClick ? toggleDrawer : () => {}}
                  />
                )}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
      {showHelpMenu && (
        <React.Fragment>
          {isAuthorized && <Divider />}
          <AppBarHelp bankName={bankName} />
        </React.Fragment>
      )}
      {isAuthorized && (
        <Container padded>
          <FullButton
            href={`${apiBaseUrl}/${apis.letterSummary}`}
            download="Download_Summary.pdf"
            component={elementType.anchor}
          >
            {t(localeKeys.downloadSummary)}
          </FullButton>
        </Container>
      )}
    </React.Fragment>
  );
};
