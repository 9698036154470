import { DefaultTheme } from 'styled-components';
import {
  formHelperTextClasses,
  accordionSummaryClasses,
  accordionClasses,
  outlinedInputClasses,
  inputClasses,
  drawerClasses
} from '@mui/material';

const black = '#000000';
const normal = 'normal';

const fontSizes = {
  XS: '12px',
  S: '14px',
  SM: '15px',
  M: '16px',
  ML: '18px',
  L: '20px',
  XL: '22px',
  XXL: '24px'
};

const fontWeights = {
  ultraLight: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  doubleBold: 800,
  megaBold: 900
};

const inputProps = {
  fontSize: fontSizes.M,
  minHeight: '20px'
};

export const customColors = {
  paleGrey: '#f5f7fb',
  paleGreyTwo: '#fafbfd',
  offWhite: '#fffbe6',
  veryLightPink: '#e2e2e2',
  lightGreen: '#D9EEEA',
  lightYellow: '#F2E9DA',
  darkCream: '#ffe58f',
  orangeyYellow: '#f5a623',
  barbiePink: '#ff428c',
  orange: '#C74500',
  red: '#E0120B',
  successGreen: '#4e8314',
  waterBlue: '#015C86',
  activeBlue: '#3F51B5',
  cobaltBlue: '#0018a8',
  deepSeaBlue: '#005192',
  whiteGrey: '#efefef',
  lightGrey: '#9d9d9d',
  brownGrey: '#9b9b9b',
  doveGrey: '#727272',
  lightBlue: '#666666',
  greyishBrown: '#4a4a4a',
  darkGrey: '#383838',
  pureWhite: '#fff'
};

export const theme: DefaultTheme = {
  cardPadding: '20px',
  penColor: black,
  bannerColor: customColors.orangeyYellow,
  bannerTextColor: customColors.lightBlue,
  defaultPadding: '15px',
  appMainContainer: {
    maxWidth: '550px',
    margin: 'auto 0'
  },
  typography: {
    fontSizes,
    fontWeights
  },
  borders: {
    tinyVeryLightPink: `1px solid ${customColors.veryLightPink}`,
    tinyVeryLightGrey: `1px solid ${customColors.lightGrey}`,
    tinyDashedVeryLightPink: `1px dashed ${customColors.veryLightPink}`,
    tinyVeryOrangeyYellow: `1px solid ${customColors.orangeyYellow}`,
    tinyDeepSeaBlue: `1px solid ${customColors.deepSeaBlue}`
  },
  customShadows: {
    tinyBoxShadow: '2px 4px 6px 0 rgba(0, 0, 0, 0.1)',
    boxShadow: '0 2px 10px 5px rgba(0, 0, 0, 0.1)'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontStyle: normal,
          fontStretch: normal,
          lineHeight: normal,
          letterSpacing: normal,
          color: black,
          fontSize: fontSizes.M,
          fontWeight: fontWeights.bold
        },
        h2: {
          fontStyle: normal,
          fontStretch: normal,
          lineHeight: normal,
          letterSpacing: normal,
          color: black,
          fontSize: fontSizes.S,
          fontWeight: fontWeights.bold
        },
        h3: {
          fontStyle: normal,
          fontStretch: normal,
          lineHeight: normal,
          letterSpacing: normal,
          color: black,
          fontSize: fontSizes.XS,
          fontWeight: fontWeights.semiBold
        },
        h4: {
          fontStyle: normal,
          fontStretch: normal,
          lineHeight: normal,
          letterSpacing: normal,
          color: black,
          fontSize: fontSizes.XS,
          fontWeight: fontWeights.bold
        },
        h5: {
          fontStyle: normal,
          fontStretch: normal,
          lineHeight: normal,
          letterSpacing: normal,
          color: black,
          fontSize: fontSizes.XS,
          fontWeight: fontWeights.bold
        },
        caption: {
          fontStyle: normal,
          fontStretch: normal,
          lineHeight: normal,
          letterSpacing: normal,
          color: black,
          fontSize: fontSizes.XS,
          fontWeight: fontWeights.normal
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '60px',
          top: 0
        }
      }
    },
    MuiExpansionPanelSummary: {
      styleOverrides: {
        root: {
          height: '48px',
          [`& .${accordionSummaryClasses.expanded}`]: {
            top: '40% !important',
            minHeight: '15px'
          },
          expandIcon: {
            top: '55%'
          }
        }
      }
    },
    MuiExpansionPanel: {
      styleOverrides: {
        root: {
          [`&. ${accordionClasses.expanded}`]: {
            margin: '0'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          padding: '12px'
        },
        adornedEnd: {
          paddingRight: '8px !important'
        },
        root: {
          lineHeight: '1.1876em',
          [`&.${inputClasses.error}`]: {
            fieldset: {
              borderColor: `${customColors.red} !important`
            }
          }
        },
        input: {
          height: '1.1876em'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            border: `1px solid ${customColors.veryLightPink}`,
            borderRadius: '3px'
          }
        },
        input: {
          padding: '14px',
          ...inputProps
        },
        multiline: {
          padding: '12px'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          ...inputProps
        },
        underline: {
          '&:before': {
            borderBottom: `1px solid ${customColors.veryLightPink}`
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: fontWeights.medium,
          textTransform: 'capitalize'
        },
        outlined: {
          border: '1px solid rgba(0, 0, 0, 0.23)',
          padding: '5px 15px'
        },
        outlinedSizeSmall: {
          padding: '3px 9px',
          fontSize: '0.8125rem'
        },
        contained: {
          boxShadow: 'none'
        },
        containedSizeSmall: {
          padding: '4px 10px',
          fontSize: '0.8125rem'
        },
        text: {
          padding: '6px 8px'
        },
        textSizeSmall: {
          padding: '4px 5px',
          fontSize: '0.8125rem'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          [`& .${drawerClasses.paperAnchorRight}`]: {
            boxShadow: '-2px 0 4px 1px rgba(0, 0, 0, 0.1)'
          },
          [`& .${drawerClasses.paperAnchorLeft}`]: {
            borderRight: 'none !important'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: fontSizes.XS,
          [`&.${formHelperTextClasses.error}`]: {
            display: 'flex',
            alignItems: 'center',
            color: customColors.red
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          whiteSpace: 'nowrap',
          fontSize: '12px'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '10px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: customColors.darkGrey,
          fontSize: fontSizes.XS
        },
        arrow: {
          '&:before': {
            backgroundColor: customColors.darkGrey
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: customColors.activeBlue
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: customColors.doveGrey,
          '&.Mui-selected': {
            color: customColors.activeBlue
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          maxHeight: '200px'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          minHeight: '1.25em'
        },
        filled: {
          minHeight: '1em'
        }
      }
    }
  }
};
