import { takeEvery } from 'redux-saga/effects';

import { Types } from 'actions/mandates';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

const { apis } = config;
const { GET_PAYMENT_PARTNER, GET_PAYMENT_PARTNER_SELECTION } = Types;

export function* getPaymentPartner({ id }) {
  const params = {
    path: `${apis.paymentPartners}/${id}`
  };

  return yield fetchSaga({
    params,
    actionType: GET_PAYMENT_PARTNER,
    fields: 'paymentPartner'
  });
}

export function* getPaymentPartnerSelection({ payload }) {
  const { query, isDirectDebit } = payload;
  const params = {
    path: apis.paymentPartners,
    params: {
      search_term: query,
      is_direct: isDirectDebit,
      address: true
    }
  };

  yield fetchSaga({
    params,
    actionType: GET_PAYMENT_PARTNER_SELECTION,
    fields: 'paymentPartners'
  });
}

export function* paymentPartnerSaga() {
  yield takeEvery(GET_PAYMENT_PARTNER, getPaymentPartner);
  yield takeEvery(GET_PAYMENT_PARTNER_SELECTION, getPaymentPartnerSelection);
}
