import dayjs from 'dayjs';
import { select, takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';

import { Types } from 'actions/closeAccount';
import { fetchSaga } from 'api/fetchSaga';
import { appSelector } from 'selectors/appSelectors';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

import { formatDate } from 'utils/date';

const { apis } = config;

const { GET_CLOSE_ACCOUNT_STATUS, CLOSE_ACCOUNT } = Types;

export function* getCloseAccountStatus() {
  const params = {
    path: apis.closeAccount
  };

  return yield fetchSaga({
    params,
    actionType: GET_CLOSE_ACCOUNT_STATUS,
    fields: 'accountClosed'
  });
}

function getCloseAccountPayload({ createSepaDirectDebitForNegativeBalance }) {
  return {
    createSepaDirectDebitForNegativeBalance,
    transferToDifferentAccount: false
  };
}

function getManualSwitchCloseAccountPayload({ createSepaDirectDebitForNegativeBalance, iban }) {
  return {
    sendSepaDirectDebitMandate: createSepaDirectDebitForNegativeBalance,
    iban,
    transferOptions: {
      transferToDifferentAccount: false
    }
  };
}

export function* closeAccount({ data, action }) {
  const { isManualSwitch } = yield select(appSelector);

  const payload = {
    ...(isManualSwitch ? getManualSwitchCloseAccountPayload(data) : getCloseAccountPayload(data)),
    validityDate: data.validityDate || formatDate(dayjs())
  };

  const params = {
    path: isManualSwitch ? apis.closeOldBankAccount : apis.closeAccount,
    method: httpMethods.POST,
    data: payload
  };

  action.setSubmitting(true);

  const response = yield fetchSaga({
    params,
    actionType: CLOSE_ACCOUNT
  });

  action.setSubmitting(false);

  if (response) {
    response && history.push(routes.closeAccountSuccess.path);
    yield getCloseAccountStatus();
  }
}

export function* closeAccountSaga() {
  yield takeEvery(GET_CLOSE_ACCOUNT_STATUS, getCloseAccountStatus);
  yield takeEvery(CLOSE_ACCOUNT, closeAccount);
}
