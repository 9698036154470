import styled from 'styled-components';

export const CircularProgressStyledDiv = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: rgba(75, 75, 75, 0.1);
`;
