import { history } from 'routes';
import { removeStorageItem } from 'locale';

import { goBackToPreviousPage } from 'utils/history';

export const implementMe = () => alert('Implement me!');

export const redirectToPath = (toPath: string) => {
  if (toPath) {
    history.push(toPath);
  } else {
    goBackToPreviousPage();
  }
};

export const isEmbeddedView = () => removeStorageItem('embedded');
