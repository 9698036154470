import { history, publicRoutes } from 'routes';

import { Route } from 'routes/config';

export const isPublicRoute = () => {
  const { pathname } = history.location;

  return Boolean(
    Object.values(publicRoutes).some((route: Route) => {
      return pathname === route.path;
    })
  );
};
