import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';

import { Creators } from 'actions/app';
import { appSelector } from 'selectors/appSelectors';
import { routeSelector } from 'selectors/routeSelector';

import { config } from 'config';

const PrepareAppComponent = RouterComponent =>
  class extends Component {
    constructor(props) {
      super(props);
      this.intervalPromise = null;
    }

    componentDidMount() {
      const { initialize } = this.props;
      initialize();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
        this.pollSessionStatus();
      }
    }

    pollSessionStatus = () => {
      clearInterval(this.intervalPromise);
      const { isAuthenticated, sessionStatus } = this.props;
      if (isAuthenticated) {
        this.intervalPromise = setInterval(() => sessionStatus(), config.sessionStatusPollingDelay);
      }
    };

    render() {
      const { ...otherProps } = this.props;
      return <RouterComponent {...otherProps} />;
    }
  };

const mapStateToProps = state => {
  return {
    ...appSelector(state),
    ...routeSelector(state)
  };
};

export const withPrepareApp = RouterComponent =>
  compose(
    connect(mapStateToProps, {
      sessionStatus: Creators.sessionStatus,
      initialize: Creators.initializeApp
    }),
    PrepareAppComponent
  )(RouterComponent);
