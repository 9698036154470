import { Route } from 'routes';

import { CircularProgress } from 'components/Loaders/CircularLoader';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

export const personalDetailsRoutes: Record<string, Route> = {
  personalDetails: {
    id: 'personalDetails',
    title: 'navigationPersonalDetails',
    path: '/user',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: CircularProgress,
      loader: () => import('./PersonalDetailsContainer')
    })
  }
};

export const signUpPersonalDetailsRoutes: Record<string, Route> = {
  signUpPersonalDetails: {
    id: 'signUpPersonalDetails',
    title: 'navigationAccountSwitchText',
    path: '/sign-up/personal-details',
    isProtected: true,
    type: routeTypes.default,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: CircularProgress,
      loader: () => import('./PersonalDetailsContainer')
    })
  }
};
