import styled from 'styled-components';
import { HTMLAttributes } from 'react';

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  padded?: boolean;
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky';
}

export const Container = styled(
  ({ padded: _padded, position: _position, ...props }: ContainerProps) => <div {...props} />
)`
  ${({ padded = false, theme }) => (padded ? `padding: ${theme.defaultPadding}` : '')};
  ${({ position }) => (position ? `position: ${position}` : '')};
`;

export const WarningContainer = styled(Container)`
  border: ${({ theme }) => theme.borders.tinyVeryOrangeyYellow};
  padding: ${({ theme }) => theme.defaultPadding};
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  svg {
    color: #f5a623;
    margin-right: 5px;
  }
  p {
    margin: 0;
    font-size: ${({ theme }) => theme.typography.fontSizes.XS};
  }
`;
