import { Route } from 'routes';

import { LoaderLayout } from 'components/Loaders/LoaderLayout';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const transferBalanceRoutes: Record<string, Route> = {
  transferBalance: {
    id: 'transferBalance',
    title: 'navigationTransferBalance',
    path: '/transfer-balance',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./TransferBalanceContainer')
    })
  },
  transferBalanceSuccess: {
    id: 'transferBalanceSuccess',
    title: 'navigationTransferBalance',
    path: '/transfer-balance-success',
    isProtected: true,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./TransferBalanceSuccessContainer')
    })
  }
};

export default transferBalanceRoutes;
