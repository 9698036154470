import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { routeTypes } from 'enums/routeTypes';

export type Route = {
  id: string;
  title: string;
  path: string;
  isProtected: boolean;
  type?: routeTypes;
};

const initialState: Route = {
  id: '',
  title: '',
  path: '/',
  isProtected: false,
  type: routeTypes.public
};

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    registerRoute(state, action: PayloadAction<Route>) {
      return action.payload;
    }
  },
  selectors: {
    getRoute: state => state
  }
});

export const { registerRoute } = routeSlice.actions;
export const { getRoute } = routeSlice.selectors;
