import { init as initApm } from '@elastic/apm-rum';
import { nanoid } from 'nanoid';
import remove from 'lodash/remove';
import { getStorageItem, setStorageItem } from 'locale';

import { version } from '../../package.json';

export const APM_USER_ID = 'APM_USER_ID';

export const initializeApmAgent = () => {
  const {
    ELASTIC_APM_ENABLED,
    ELASTIC_APM_SERVER_URL,
    ELASTIC_APM_SERVICE_NAME,
    ELASTIC_APM_ENVIRONMENT,
    X_FORWARDED_FOR = null
  } = window?.serverEnvConfig ?? {};

  // It checks ELASTIC_APM_ENABLED against string "true" because this is coming from server ENV config and is set to window.serverEnvConfig
  // with JSON.stringify through server proxy
  const shouldEnableApmAgent =
    ELASTIC_APM_ENABLED === 'true' &&
    typeof ELASTIC_APM_SERVER_URL === 'string' &&
    ELASTIC_APM_SERVER_URL !== '';

  if (shouldEnableApmAgent) {
    window.__apm__ = initApm({
      // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
      serviceName: ELASTIC_APM_SERVICE_NAME,

      // Set custom APM Server URL (default: http://localhost:8200)
      serverUrl: ELASTIC_APM_SERVER_URL,

      // Set service version (required for sourcemap feature)
      serviceVersion: version,

      environment: ELASTIC_APM_ENVIRONMENT,

      // @ts-ignore
      apmRequest({ xhr }) {
        if (X_FORWARDED_FOR !== null) {
          xhr.setRequestHeader('X-Forwarded-For', X_FORWARDED_FOR);
        }
        return true;
      }
    });

    setApmUserContext();
    filterOutApmErrors();
  }
};

const setApmUserContext = (userContext = {}) => {
  const apm = window.__apm__;
  if (!apm) {
    return;
  }

  let apmUserID = getStorageItem(APM_USER_ID);

  if (!apmUserID) {
    apmUserID = nanoid();
    setStorageItem(APM_USER_ID, apmUserID);
  }

  apm.setUserContext({
    id: apmUserID,
    ...userContext
  });
};

const filterOutApmErrors = () => {
  const apm = window.__apm__;
  if (!apm) {
    return;
  }

  apm.addFilter(function (payload) {
    if (payload.errors) {
      remove(payload.errors, (error: { exception: { message: string } }) => {
        const message = error?.exception?.message;
        if (message) {
          return [
            // DatePicker Input
            'TypeError: (intermediate value).parse is not a function',
            "TypeError: fe.parse is not a function. (In 'fe.parse(p,De[c],new Date)', 'fe.parse' is undefined)",
            'Uncaught TypeError: fe.parse is not a function'
          ].includes(message);
        }
        return false;
      });
    }
    return payload;
  });
};
