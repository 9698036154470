import React from 'react';

import IconLoader from './IconLoader';
import catalanImg from './img/flag-icon-catalonia.svg';

export const Catalan = ({
  style = {
    display: 'inline-block',
    width: '18px',
    height: '16px',
    verticalAlign: 'middle'
  },
  dataId,
  ...rest
}: {
  style?: React.CSSProperties;
  dataId: string;
}) => {
  return (
    <span data-id={dataId}>
      <IconLoader src={catalanImg} imageStyle={style} {...rest} />
    </span>
  );
};
