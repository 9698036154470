import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'store/store';

interface LocaleProps extends LocalePropsFromRedux {
  children: ReactNode;
}

export const Locale = ({ children }: LocaleProps) => {
  return <>{children}</>;
};

const mapStateToProps = ({ locale }: RootState) => {
  return {
    translations: locale.translations
  };
};

const connector = connect(mapStateToProps);

type LocalePropsFromRedux = ConnectedProps<typeof connector>;

export const LocaleProvider = connector(Locale);
