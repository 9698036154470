import { restrictRoute } from 'routes/restrictRoute';
import { publicRoutes } from 'routes/config';

import { Routes } from './Routes';

export const PublicRoutesComponent = props => <Routes routes={publicRoutes} {...props} />;

export const PublicRoutes = restrictRoute(
  publicRoutes,
  publicRoutes.oldBankSelection.path
)(PublicRoutesComponent);
