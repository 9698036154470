import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import { tenantIcons } from 'enums/tenantIcons';

import IconLoader from './IconLoader';

export const User = ({
  style = {
    height: '24px'
  }
}) => (
  <IconLoader
    tenantIconName={tenantIcons.user}
    src={PersonOutlineIcon}
    imageStyle={style}
    isMuiIcon
  />
);
