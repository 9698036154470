import React from 'react';
import { t } from 'locale';

import { localeKeys } from 'enums/localeKeys';

import { DemoBannerStyled } from './BaseStyled';

const DemoBanner = () => <DemoBannerStyled>{t(localeKeys.demoBannerText)}</DemoBannerStyled>;

export default DemoBanner;
