import IconButton from '@mui/material/IconButton';
import MuiToolbar from '@mui/material/Toolbar';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { useContext } from 'react';
import { t } from 'locale';

import { MarkDown } from 'components/MarkDown';
import { AppBarHelpMenu } from 'components/AppBar/Header/AppBarHelp';
import DemoBanner from 'components/Base/EnvironmentBanner';
import { Hamburger } from 'components/Icons/Hamburger';
import OnboardingProgressBar from 'components/OnboardingProgressBar/OnboardingProgressBarContainer';

import { localeKeys } from 'enums/localeKeys';
import { colors } from 'enums/button';
import { edge, position } from 'enums/variants';

import { isPublicRoute } from 'utils/routes';
import { isEmbeddedView } from 'utils/util';
import { parseStringTranslationKeys } from 'utils/stringTemplate';
import { isProduction } from 'utils/checkEnvironment';

import { AppBarLanguageMenu } from './AppBarLanguage';
import { AppBarProfileMenu } from './AppBarProfile';

import { AppBarContext } from '../AppBarContext';
import { AppBarStyled } from '../AppBarStyled';

export const AppBarHeader = ({ classes, logoComponent }) => {
  const {
    toggleDrawer,
    isAuthorized,
    clearBankAndGoToPreviousPage,
    showBackButton,
    tenantSettings: { bankName, mobileAsk: { navigationBarHtml } = {} }
  } = useContext(AppBarContext);

  const isEmbedded = isEmbeddedView();
  const isMobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const showHelpIconForSignup = !isMobileView && isPublicRoute();
  const isEnvironmentProduction = isProduction();

  return (
    <AppBarStyled position={position.sticky} color={colors.default} className={classes.appBar}>
      {!isEnvironmentProduction && <DemoBanner />}
      <MuiToolbar>
        {isMobileView && (
          <IconButton
            size="large"
            aria-label={t(localeKeys.menuText)}
            edge={edge.start}
            onClick={toggleDrawer}
            data-id="mobile-menu-open-button"
          >
            {isEmbedded ? <BackIcon /> : <Hamburger />}
          </IconButton>
        )}
        <IconButton
          size="large"
          aria-label={t(localeKeys.backText)}
          edge={edge.start}
          className={clsx(showBackButton ? classes.hideForAboveSm : classes.hide)}
          onClick={clearBankAndGoToPreviousPage}
          data-id="app-bar-back-button"
        >
          <BackIcon />
        </IconButton>
        {!isEmbedded && (
          <div className="app-bar">
            <div
              className={`${classes.appBarContentWrapper} app-bar__content`}
              data-id="app-bar-content"
            >
              {logoComponent}
              {navigationBarHtml ? (
                <MarkDown
                  text={parseStringTranslationKeys(navigationBarHtml)}
                  htmlTag="div"
                  data-id="tenant-navigation-bar"
                  className={classes.hideForSm}
                />
              ) : null}
            </div>
            {showHelpIconForSignup && <AppBarHelpMenu bankName={bankName} />}
            <AppBarLanguageMenu />
            {isAuthorized && <AppBarProfileMenu />}
          </div>
        )}
      </MuiToolbar>
      <OnboardingProgressBar />
    </AppBarStyled>
  );
};
