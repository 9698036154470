import { matchPath, withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import branch from 'recompose/branch';
import compose from 'recompose/compose';
import renderComponent from 'recompose/renderComponent';

export const restrictRoute = (routes, redirectPath) =>
  compose(
    withRouter,
    branch(
      ({ location }) => {
        const filteredRoute = Object.entries(routes).filter(([key, item]) => {
          return matchPath(location.pathname, {
            path: item.path,
            exact: true
          });
        });

        return filteredRoute.length === 0;
      },
      renderComponent(() => <Redirect to={redirectPath} />)
    )
  );
