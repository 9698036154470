import { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'routes';
import { removeStorageItem, setStorageItem } from 'locale';
import { registerRoute } from 'slices/routes';
import { selectBank } from 'slices/banks';

import { Creators as AppCreators } from 'actions/app';
import { Creators as MandateCreators } from 'actions/mandates';
import { RestrictApp } from 'components/RestrictApp/RestrictApp';
import { appMenuSelector } from 'selectors/appSelectors';
import { localeSelector } from 'selectors/localeSelector';
import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';

import { storageKeys } from 'enums/storage';
import { routeTypes } from 'enums/routeTypes';

import { routes } from 'routes/config';

import { getRestrictAppMessage } from 'utils/restrictApp';
import { isEmbeddedView } from 'utils/util';

class Container extends Component {
  componentDidMount() {
    const {
      registerRoute,
      id,
      title,
      isProtected,
      path,
      type = routeTypes.public,
      appMenuHidden,
      hideAppMenu,
      location
    } = this.props;

    if (id === routes.autoLogin.id && location.search.includes('embedded=true')) {
      setStorageItem(storageKeys.embedded, true);
    }

    hideAppMenu(!appMenuHidden && isEmbeddedView());

    if (this.shouldRegisterRoute()) {
      // This flag is needed to properly calculate progress bar steps and back button route
      if (id === routes.signupIban.id) {
        setStorageItem(storageKeys.HAS_IBAN_SCREEN, true);
      }

      if (id === routes.overview.id) {
        removeStorageItem(storageKeys.HAS_IBAN_SCREEN);
      }

      registerRoute({ id, title, isProtected, type, path });

      const { state } = Object.fromEntries(new URLSearchParams(history.location.search));

      if (state) {
        this.restoreRegShieldWidgetRedirectChallenge(state);
      }
    }
  }

  /**
   * Function used for restoring RegShieldWidgetRedirectChallenge
   * State query param is a flag which indicates that ask-ui is opened by a Bank after the widget redirect challenge.
   * There are 2 possible scenarios:
   * 1. Sign-up - via /old-bank page - we restore selectedBank and the widget with state query parameter
   * 2. Transfer balance - via /transfer-balance - we restore selectedPaymentAmount and the widget with state query parameter
   * @param {string} state
   */
  restoreRegShieldWidgetRedirectChallenge = state => {
    const { selectBank, getPaymentLocation, id: routeId } = this.props;

    let storedRedirectChallengeInfo = window.serverEnvConfig.redirectChallengeData;

    if (storedRedirectChallengeInfo) {
      const { widgetBaseUrl, selectedPaymentAmount, selectedBank } = storedRedirectChallengeInfo;

      if (routeId === routes.oldBankSelection.id) {
        selectBank({ selectedBank, redirectChallengeData: { widgetBaseUrl, state } });
        history.replace(routes.oldBankSelection.path);
      } else if (routeId === routes.transferBalance.id) {
        getPaymentLocation({ widgetBaseUrl, selectedPaymentAmount, state });
        history.replace(routes.transferBalance.path);
      }
    }
  };

  shouldRegisterRoute = () => {
    return this.props.id !== routes.autoLogin.id;
  };

  render() {
    const {
      tenantSettings,
      isRegisteredFromLink,
      component: RouteComponent,
      ...otherProps
    } = this.props;
    const { active, magicLinkSettings, disableUserRegistration } = tenantSettings;
    const { requireLinkUse, allowConventionalLogin } = magicLinkSettings || {};
    const { pathname } = history.location;
    const suppressRestriction = pathname === routes.signHere.path;

    const isRestrictAppViaMagicLinkOnly =
      !suppressRestriction && requireLinkUse && this.shouldRegisterRoute() && !isRegisteredFromLink;

    const isRestrictAppUserRegistration =
      disableUserRegistration && pathname === routes.oldBankSelection.path;
    const isNormalUser = !isRegisteredFromLink && !requireLinkUse;
    const isMagicLinkUserWithConventionalLogin = isRegisteredFromLink && allowConventionalLogin;

    let shouldDisplayLoginLink =
      active &&
      isRestrictAppUserRegistration &&
      (isNormalUser || isMagicLinkUserWithConventionalLogin);

    if (!active || isRestrictAppViaMagicLinkOnly || isRestrictAppUserRegistration) {
      const restrictAppMessage = getRestrictAppMessage({ tenantSettings, isRegisteredFromLink });

      return (
        <RestrictApp
          restrictAppMessage={restrictAppMessage}
          isWithLoginLink={shouldDisplayLoginLink}
          isWithTitle={pathname !== routes.oldBankSelection.path}
        />
      );
    }

    return <RouteComponent {...otherProps} />;
  }
}

const mapStateToProps = state => {
  return {
    ...tenantSettingsSelector(state),
    ...appMenuSelector(state),
    ...localeSelector(state)
  };
};

export const RouteContainer = connect(mapStateToProps, {
  registerRoute,
  hideAppMenu: AppCreators.hideAppMenu,
  selectBank,
  getPaymentLocation: MandateCreators.getPaymentLocation
})(Container);
