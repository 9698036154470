import { put, select, takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';
import { t } from 'locale';

import { Creators as ApiCreators } from 'api/actions';
import { Types } from 'actions/mandates';
import { Creators as SnackbarCreators } from 'actions/snackbar';
import { fetchSaga } from 'api/fetchSaga';
import { clearKeysFromStore } from 'sagas/app';
import { getTransferAmount } from 'selectors/transferBalanceTanSelector';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';
import { localeKeys } from 'enums/localeKeys';

const { apis } = config;
const { GET_TRANSFER_BALANCE, GET_PAYMENT_LOCATION, FINALIZE_TRANSFER, CLEAR_PAYMENT_LOCATION } =
  Types;

export function* getTransferBalance() {
  const params = {
    path: apis.transferBalance
  };

  return yield fetchSaga({
    params,
    actionType: GET_TRANSFER_BALANCE,
    fields: 'balance'
  });
}

export function* getPaymentLocation({ redirectChallengeData }) {
  if (redirectChallengeData) {
    const { widgetBaseUrl, selectedPaymentAmount, state } = redirectChallengeData;
    const location = `${widgetBaseUrl}?state=${state}`;

    return yield put(
      ApiCreators.apiResponse(GET_PAYMENT_LOCATION, { location, selectedPaymentAmount })
    );
  }

  const amount = yield select(getTransferAmount);

  const params = {
    path: apis.balanceTransfers,
    method: httpMethods.POST,
    data: {
      amount: amount * 100,
      redirectUrl: window.location.origin
    }
  };

  yield fetchSaga({
    params,
    actionType: GET_PAYMENT_LOCATION
  });
}

export function* finalizeTransfer({ payload: { state } }) {
  const params = {
    path: apis.balanceTransfersFinalize(state),
    method: httpMethods.PATCH
  };

  const response = yield fetchSaga({
    params,
    actionType: FINALIZE_TRANSFER
  });

  yield getTransferBalance();
  window.serverEnvConfig.redirectChallengeData = null;
  yield clearKeysFromStore([GET_PAYMENT_LOCATION, FINALIZE_TRANSFER]);

  if (response) {
    yield put(SnackbarCreators.triggerSnack(t(localeKeys.transferSuccessfulText)));
    history.push(routes.overview.path);
  }
}

function* clearPaymentLocation() {
  const keysToReset = [GET_PAYMENT_LOCATION];
  yield clearKeysFromStore(keysToReset);
}

export function* getTransferBalanceSaga() {
  yield takeEvery(GET_TRANSFER_BALANCE, getTransferBalance);
  yield takeEvery(GET_PAYMENT_LOCATION, getPaymentLocation);
  yield takeEvery(CLEAR_PAYMENT_LOCATION, clearPaymentLocation);
  yield takeEvery(FINALIZE_TRANSFER, finalizeTransfer);
}
