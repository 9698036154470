import { Route } from 'routes';

import { LoaderLayout } from 'components/Loaders/LoaderLayout';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const directDebitRoutes: Record<string, Route> = {
  directDebits: {
    id: 'directDebits',
    title: 'navigationDirectDebits',
    path: '/direct-debits',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./DirectDebitsContainer')
    })
  },
  directDebitItem: {
    id: 'directDebitItem',
    title: 'navigationDirectDebits',
    basePath: '/direct-debit/',
    path: '/direct-debit/:id',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./DirectDebitDetailsContainer')
    })
  }
};

export default directDebitRoutes;
