import { put, select, takeEvery } from 'redux-saga/effects';
import invert from 'lodash/invert';
import { history } from 'routes';

import { fetchSaga } from 'api/fetchSaga';
import { getCreditorMandates } from 'sagas/mandates';
import { appSelector } from 'selectors/appSelectors';
import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';
import { Creators, Types } from 'locale/actions';
import {
  getLangTranslationsFromLocalStorage,
  storeLangTranslationsToLocalStorage,
  getLangHashFromLocalStorage,
  storeLangHashToLocalStorage,
  getSelectedLanguageFromLocalStorage,
  storeSelectedLanguageToLocalStorage
} from 'locale/storage';

import { config } from 'config';

const { apis } = config;

function* getLocaleAndMandates({ lang }) {
  yield getLocale({ lang });

  const { isAuthenticated } = yield select(appSelector);
  if (isAuthenticated) {
    yield getCreditorMandates();
  }
}

export function* getLang(lang) {
  const params = {
    path: apis.locale(lang)
  };

  return yield fetchSaga({
    params,
    actionType: 'CURRENT_LOCALE',
    payload: lang
  });
}

export function* getLocaleHash(lang) {
  const params = {
    path: apis.locales
  };

  return yield fetchSaga({
    params,
    actionType: Types.GET_LOCALE_HASH,
    fields: lang
  });
}

export function* getLocale({ lang }) {
  const {
    tenantSettings: { tenantInformal }
  } = yield select(tenantSettingsSelector);

  const informalLang = config.informalLanguages[lang] ?? '';
  const isInformalLanguage = tenantInformal && Boolean(informalLang);

  const _lang = isInformalLanguage ? informalLang : lang;

  yield put(Creators.setLang({ lang, informalLang, tenantInformal }));

  yield storeSelectedLanguageToLocalStorage(_lang);

  try {
    let langObj;
    const hash = yield getLocaleHash(_lang);

    if (getLangHashFromLocalStorage(_lang) !== hash.toString()) {
      langObj = yield getLang(_lang);
      storeLangTranslationsToLocalStorage(_lang, langObj);
      storeLangHashToLocalStorage(_lang, hash);
    } else {
      langObj = getLangTranslationsFromLocalStorage(_lang);
    }

    yield put(Creators.setLocale(langObj.locale, langObj.translations));
  } catch (err) {
    console.warn(err);
  }
}

export function* initializeLocale(tenantSettings) {
  const DEFAULT_SETTINGS = {
    defaultLanguage: config.defaultLanguage,
    allowedLanguages: []
  };

  const { defaultLanguage, allowedLanguages } = tenantSettings || DEFAULT_SETTINGS;

  const storedSelectedLanguage = getSelectedLanguageFromLocalStorage();
  const informalLanguageMap = invert(config.informalLanguages);
  const storedLanguage = informalLanguageMap[storedSelectedLanguage] ?? storedSelectedLanguage;
  const storedLanguageIsAllowed = checkIfLanguageIsAllowed(storedLanguage, allowedLanguages);

  const params = new URLSearchParams(history.location.search.replace('?', ''));
  const queryParams = Object.fromEntries(params);

  const queryParamLanguage = queryParams.language?.toLowerCase();

  const queryParamLanguageIsAllowed = checkIfLanguageIsAllowed(
    queryParamLanguage,
    allowedLanguages
  );

  let lang = defaultLanguage;

  if (storedLanguageIsAllowed && storedLanguage !== defaultLanguage) {
    lang = storedLanguage;
  }

  if (queryParamLanguageIsAllowed && queryParamLanguage !== lang) {
    lang = queryParamLanguage;
  }

  yield getLocale({ lang });
}

const checkIfLanguageIsAllowed = (langCode, allowedLanguages) =>
  allowedLanguages.find(language => language.code === langCode) !== undefined;

export function* getLocaleSaga() {
  yield takeEvery(Types.GET_LOCALE, getLocaleAndMandates);
}
