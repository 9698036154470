import makeStyles from '@mui/styles/makeStyles';

import { config } from 'config';

import { themeBreakPoints } from 'enums/keys';

const drawerWidth = config.defaultDrawerWidth;

export const getAppBarMobileClasses = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  hide: {
    display: 'none !important'
  },
  hideForAboveSm: {
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up(themeBreakPoints.sm)]: {
      display: 'none !important'
    }
  },
  hideForSm: {
    [theme.breakpoints.down(themeBreakPoints.xs)]: {
      display: 'none !important'
    }
  },
  appBarContentWrapper: {
    [theme.breakpoints.up(themeBreakPoints.sm)]: {
      'justify-content': 'left !important'
    },
    justifyContent: 'center !important'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    [theme.breakpoints.up(themeBreakPoints.sm)]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: drawerWidth,
      [theme.breakpoints.only(themeBreakPoints.xs)]: {
        marginLeft: 0
      }
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up(themeBreakPoints.sm)]: {
      position: 'fixed'
    }
  },
  backButton: {
    display: 'none',
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.only(themeBreakPoints.xs)]: {
      display: 'block'
    }
  }
}));
