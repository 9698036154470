import { template, templateSettings } from 'lodash';
import { t } from 'locale';

import { LocaleKey } from 'locale/types';

import { config } from 'config';

import { isDevelopment } from 'utils/checkEnvironment';

interface ParseStringAsTemplate {
  (string: LocaleKey, variables?: Record<string, unknown>, delimiter?: RegExp): string;
}

export const parseStringAsTemplate: ParseStringAsTemplate = (
  string,
  variables = {},
  delimiter = config.defaultStringTemplateDelimiter
) => {
  if (delimiter) {
    templateSettings.interpolate = delimiter;
  }

  /* In case provided variable identifiers are invalid (not found in string) return plain string
    if it's production mode - otherwise throw an error
  */
  try {
    return template(t(string))(variables);
  } catch (err) {
    if (isDevelopment()) {
      throw err;
    }

    return string;
  }
};

interface ParseStringTranslationKeys {
  (string: string, translator?: typeof t): string;
}

export const parseStringTranslationKeys: ParseStringTranslationKeys = (string, translator = t) => {
  if (!string) return '';

  const matchTranslationKeysRegEx = /%{(\w+)}/g; // %{translationKey}
  const matched = Array.from(string.matchAll(matchTranslationKeysRegEx));

  for (const { 0: keyToBeReplaced, 1: translationKey } of matched) {
    string = string.replace(keyToBeReplaced, translator(translationKey as LocaleKey));
  }

  return string;
};
