import { tenantIcons } from 'enums/tenantIcons';

import incomingPayments from './default/Dark/incoming_payments.svg';
import IconLoader from './IconLoader';

export const IncomingPayments = ({
  style = {
    width: '32px'
  }
}) => (
  <IconLoader
    tenantIconName={tenantIcons.incomingPayments}
    src={incomingPayments}
    imageStyle={style}
  />
);
