import { put, takeEvery } from 'redux-saga/effects';

import { Creators } from 'actions/account';
import { Types } from 'actions/banks';

const { loginToOldBank } = Creators;

const { START_MANUAL_ACCOUNT_SWITCH_SIGNUP } = Types;

export function* startManualAccountSwitchSignup({ payload, params, accountType }) {
  yield put(loginToOldBank(payload, params, accountType));
}

export function* manualAccountSwitchSaga() {
  yield takeEvery(START_MANUAL_ACCOUNT_SWITCH_SIGNUP, startManualAccountSwitchSignup);
}
