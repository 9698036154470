import { Route } from 'routes';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const signUpOldBankRoutes: Record<string, Route> = {
  oldBankSelection: {
    id: 'oldBankSelection',
    title: 'navigationAccountSwitchText',
    path: '/old-bank',
    isProtected: true,
    type: routeTypes.default,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('./SignUpOldBankContainer')
    })
  }
};

export default signUpOldBankRoutes;
