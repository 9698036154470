export const getSubDomain = (host: string) => host.split('.')[1];

export const extractUrlFromString = (value: string) => {
  /* Match URL's from the given string.
   * Examples:
   * 1) "Here is a URL https://www.example.com"
   * 2) "Here is a URL https://www.example.com)"
   * https?:\/\/   // matches https:// between zero and one times
   * [^\s)]*         // matches the start of a string without consuming any characters that ends on ')' or space
   */
  const urlRegex = /(https?:\/\/[^\s)]*)/;
  const matchedRegex = value ? value.match(urlRegex) : '';
  return matchedRegex ? matchedRegex[1] : '';
};

export const extractBetweenSquareBrackets = (value: string) => {
  /*
   * \[ : [ is a meta char and needs to be escaped if you want to match it literally.
   * (.*?) : match everything in a non-greedy way and capture it.
   * \] : ] is a meta char and needs to be escaped if you want to match it literally.
   */
  const textRegex = /\[(.*?)\]/;
  const matchedRegex = value ? value.match(textRegex) : '';
  return matchedRegex ? matchedRegex[1] : '';
};

export const getPrivacyPolicyUrlAndTitle = (value: string) => {
  return {
    title: extractBetweenSquareBrackets(value),
    url: extractUrlFromString(value)
  };
};
