import { takeEvery } from 'redux-saga/effects';

import { Types } from 'actions/banks';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

const { apis } = config;

const LIMIT = 100;

export function* bankSelectionSaga({ query }) {
  const params = {
    path: apis.banks,
    params: {
      q: query,
      limit: LIMIT
    }
  };

  return yield fetchSaga({
    params,
    actionType: Types.GET_BANK_SELECTIONS,
    fields: 'banks'
  });
}

export function* banksSaga() {
  yield takeEvery(Types.GET_BANK_SELECTIONS, bankSelectionSaga);
}
