import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import snakeCase from 'lodash/snakeCase';

/**
 * @example
 *   import transformKeys from './transformKeys';
 *   transformKeys({bad_key: 1});   => {badKey: 1}
 *   transformKeys([{bad_key: 1}]); => [{badKey: 1}]
 */

type KeyTransformType = 'SNAKE_CASE' | 'CAMEL_CASE';
type Obj = Record<string, unknown>;

export function transformKeys(
  object: Obj | unknown[],
  to: KeyTransformType = 'CAMEL_CASE'
): Obj | unknown[] {
  let obj = cloneDeep(object);
  let transformer: (str: string) => string;

  if (to === 'SNAKE_CASE') {
    transformer = snakeCase;
  }

  if (to === 'CAMEL_CASE') {
    transformer = camelCase;
  }

  if (isArray(obj)) {
    return map(obj, (item: Obj) => transformKeys(item, to));
  } else {
    obj = mapKeys(obj, (value: unknown, key: string) => {
      return transformer(key);
    });

    // Recursively apply throughout object
    return mapValues(obj, (value: unknown) => {
      if (isPlainObject(value)) {
        return transformKeys(value as Obj, to);
      } else if (isArray(value)) {
        return map(value, (item: unknown) => {
          if (typeof item !== 'object' || item === null) {
            return item;
          }
          return transformKeys(item as Obj, to);
        });
      } else {
        return value;
      }
    });
  }
}
