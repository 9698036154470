import { OnRegShieldFinish } from 'components/RegShield/types';
import { TransferBalanceRedirectChallengeData } from 'actions/account';

import { dispatchingActions } from 'enums/actions';

import { createActions } from 'utils/redux';

export type GetMandateHintAction = {
  type: 'GET_MANDATE_HINT';
  id: string;
};

export type GetMandateHintActionCreator = (id: GetMandateHintAction['id']) => void;

export type SetTransferAmountActionCreator = (amount: number) => void;

export type GetPaymentLocationActionCreator = (
  redirectChallengeData?: TransferBalanceRedirectChallengeData
) => void;

export type FinalizeTransferActionCreator = OnRegShieldFinish;

export const { Types, Creators } = createActions({
  deleteMandate: [dispatchingActions.id],
  getMandateHint: [dispatchingActions.id],

  // Manual Mandate
  notifyManualMandate: [dispatchingActions.payload, dispatchingActions.action],

  // Direct debit actions
  getDirectDebits: [],
  ignoreDirectDebit: [dispatchingActions.id],
  markDirectDebitAsDone: [dispatchingActions.id],
  markDirectDebitAsUndone: [dispatchingActions.id],
  notifyDirectDebit: [dispatchingActions.payload, dispatchingActions.action],
  saveDirectDebitPartner: [dispatchingActions.payload, dispatchingActions.action],
  // Incoming payment actions
  getIncomingPayments: [],
  saveIncomingPaymentsPartner: [dispatchingActions.payload, dispatchingActions.action],
  markIncomingPaymentAsDone: [dispatchingActions.id],
  markIncomingPaymentAsUndone: [dispatchingActions.id],
  saveIncomingPayment: [dispatchingActions.values, dispatchingActions.props],
  notifyIncomingPayments: [dispatchingActions.payload, dispatchingActions.action],
  ignoreIncomingPayment: [dispatchingActions.id],
  // Payment partners
  getPaymentPartner: [dispatchingActions.id],
  selectPaymentPartner: [dispatchingActions.payload],
  getPaymentPartnerSelection: [dispatchingActions.payload],
  // Standing orders
  getStandingOrders: [],
  doneIgnoreStandingOrders: [dispatchingActions.payload],
  acknowledgeStandingOrders: [dispatchingActions.payload],
  // Transfer balance
  getTransferBalance: [],
  clearPaymentLocation: [],
  getPaymentLocation: [dispatchingActions.redirectChallengeData],
  finalizeTransfer: [dispatchingActions.payload],
  setTransferAmount: [dispatchingActions.amount]
});
