import { inputClasses } from '@mui/material';

export const spacer = {
  xsm: 'xsm',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  xxxl: 'xxxl',
  fiveXl: 'fiveXl'
};

export const transformCase = {
  SnakeCase: 'SNAKE_CASE',
  CamelCase: 'CAMEL_CASE'
};

// these ids are used as `data-id` for automation
export const dataId = {
  toggleMenuButton: 'toggle-menu-button',
  signupHintMessage: 'sign-up-hint-message',
  signupNotSupported: 'sign-up-not-supported'
};

// Used for react-country-flag
export const countryCodes = {
  en: 'en',
  ca: 'ca',
  gb: 'gb'
};

export const gridNumbers = {
  auto: 'auto',
  onwColumn: 1,
  twoColumn: 2,
  threeColumn: 3,
  fourColumn: 4,
  fiveColumn: 5,
  sixColumn: 6,
  sevenColumn: 7,
  eightColumn: 8,
  nineColumn: 9,
  tenColumn: 10,
  elevenColumn: 11,
  twelveColumn: 12
} as const;

export const inputTypes = {
  tel: 'tel',
  submit: 'submit',
  search: 'search',
  number: 'number',
  text: 'text',
  password: 'password',
  email: 'email',
  date: 'date'
};

export const themeBreakPoints = {
  xs: 'xs', //extra-small: 0px
  sm: 'sm', // small: 600px
  md: 'md', // medium: 960px
  lg: 'lg', // large: 1280px
  xl: 'xl' // extra- large: 1920px
};

export const tenantVisualSettingsMUIOverridesPrefix = {
  pseudoPrefixAfter: '&::after',
  pseudoPrefixBefore: '&::before',
  dollarPrefixFocused: `&.${inputClasses.focused}`,
  dollarPrefixError: `&.${inputClasses.error}`
};

export const sessionStatusText = {
  sessionActive: 'session active',
  sessionExpire: 'session expired',
  sessionTimeout: 'Session timed out, please sign in again.'
};

export const autoCompleteOnChangeReason = {
  selectOption: 'selectOption',
  clear: 'clear'
};

export const autoCompleteOnInputChangeReason = {
  input: 'input'
};

export const termsConditions = {
  tosElementId: 'tos',
  shortTerms: 'short'
};
