import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import styled from 'styled-components';

import { textVariants } from 'enums/variants';

export const Text = styled(Typography).attrs({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  component: 'div'
})``;

export const PrimaryText = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizes.XS};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const SecondaryText = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizes.XS};
  color: ${({ theme }) => theme.palette.doveGrey};
`;

export const ErrorText = styled.span.attrs({ role: 'alert' })`
  display: block;
  font-size: ${({ theme }) => theme.typography.fontSizes.XS};
  color: ${({ theme: { palette } }) => palette.text.error ?? palette.red};
  height: 20px;
`;

export const SuccessText = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizes.XS};
  color: ${({ theme: { palette } }) => palette.text.success ?? palette.successGreen};
`;

export const SuccessIcon = styled(DoneIcon)`
  && {
    color: ${({ theme: { palette } }) => palette.text.success ?? palette.successGreen};
  }
`;

export const SuccessCircleIcon = styled(CheckCircleIcon)`
  && {
    color: ${({ theme: { palette } }) => palette.text.success ?? palette.successGreen};
  }
`;

export const WarningText = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.typography.fontSizes.XS};
  color: ${({ theme }) => theme.palette.orange};
`;

export const H1Title = styled(Typography).attrs({
  component: textVariants.h1,
  variant: textVariants.h1
})``;

export const H2Title = styled(Typography).attrs({
  component: textVariants.h2,
  variant: textVariants.h2
})``;

export const H5Title = styled(Typography).attrs({
  component: textVariants.h5,
  variant: textVariants.h5
})``;

export const H3Title = styled(Typography).attrs({
  component: textVariants.h3,
  variant: textVariants.h3
})``;

export const Caption = styled(Typography).attrs({
  component: textVariants.paragraph,
  variant: textVariants.caption
})``;

export const Content = styled(Caption)`
  && {
    font-size: ${({ theme: { typography } }) => typography.fontSizes.S};
    margin: 12px 0 16px 0;
  }
`;

export const PageTitle = styled(H2Title)`
  && {
    flex: 1;
    text-align: center;
    width: 100%;
  }
`;

export const TextWithButtonWrapper = styled.div`
  padding: 25px;
  margin: 0 auto 30px auto;
  background-color: ${({ theme: { palette } }) =>
    palette.background.secondary ?? palette.common.white};
  max-width: ${({ theme: { appMainContainer } }) => appMainContainer.maxWidth};
  border-top: 1px solid #e6e6e6;
`;

export const TextWithButton = styled(H3Title)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin-left: 5px;
      font-weight: ${({ theme: { typography } }) => typography.fontWeights.semiBold};
      text-align: center;
    }
  }
`;
