import get from 'lodash/get';
import { object, string } from 'prop-types';
import { connect } from 'react-redux';

import { tenantIconsSelector, tenantSettingsSelector } from 'selectors/tenantSettingsSelector';

import { tenantsWithCustomIcons } from 'enums/tenantIcons';

import { ImageLoader } from './ImageLoader';

const IconLoader = ({ tenantIcons, tenantId, tenantIconName, src, imageStyle, isMuiIcon }) => {
  const tenantProvidedIcons = get(tenantsWithCustomIcons, [tenantId]);
  const defaultIcons = get(tenantsWithCustomIcons, ['default'], {});
  const tenantCustomIcons = tenantProvidedIcons || defaultIcons;
  const tenantIcon = tenantCustomIcons[tenantIconName];
  const atlasTenantIcon = tenantIcons[tenantIconName];
  const iconPath = tenantProvidedIcons
    ? new URL(`./${tenantId}/${tenantIcon}`, import.meta.url).href
    : new URL(`./default/Dark/${tenantIcon}`, import.meta.url).href;

  /*  
    Case 1: if tenant icon is hardcoded
    Case 2: if tenant icon is in Atlas
    Case 3: if material ui icon (few icons are not available in `./img`)
    Case 4: if icon is from `./img`
  */

  if (tenantIcon) {
    return <ImageLoader src={iconPath} imageStyle={imageStyle} />;
  } else if (atlasTenantIcon) {
    return <ImageLoader src={atlasTenantIcon} imageStyle={imageStyle} />;
  } else if (isMuiIcon) {
    const MuiIcon = src;
    return <MuiIcon />;
  }

  return <ImageLoader src={src} imageStyle={imageStyle} />;
};

IconLoader.defaultProps = {
  tenantIcons: {},
  imageStyle: {},
  src: '',
  tenantIconName: '',
  isMuiIcon: false
};

IconLoader.propTypes = {
  tenantIcons: object,
  imageStyle: object,
  tenantIconName: string
};

const mapStateToProps = state => {
  const { tenantIcons } = tenantIconsSelector(state);
  const { tenantSettings } = tenantSettingsSelector(state);

  return {
    tenantIcons,
    tenantId: tenantSettings.tenantId
  };
};

export default connect(mapStateToProps, null)(IconLoader);
