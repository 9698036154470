import { tenantIcons } from 'enums/tenantIcons';

import directDebits from './default/Dark/direct_debits.svg';
import IconLoader from './IconLoader';

export const DirectDebit = ({
  style = {
    width: '32px'
  }
}) => (
  <IconLoader tenantIconName={tenantIcons.directDebits} src={directDebits} imageStyle={style} />
);
