import { FormikHelpers as FormikActions } from 'formik';

import { IdCard } from 'models/IdCard';

import { createActions } from 'utils/redux';

export type GetIdCardActionCreator = () => void;

export type GetIdCardAction = {
  type: ' GET_ID_CARD';
};

export type EnableIdCardActionCreator = (status: boolean) => void;

export type EnableIdCardAction = {
  type: 'ENABLE_ID_CARD';
  status: boolean;
};

export type SaveIdCardActionCreator = (payload: unknown, action: FormikActions<unknown>) => void;

type IdCardPayload = { [P in keyof IdCard]: NonNullable<IdCard[P]> };

export type SaveIdCardAction = {
  type: 'SAVE_ID_CARD';
  payload: IdCardPayload;
  action: FormikActions<IdCardPayload>;
};

export const { Types, Creators } = createActions({
  saveIdCard: ['payload', 'action'],
  getIdCard: [],
  enableIdCard: ['status']
});
