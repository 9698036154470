import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import PreviousIcon from '@mui/icons-material/ExpandLess';
import NextIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { t } from 'locale';

import { localeKeys } from 'enums/localeKeys';
import { inputTypes } from 'enums/keys';

import { useIsUpdated } from 'hooks/useIsUpdated';

import { scrollToElementInModal } from 'utils/scrollToElementInModal';

import { InputBase, ModalStyles, SearchControls, SearchInput } from './ModalStyled';

const MATCHED_ELEMENT_KEY = `highlight_${new Date().getTime()}`;

enum NavigationDirections {
  next = 'next',
  previous = 'previous'
}

export type SearchProps = {
  updateModalData: (content: string) => void;
  modalData: {
    modalContent: string;
    unHighlightedModalContent: string;
  };
};

interface ModalSearchInputProps {
  searchProps: SearchProps;
}

export const ModalSearchInput = ({
  searchProps: {
    updateModalData,
    modalData: { modalContent, unHighlightedModalContent }
  }
}: ModalSearchInputProps): React.ReactElement => {
  const classes = ModalStyles();
  const [query, setQuery] = useState('');
  const [matchedCount, setMatchedCount] = useState(0);
  const [searchIndex, setSearchIndex] = useState(1);
  const [highlightedElements, setHighlightedElements] = useState<NodeListOf<Element> | []>([]);

  useIsUpdated(() => queryElements(), modalContent);
  useIsUpdated(() => scrollToSearch(), searchIndex);
  useIsUpdated(() => scrollToSearch(), highlightedElements);

  const queryElements = () => {
    const elements = document.querySelectorAll(`.${MATCHED_ELEMENT_KEY}`);
    setSearchIndex(0);
    setHighlightedElements(elements);
    setMatchedCount(elements.length);
  };

  const typeToSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(value);
    searchAndReplace(value);
  };

  const setIndex = ({ navigateTo }: { navigateTo: NavigationDirections }) => {
    const currentIndex =
      navigateTo === NavigationDirections.next ? searchIndex + 1 : searchIndex - 1;
    if (currentIndex >= 0 && currentIndex < matchedCount) {
      setSearchIndex(currentIndex);
    }
  };

  const searchAndReplace = (query: string) => {
    // replace all the special characters with '\'
    const escapeRegex = query.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');

    const content = !query
      ? unHighlightedModalContent
      : unHighlightedModalContent.replace(
          new RegExp(escapeRegex, 'gi'),
          match => `<span class="highlight ${MATCHED_ELEMENT_KEY}">${match}</span>`
        );

    updateModalData(content);
  };

  const scrollToSearch = () => {
    const elementToScrollTo = highlightedElements[searchIndex] as HTMLElement;
    if (elementToScrollTo) {
      scrollToElementInModal(elementToScrollTo);
    }
  };

  return (
    <SearchInput>
      <InputBase
        type={inputTypes.search}
        value={query}
        onChange={typeToSearch}
        placeholder={`${t(localeKeys.searchText)}...`}
        classes={{ inputTypeSearch: classes.inputInput }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      {Boolean(query && matchedCount) && (
        <SearchControls>
          <span>
            {searchIndex + 1}/{matchedCount}
          </span>
          <span>
            <IconButton
              aria-label="Previous"
              size="small"
              onClick={() => setIndex({ navigateTo: NavigationDirections.previous })}
            >
              <PreviousIcon />
            </IconButton>
            <IconButton
              aria-label="Next"
              size="small"
              onClick={() => setIndex({ navigateTo: NavigationDirections.next })}
            >
              <NextIcon />
            </IconButton>
          </span>
        </SearchControls>
      )}
    </SearchInput>
  );
};
