import IconButton from '@mui/material/IconButton';
import MuiSnackbarContent from '@mui/material/SnackbarContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import clsx from 'clsx';
import React from 'react';
import { t } from 'locale';

import { localeKeys } from 'enums/localeKeys';

import { snackbarStyles } from './SnackbarStyled';

const SNACKBAR_ID = 'client-snackbar';

const snackbarIcons = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

type Snackbar = 'error' | 'info' | 'success' | 'warning';

interface SnackbarContentProps {
  message: string;
  type: Snackbar;
  onClose: VoidFunction;
}

const SnackbarContentComponent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  SnackbarContentProps
> = ({ message, type = 'success', onClose: closeSnackbar }: SnackbarContentProps, ref) => {
  const classes = snackbarStyles();
  const Icon = snackbarIcons[type];

  return (
    <MuiSnackbarContent
      ref={ref}
      data-id="snackbar-content"
      className={clsx(classes.content, classes[type])}
      aria-describedby={SNACKBAR_ID}
      message={
        <span id={SNACKBAR_ID} className={classes.message} data-id="snackbar-message">
          <Icon
            className={clsx(classes.icon, classes.iconVariant)}
            data-id="snackbar-message-icon"
          />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label={t(localeKeys.closeButton)}
          size="small"
          color="inherit"
          onClick={closeSnackbar}
          data-id="snackbar-button-close"
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
    />
  );
};

export const SnackbarContent = React.forwardRef(SnackbarContentComponent);
