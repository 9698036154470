import { Route } from 'routes';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const updatePasswordRoutes: Record<string, Route> = {
  updateEmailPassword: {
    id: 'updateEmailPassword',
    title: 'navigationUpdateEmailPassword',
    path: '/update-account',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loader: () => import('./UpdateEmailPasswordContainer')
    })
  },
  emailUpdateSuccess: {
    id: 'emailUpdateSuccess',
    title: 'navigationUpdateEmailPassword',
    path: '/update-email-success',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loader: () => import('components/UpdateEmailPassword/UpdateEmailSuccess')
    })
  },
  passwordUpdateSuccess: {
    id: 'passwordUpdateSuccess',
    title: 'navigationUpdateEmailPassword',
    path: '/update-password-success',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loader: () => import('components/UpdateEmailPassword/UpdatePasswordSuccess')
    })
  }
};

export default updatePasswordRoutes;
