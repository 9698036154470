import { useEffect } from 'react';

/**
 * This function is a simple wrapper around `useEffect`. Its sole purpose
 * is to increase the readability of the code.
 * @param method
 */
export function useAfterRender(method: VoidFunction) {
  useEffect(method);
}
