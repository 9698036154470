import { Route } from 'routes';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const signUpIbanRoutes: Record<string, Route> = {
  signupIban: {
    id: 'signupIban',
    title: 'navigationAccountSwitchText',
    path: '/sign-up/iban',
    isProtected: true,
    type: routeTypes.default,
    redirectOnUnauthorized: true,
    component: Loadable({
      loader: () => import('./SignUpIbanContainer')
    })
  }
};

export default signUpIbanRoutes;
