import { takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';

import { Types } from 'actions/account';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

const { apis } = config;
const { REQUEST_RESET_PASSWORD, REQUEST_RESET_PASSWORD_TOKEN } = Types;

export function* requestResetPasswordToken({ payload: { email }, action }) {
  action.setSubmitting(true);

  const params = {
    path: apis.resetPassword,
    method: httpMethods.POST,
    data: {
      email
    }
  };

  const response = yield fetchSaga({
    params,
    actionType: REQUEST_RESET_PASSWORD_TOKEN
  });

  action.setSubmitting(false);

  if (response) {
    history.replace(routes.resetPasswordTokenSuccess.path);
  }
}

export function* requestResetPassword({ payload: { password, token }, action }) {
  action.setSubmitting(true);

  const params = {
    path: apis.passwordWithToken,
    method: httpMethods.PUT,
    data: {
      password,
      token
    }
  };

  const response = yield fetchSaga({
    params,
    actionType: REQUEST_RESET_PASSWORD
  });

  action.setSubmitting(false);

  if (response) {
    history.replace(routes.resetPasswordSuccess.path);
  }
}

export function* resetPasswordSaga() {
  yield takeEvery(REQUEST_RESET_PASSWORD_TOKEN, requestResetPasswordToken);
  yield takeEvery(REQUEST_RESET_PASSWORD, requestResetPassword);
}
