import styled from 'styled-components';
import { HTMLAttributes } from 'react';
import get from 'lodash/get';

import { Text } from 'components/Text/TextStyled';

const sizeEnum = {
  default: '1px',
  xsm: '2px',
  lg: '5px',
  xl: '10px',
  xxl: '15px',
  sm: '3px'
};

const spacerEnum = {
  default: '1rem',
  xsm: '0.3rem',
  sm: '0.5rem',
  md: '0.75rem',
  lg: '1.2rem',
  xl: '1.5rem',
  xxl: '2rem',
  xxxl: '2.5rem',
  fiveXl: '5rem'
};

interface SeparatorProps extends HTMLAttributes<HTMLDivElement> {
  size: keyof typeof sizeEnum;
  width?: string;
  // TODO: color type should be customColors + MUI colors + colors from tenantVisuals
  // Define color type somewhere not in this file and use it whenever we need
  color?: string;
}

export const Separator = styled(
  ({ size: _size, width: _width, color: _color, ...props }: SeparatorProps) => <div {...props} />
)`
  height: ${({ size }) => sizeEnum[size] || sizeEnum.default};
  width: ${({ width }) => (width ? width : '100%')};
  background-color: ${({ theme, color = 'paleGrey' }) =>
    get(theme.palette, color, theme.palette.paleGrey)};
`;

interface SpacerProps extends HTMLAttributes<HTMLDivElement> {
  size?: keyof typeof spacerEnum;
}

export const Spacer = styled(({ size: _, ...props }: SpacerProps) => <div {...props} />)`
  height: ${({ size = 'default' }) => spacerEnum[size] ?? spacerEnum.default};
`;

export const Divider = styled(Text).attrs({ variant: 'h5' })`
  position: relative;
  text-align: center;
  margin: 5px;
  padding: 0 40px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    background-color: ${({ theme }) => theme.palette.greyishBrown}};
  }

  div {
    background-color: ${({ theme }) => theme.palette.common.white};
    position: relative;
    z-index: 2;
    padding: 0 10px;
    display: inline-block;
    span {
      font-weight: ${({ theme }) => theme.typography.fontWeights.normal};
    }
  }
`;
