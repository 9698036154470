import parsePhoneNumber from 'libphonenumber-js';
import get from 'lodash/get';
import { createSelector, Selector } from '@reduxjs/toolkit';

import { Types } from 'actions/account';
import { getCountry } from 'components/Address/countryUtils';
import { tenantSettingsResponseData } from 'selectors/tenantSettingsSelector';
import { RootState } from 'store/store';
import { Account, BusinessAccount } from 'models/Account';

const getIsProfileUpdated: Selector<RootState, boolean> = ({ api }) =>
  get(api, [Types.UPDATE_PERSONAL_DETAILS, 'isRequestCompleted'], false);

export const personalDetailsResponseData: Selector<RootState, Account | BusinessAccount> = ({
  api
}) => get(api, [Types.GET_PERSONAL_DETAILS, 'data'], {});

export const personalDetailsSelector = createSelector(
  [personalDetailsResponseData, getIsProfileUpdated, tenantSettingsResponseData],
  (personalDetailsData, isProfileUpdated, tenantSettings) => {
    const {
      country: personalDetailsCountry,
      phoneNumber: personalDetailsPhoneNumber,
      firstName,
      lastName,
      accountType
    } = personalDetailsData;
    const country = personalDetailsCountry ?? tenantSettings.operationalCountry;
    const phoneNumber = personalDetailsPhoneNumber ?? '';

    const { countryCallingCode: parsedPhoneNumberCountryCode, nationalNumber = phoneNumber } =
      parsePhoneNumber(phoneNumber) ?? {};

    const countryDialingCode = parsedPhoneNumberCountryCode
      ? `+${parsedPhoneNumberCountryCode}`
      : getCountry(country)?.code ?? '';

    const accountOwner =
      accountType === 'BUSINESS' ? personalDetailsData.companyName : `${firstName} ${lastName}`;

    return {
      personalDetails: {
        ...personalDetailsData,
        country,
        phoneNumber,
        nationalNumber,
        countryDialingCode
      },
      isProfileUpdated,
      accountOwner
    };
  }
);
