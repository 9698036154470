import { Route } from 'routes';

import Loadable from 'utils/Loadable';

const signatureRoutes: Record<string, Route> = {
  signHere: {
    id: 'sign_here',
    title: 'Signature',
    path: '/sign_here',
    isProtected: false,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('./SignatureContainer')
    })
  }
};

export default signatureRoutes;
