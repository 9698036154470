import React from 'react';
import { t } from 'locale';

import { localeKeys } from 'enums/localeKeys';

import { calculateProgress, calculateProgressPercentage } from 'utils/progress';

import { LinearProgress, StatusText } from './ProgressBarStyled';

interface ProgressBarProps {
  length: number;
  amount: number;
  title: string;
  showText?: boolean;
  dataId: string;
}

export const ProgressBar = ({
  length,
  amount,
  title,
  showText = false,
  dataId,
  ...otherProps
}: ProgressBarProps) => (
  <>
    {showText && (
      <StatusText data-id={`${dataId}-status-text`}>
        {calculateProgress(amount, length)} {t(localeKeys.completedText)}
      </StatusText>
    )}
    <LinearProgress
      title={title}
      aria-label={title}
      variant="determinate"
      value={calculateProgressPercentage(amount, length)}
      data-id={`${dataId}-progress-bar`}
      {...otherProps}
    />
  </>
);
