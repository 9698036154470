import { connect } from 'react-redux';
import { getStorageItem } from 'locale';

import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';
import { RootState } from 'store/store';
import { routeSelector } from 'selectors/routeSelector';
import { appSelector } from 'selectors/appSelectors';
import { personalDetailsSelector } from 'selectors/personalDetailsSelector';

import { storageKeys } from 'enums/storage';

import { onboardingStepsCalculator } from 'utils/onboardingStepsCalculator';
import { checkIsMagicLinkRequiredUserDataIncomplete } from 'utils/magicLink';

import { OnboardingProgressBar } from './OnboardingProgressBar';

const mapStateToProps = (state: RootState) => {
  const { tenantSettings } = tenantSettingsSelector(state);
  const { route } = routeSelector(state);
  const { isRegisteredFromLink } = appSelector(state);
  const { personalDetails } = personalDetailsSelector(state);

  const { magicLinkSettings } = tenantSettings;
  const hasIbanScreen = getStorageItem(storageKeys.HAS_IBAN_SCREEN);
  const { path } = route;

  const isIncompleteMagicLinkRequiredUserData =
    checkIsMagicLinkRequiredUserDataIncomplete(personalDetails);

  const { isVisible, totalSteps, currentStep } = onboardingStepsCalculator({
    magicLinkSettings,
    path,
    hasIbanScreen: Boolean(hasIbanScreen),
    isRegisteredFromLink,
    isIncompleteMagicLinkRequiredUserData
  });

  return { isVisible, totalSteps, currentStep };
};

const OnboardingProgressBarContainer = connect(mapStateToProps, null)(OnboardingProgressBar);

export default OnboardingProgressBarContainer;
