import { createActions } from 'utils/redux';

export const { Types, Creators } = createActions({
  apiRequest: ['actionType', 'payload'],
  apiResponse: ['actionType', 'payload'],
  apiError: ['actionType', 'error', 'errorType'],
  apiErrorReset: [],
  apiReset: ['except'],
  updateActionResponse: ['actionType', 'payload'],
  apiResetByKeys: ['keysToReset']
});
