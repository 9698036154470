import { createContext, useState } from 'react';

export const SnackbarContext = createContext({});

export const SnackbarContextProvider = ({ children }) => {
  const [snacks, setSnacks] = useState([]);

  const dequeueSnack = () => {
    if (snacks.length > 0) {
      setSnacks(previousSnacks => {
        const [, ...nextSnacks] = previousSnacks;
        return nextSnacks;
      });
    }
  };

  const enqueueSnack = (message, type) => {
    setSnacks(previousSnacks => {
      const nextSnacks = [...previousSnacks];
      nextSnacks.push({
        message,
        type
      });
      return nextSnacks;
    });
  };

  const snackbarContextValue = {
    snacks,
    enqueueSnack,
    dequeueSnack
  };

  return (
    <SnackbarContext.Provider value={snackbarContextValue}>{children}</SnackbarContext.Provider>
  );
};
