import { AccountType } from 'models/Account';
import { BankLoginFormData } from 'actions/account';

import { createActions } from 'utils/redux';

export type GetBankSelectionsActionCreator = (query: string) => void;

export interface Bank {
  id: string;
  shortName: string;
  bic: string;
  sortCode: string;
}

export type StartManualAccountSwitchSignupActionCreator = (
  payload: {
    acceptTerms: boolean;
  },
  params: BankLoginFormData,
  accountType: AccountType
) => void;

export const { Types, Creators } = createActions({
  getBankSelections: ['query'],
  startManualAccountSwitchSignup: ['payload', 'params', 'accountType']
});
