import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';

export const LinearProgressStyled = styled(LinearProgress).attrs({
  color: 'secondary'
})`
  && {
    position: fixed;
    width: 100%;
    z-index: 10000;
    height: 3px;
    top: 0;
    left: 0;
  }
`;
