import { t } from 'locale';

import { countries } from 'components/Address/countries';

import { normalizeString } from 'utils/normalizeString';

export const getCountry = (code: string) =>
  countries.find(country => country.value === code || country.code === code);

export const getCountryFromLabel = (label: string) =>
  countries.find(country => country.label.toLowerCase() === label.toLowerCase());

export const getSortedCountries = (countriesToSort = countries) =>
  countriesToSort.sort((a, b) => {
    const aLowerCased = normalizeString(t(a.label));
    const bLowerCased = normalizeString(t(b.label));

    if (aLowerCased < bLowerCased) {
      return -1;
    }

    if (aLowerCased > bLowerCased) {
      return 1;
    }

    return 0;
  });
