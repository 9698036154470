import { Route as RouteConfig } from 'slices/routes';

import accountAnalysisRoutes from 'pages/AccountAnalysis/accountAnalysisRoutes';
import autoLoginRoutes from 'pages/AutoLogin/autoLoginRoutes';
import closeRoutes from 'pages/CloseAccount/closeAccountRoutes';
import directDebitRoutes from 'pages/DirectDebits/directDebitRoutes';
import incomingPaymentsRoutes from 'pages/IncomingPayments/incomingPaymentsRoutes';
import loginRoutes from 'pages/Login/loginRoutes';
import manualMandateRoutes from 'pages/ManualMandate/manualMandateRoutes';
import overviewRoutes from 'pages/Overview/overviewRoutes';
import {
  personalDetailsRoutes,
  signUpPersonalDetailsRoutes
} from 'pages/PersonalDetails/personalDetailsRoutes';
import resetPasswordRoutes from 'pages/ResetPassword/resetPasswordRoutes';
import signatureRoutes from 'pages/Signature/signatureRoutes';
import signUpIbanRoutes from 'pages/SignUpIban/signUpIbanRoutes';
import signUpLoginDetailsRoutes from 'pages/SignUpLoginDetails/signUpLoginDetailsRoutes';
import signUpOldBankRoutes from 'pages/SignUpOldBank/signUpOldBankRoutes';
import standingOrdersRoutes from 'pages/StandingOrders/standingOrdersRoutes';
import transferBalanceRoutes from 'pages/TransferBalance/transferBalanceRoutes';
import updatePasswordRoutes from 'pages/UpdateEmailPassword/updatePasswordRoutes';

export interface Route extends RouteConfig {
  redirectOnUnauthorized: boolean;
  component: () => React.ReactElement;
  basePath?: string;
}

export const defaultRoutes: Record<string, Route> = {
  ...signUpOldBankRoutes,
  ...signUpIbanRoutes,
  ...signUpPersonalDetailsRoutes,
  ...signUpLoginDetailsRoutes,
  ...accountAnalysisRoutes,
  ...signatureRoutes
};

export const protectedRoutes: Record<string, Route> = {
  ...closeRoutes,
  ...directDebitRoutes,
  ...updatePasswordRoutes,
  ...overviewRoutes,
  ...personalDetailsRoutes,
  ...incomingPaymentsRoutes,
  ...standingOrdersRoutes,
  ...transferBalanceRoutes,
  ...manualMandateRoutes,
  ...defaultRoutes
};

export const publicRoutes: Record<string, Route> = {
  ...autoLoginRoutes,
  ...resetPasswordRoutes,
  ...loginRoutes,
  ...defaultRoutes
};

export const routes: Record<string, Route> = {
  ...publicRoutes,
  ...protectedRoutes
};
