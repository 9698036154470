import { AppBar as MuiAppBar } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiIconButton from '@mui/material/IconButton';
import MuiToolbar from '@mui/material/Toolbar';
import { withStyles } from '@mui/styles';
import styled from 'styled-components';

import { Button } from 'components/Buttons/ButtonsStyled';
import { StyledImage } from 'components/Icons/ImageLoader';
import { ProgressBarContainer } from 'components/OnboardingProgressBar/OnboardingProgressBarStyled';

import { config } from 'config';

import { themeBreakPoints } from 'enums/keys';

export const AppBarStyled = styled(MuiAppBar)`
  && {
    box-shadow: 0 1px 5px -5px rgba(0, 0, 0, 0.12);
    background-color: ${({ theme: { header } }) => header.main};
    svg {
      color: ${({ theme: { header } }) => header.contrastText};
    }
    .MuiIconButton-root {
      filter: ${({ theme: { header } }) => header.filter};
    }
    .MuiToolbar-root {
      max-width: ${config.defaultLayoutWidth};
      margin: 0 auto;
      width: 100%;
    }

    .app-bar,
    .app-bar__content {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
    }
  }
  ${ProgressBarContainer} {
    max-width: 100%;
    @media (min-width: ${({ theme: { breakpoints } }) =>
        `${breakpoints.values[themeBreakPoints.sm]}px`}) {
      display: none;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  ${StyledImage} {
    width: auto;
    max-width: 200px;
    height: 30px;
    object-fit: contain;
    cursor: pointer;
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;

  [data-id^='language-menu-item-text-wrapper'] {
    display: flex;
    flex-direction: column;
  }

  svg {
    color: ${({ theme: { palette } }) => palette.darkGrey};
  }
`;

export const MenuItemButton = styled(Button)`
  && {
    width: 100%;
    display: block;
    padding: 0;
    text-align: left;
    min-width: 100px;
    &:hover {
      background-color: transparent;
    }
  }
`;

export const IconButtonText = styled(MuiIconButton)`
  && {
    font-size: ${({ theme }) => theme.typography.fontSizes.S};
    text-transform: uppercase;
    line-height: 21px;
  }
`;

const styles = {
  MuiDrawer: {
    paper: {
      width: config.defaultDrawerWidth,
      left: 'auto',
      top: 'auto',
      height: 'auto',
      marginTop: '15px'
    }
  },
  MuiDrawerMobile: {
    paper: {
      left: 'auto',
      width: config.defaultDrawerWidth
    }
  }
};

export const Drawer = withStyles(styles.MuiDrawer)(MuiDrawer);
export const DrawerOverlay = withStyles(styles.MuiDrawerMobile)(MuiDrawer);

export const Toolbar = styled(MuiToolbar)`
  && {
    flex-direction: row-reverse;
    padding-right: 10px;
    box-shadow: 0 1px 4px -2px rgba(0, 0, 0, 0.12);
    background-color: ${({ theme: { header } }) => header.main};
    svg,
    .MuiIconButton-root {
      filter: ${({ theme: { header } }) => header.filter};
    }
  }
`;

export const ToolbarBottom = styled(MuiToolbar)`
  && {
    padding: 0;
    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    background-color: ${({ theme: { palette } }) => palette.common.white};
    box-shadow: ${({ theme: { customShadows } }) => customShadows.boxShadow};
  }
`;

export const CountryFlagWrapper = styled.div`
  margin-right: 10px;
`;
