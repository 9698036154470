import pull from 'lodash/pull';

import { MagicLinkSettings } from 'actions/app';

import { routes } from 'routes/config';

export const onboardingPages = [
  routes.signupIban.path,
  routes.signUpPersonalDetails.path,
  routes.signupLoginDetails.path
];

interface Params {
  path: string;
  hasIbanScreen: boolean;
  isRegisteredFromLink: boolean;
  isIncompleteMagicLinkRequiredUserData: boolean;
  magicLinkSettings?: MagicLinkSettings;
}

export const onboardingStepsCalculator = ({
  magicLinkSettings,
  path,
  hasIbanScreen,
  isRegisteredFromLink,
  isIncompleteMagicLinkRequiredUserData
}: Params) => {
  const pages = [...onboardingPages];

  if (isRegisteredFromLink && magicLinkSettings) {
    const { allowConventionalLogin, allowEditingPersonalData } = magicLinkSettings;
    if (!allowEditingPersonalData && !isIncompleteMagicLinkRequiredUserData) {
      pull(pages, routes.signUpPersonalDetails.path);
    }
    if (!allowConventionalLogin) {
      pull(pages, routes.signupLoginDetails.path);
    }
    if (!hasIbanScreen) {
      pull(pages, routes.signupIban.path);
    }
  }

  const totalSteps = pages.length;
  const currentStep = pages.indexOf(path) + 1;
  const isVisible = pages.includes(path);

  return { isVisible, totalSteps, currentStep };
};
