import { routes } from 'routes';

const includesBasePath = (currentPath: string, basePath = '') => {
  return currentPath.includes(basePath);
};

export const getIsMenuActive = (currentPath: string, menuLink: string) => {
  if (currentPath === menuLink) {
    return true;
  }

  if (menuLink === routes.directDebits.path) {
    return (
      currentPath === routes.manualDirectDebits.path ||
      includesBasePath(currentPath, routes.directDebitItem.basePath)
    );
  }

  if (menuLink === routes.incomingPayments.path) {
    return (
      currentPath === routes.manualIncomingPayments.path ||
      includesBasePath(currentPath, routes.incomingPaymentItem.basePath)
    );
  }

  if (menuLink === routes.closeAccount.path) {
    return currentPath.includes(routes.closeAccountForm.path);
  }

  return false;
};
