import { LinearProgress as MuiLinearProgress } from '@mui/material';
import styled from 'styled-components';

export const LinearProgress = styled(MuiLinearProgress)`
  && {
    border-radius: 5em;
    width: 75%;
    margin: 0 auto;
  }
`;

export const StatusText = styled.span`
  color: ${({ theme: { progressbar } }) => progressbar.contrastText};
  text-transform: ${({ theme: { progressbar } }) => progressbar.textTransform};
  font-size: ${({ theme: { progressbar } }) => progressbar.fontSize};
  line-height: 18px;
  font-weight: normal;
  span {
    margin-left: 5px;
  }
`;
