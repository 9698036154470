import { tenantIcons } from 'enums/tenantIcons';

import transferBalance from './default/Dark/transfer_balance.svg';
import IconLoader from './IconLoader';

export const Transfer = ({
  style = {
    width: '32px'
  }
}) => (
  <IconLoader
    tenantIconName={tenantIcons.transferBalance}
    src={transferBalance}
    imageStyle={style}
  />
);
