import { Route } from 'routes';

import Loadable from 'utils/Loadable';

const loginRoutes: Record<string, Route> = {
  login: {
    id: 'login',
    title: 'navigationAccountSwitchText',
    path: '/login',
    isProtected: false,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('./LoginContainer')
    })
  },
  existingUser: {
    id: 'existing_user',
    title: 'navigationAccountSwitchText',
    path: '/existing_user',
    isProtected: false,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('./LoginContainer')
    })
  }
};

export default loginRoutes;
