export const tenantIcons = {
  accountAnalysis: 'accountAnalysis',
  accountAnalysisHome: 'accountAnalysisHome',
  accountRegistration: 'accountRegistration',
  accountSelection: 'accountSelection',
  accountSwitch: 'accountSwitch',
  alertCircle: 'alertCircle',
  bank: 'bank',
  camera: 'camera',
  card: 'card',
  cards: 'cards',
  caretDown: 'caretDown',
  caretUp: 'caretUp',
  circleChecked: 'circleChecked',
  close: 'close',
  closeAccount: 'closeAccount',
  collapseMenuArrowDown: 'collapseMenuArrowDown',
  collapseMenuArrowRight: 'collapseMenuArrowRight',
  defaultMandate: 'defaultMandate',
  directDebits: 'directDebits',
  accountDirectDebitSuccess: 'accountDirectDebitSuccess',
  direction: 'direction',
  done: 'done',
  edit: 'edit',
  faq: 'faq',
  menu: 'menu',
  imprint: 'imprint',
  incomingPayments: 'incomingPayments',
  language: 'language',
  ordersLoading: 'ordersLoading',
  savings: 'savings',
  search: 'search',
  standingOrders: 'standingOrders',
  transferBalance: 'transferBalance',
  trash: 'trash',
  updateSuccess: 'updateSuccess',
  updateEmailSuccess: 'updateEmailSuccess',
  updatePasswordSuccess: 'updatePasswordSuccess',
  privacy: 'privacy',
  user: 'user',
  overview: 'overview',
  lock: 'lock'
} as const;

export const tenantsWithCustomIcons = {
  n26: {
    [tenantIcons.directDebits]: 'direct_debits.svg',
    [tenantIcons.standingOrders]: 'standing_orders.svg',
    [tenantIcons.incomingPayments]: 'incoming_payments.svg',
    [tenantIcons.closeAccount]: 'close_account.svg',
    [tenantIcons.faq]: 'faq.svg',
    [tenantIcons.imprint]: 'imprint.svg',
    [tenantIcons.menu]: 'menu.svg',
    [tenantIcons.overview]: 'overview.svg',
    [tenantIcons.privacy]: 'privacy.svg',
    [tenantIcons.user]: 'user.svg'
  },
  default: {
    [tenantIcons.directDebits]: 'direct_debits.svg',
    [tenantIcons.standingOrders]: 'standing_orders.svg',
    [tenantIcons.incomingPayments]: 'incoming_payments.svg',
    [tenantIcons.transferBalance]: 'transfer_balance.svg',
    [tenantIcons.closeAccount]: 'close_account.svg',
    [tenantIcons.faq]: 'faq.svg',
    [tenantIcons.imprint]: 'imprint.svg',
    [tenantIcons.overview]: 'overview.svg',
    [tenantIcons.privacy]: 'privacy.svg',
    [tenantIcons.menu]: 'menu.svg',
    [tenantIcons.user]: 'user.svg',
    [tenantIcons.edit]: 'edit.svg'
  }
} as const;
