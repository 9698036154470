import { takeEvery } from 'redux-saga/effects';

import { Types, getAccountStatus } from 'actions/account';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

const { apis } = config;
const { ACCOUNT_STATUS, SELECT_ACCOUNT } = Types;

export function* accountStatus() {
  const params = {
    path: apis.accountStatus
  };

  const status = yield fetchSaga({
    params,
    actionType: ACCOUNT_STATUS,
    fields: 'accountStatus'
  });

  return getAccountStatus(status);
}

export function* selectAccount({ payload, action }) {
  const params = {
    path: apis.accountSelect,
    method: httpMethods.POST,
    data: {
      account: payload
    }
  };

  action.setSubmitting(true);

  yield fetchSaga({
    params,
    actionType: SELECT_ACCOUNT
  });

  action.setSubmitting(false);
}

export function* accountAnalysisSagas() {
  yield takeEvery(ACCOUNT_STATUS, accountStatus);
  yield takeEvery(SELECT_ACCOUNT, selectAccount);
}
