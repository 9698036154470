import { isEmpty } from 'lodash';
import { delay, put, select, take, takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';
import { t } from 'locale';
import { clearBankSelection } from 'slices/banks';
import { triggerError } from 'slices/errors';
import { registerRoute } from 'slices/routes';

import { Types } from 'actions/account';
import { Creators as AppCreators } from 'actions/app';
import { fetchSaga } from 'api/fetchSaga';
import { accountStatus } from 'sagas/accountAnalysis';
import { clearKeysFromStore } from 'sagas/app';
import { getPersonalDetails } from 'sagas/personalDetails';
import { appSelector } from 'selectors/appSelectors';
import { registerIbanResponseData } from 'selectors/ibanSelector';
import { personalDetailsResponseData } from 'selectors/personalDetailsSelector';
import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';
import { mfaTypes, registrationStatus, userInitialState } from 'enums/signup';
import { localeKeys } from 'enums/localeKeys';

import { checkIsMagicLinkRequiredUserDataIncomplete, getRouteForMagicLink } from 'utils/magicLink';

const { apis } = config;
const {
  OLD_BANK_LOGIN_REQUEST,
  OLD_BANK_LOGIN_MFA_STATUS,
  LOGIN_TO_OLD_BANK,
  START_REG_SHIELD_REGISTRATION,
  COMPLETE_REG_SHIELD_REGISTRATION,
  START_REDIRECT_CHALLENGE
} = Types;

const isRegistrationCompleted = (data, response) =>
  data.isRegistrationComplete ||
  response === userInitialState.registered ||
  response === userInitialState.registeredFromLink;

function* showErrorPopup(errorMessageLocale) {
  yield put(triggerError({ message: t(errorMessageLocale), options: { redirected: true } }));
}

function* getRegistrationsStatus() {
  const params = {
    path: apis.registrationsStatus,
    method: httpMethods.GET
  };

  return yield fetchSaga({
    actionType: OLD_BANK_LOGIN_MFA_STATUS,
    fields: '',
    params
  });
}

function* checkRegistrationsStatus() {
  const response = yield getRegistrationsStatus();

  if (!response) return false;

  switch (response.status) {
    case registrationStatus.pending: {
      yield delay(config.registrationStatusPollingDelay);
      return yield checkRegistrationsStatus(); // keep checking until we get the updated status
    }
    case registrationStatus.failure:
      return yield showErrorPopup(localeKeys.registrationStatusFailedText);
    case registrationStatus.invalid:
      return yield showErrorPopup(localeKeys.registrationStatusInvalidText);
    case registrationStatus.mfa:
      yield put(AppCreators.showLoader(false));
      if (response.mfa.type === mfaTypes.decoupled) {
        yield delay(config.registrationStatusPollingDelay);
        return yield checkRegistrationsStatus();
      }
      return response.status;
    case registrationStatus.ok:
    default:
      return response.status;
  }
}

function* getRouteFromSignup() {
  const { iban } = yield select(registerIbanResponseData);
  const { isRegisteredFromLink } = yield select(appSelector);
  const { tenantSettings } = yield select(tenantSettingsSelector);
  const userData = yield select(personalDetailsResponseData);

  // Iban can be empty in the normal user flow and also if magic link doesn't contain IBAN
  // isRegistrationComplete logic resides in the ASK CORE API, and it has several conditions

  // Base condition is (without magic link) that "isRegistrationComplete" === TRUE if
  // user have iban, email, password and personal details

  // In case of magic link, if "allowConventionalLogin" flag in tenant magic link settings is FALSE
  // then "isRegistrationComplete" === TRUE if user have iban and personal details only
  const isEmptyIbanOrNewUser =
    isEmpty(userData) || isEmpty(iban) || !userData.isRegistrationComplete;

  const isIncompleteMagicLinkRequiredUserData =
    checkIsMagicLinkRequiredUserDataIncomplete(userData);

  return getRouteForMagicLink({
    tenantSettings,
    isEmptyIbanOrNewUser,
    isIncompleteMagicLinkRequiredUserData,
    isRegisteredFromLink
  });
}

export function* loginToOldBank({ payload: formValues, params, accountType }) {
  yield put(AppCreators.showLoader(true));

  const credentials = params.credentials.reduce((acc, item) => {
    acc[item.name] = formValues[item.name];
    return acc;
  }, {});

  const isClassicSwitch = Object.keys(credentials).length > 0;

  const requestParams = {
    path: apis.registrations,
    method: httpMethods.POST,
    keyToSuppressTransformation: 'registration.credentials',
    data: {
      registration: {
        ...(isClassicSwitch && { credentials: credentials }),
        gateway: params.gateway,
        bankId: params.id,
        dataAnalyticsTerms: formValues.dataAnalyticsOptIn,
        acceptTerms: formValues.acceptTerms,
        accountType
      }
    }
  };

  const response = yield fetchSaga({
    params: requestParams,
    payload: { credentials, params },
    actionType: OLD_BANK_LOGIN_REQUEST,
    fields: 'user.state'
  });

  yield getPersonalDetails();

  const data = yield select(personalDetailsResponseData);
  const registrationCompleted = isRegistrationCompleted(data, response);

  if (registrationCompleted) {
    yield accountStatus(); //if a user comes using MAGIC LINK
  }

  let regStatus;

  if (isClassicSwitch) {
    regStatus = yield checkRegistrationsStatus();
  } else {
    regStatus = registrationStatus.ok;
  }

  yield put(AppCreators.showLoader(false));

  if (regStatus !== registrationStatus.ok) {
    // If MFA is required OR MFA is invalid
    history.push(routes.oldBankSelection.path);
  } else {
    const nextRoute = yield getRouteFromSignup();
    history.push(nextRoute);
  }
}

export function* startRegShieldRegistration({ bankCode, bankId }) {
  const params = {
    path: apis.registrationsRegShieldStart,
    method: httpMethods.POST,
    data: {
      bankCode,
      bankId,
      redirectUri: window.location.href
    }
  };

  yield fetchSaga({
    params,
    actionType: START_REG_SHIELD_REGISTRATION
  });
}

export function* completeRegShieldRegistration({ payload }) {
  const { state, code, regShieldUserId, dataAnalyticsTerms, accountType } = payload;

  const params = {
    path: apis.registrationsRegShieldComplete,
    method: httpMethods.POST,
    data: {
      state,
      code,
      dataAnalyticsTerms,
      regshield_user_id: regShieldUserId,
      accountType
    }
  };

  const response = yield fetchSaga({
    params,
    actionType: COMPLETE_REG_SHIELD_REGISTRATION
  });

  if (response) {
    yield getPersonalDetails();
    window.serverEnvConfig.redirectChallengeData = null;

    const nextRoute = yield getRouteFromSignup();
    history.push(nextRoute);

    yield take(registerRoute.type);
  }

  // For RegShield, `CircularProgress` is showing if `COMPLETE_REG_SHIELD_REGISTRATION` is in the state.
  const keysToClear = [START_REG_SHIELD_REGISTRATION, COMPLETE_REG_SHIELD_REGISTRATION];
  yield clearKeysFromStore(keysToClear);
  yield put(clearBankSelection());
}

function* startRedirectChallenge({ payload }) {
  const { redirectUrl, ...data } = payload;

  const response = yield fetchSaga({
    params: {
      path: apis.redirectChallenge,
      method: httpMethods.POST,
      data,
      transformData: false
    },
    actionType: START_REDIRECT_CHALLENGE,
    isExactApiPath: true
  });

  if (response) {
    window.location.assign(redirectUrl);
  }
}

export function* oldBankSagas() {
  yield takeEvery(LOGIN_TO_OLD_BANK, loginToOldBank);
  yield takeEvery(START_REG_SHIELD_REGISTRATION, startRegShieldRegistration);
  yield takeEvery(COMPLETE_REG_SHIELD_REGISTRATION, completeRegShieldRegistration);
  yield takeEvery(START_REDIRECT_CHALLENGE, startRedirectChallenge);
}
