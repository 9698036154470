import Skeleton from '@mui/material/Skeleton';
import React from 'react';

import { Container } from 'components/Container/ContainerStyled';
import { Spacer } from 'components/Separator/Separator';
import { SkeletonCard } from 'components/Skeleton/SkeletonCard';

import { animation } from 'enums/variants';

interface SkeletonLayoutProps {
  showHeader: boolean;
}

export const SkeletonLayout = ({ showHeader }: SkeletonLayoutProps) => (
  <Container padded={showHeader}>
    {showHeader && <Skeleton animation={animation.wave} height={50} variant="rectangular" />}
    <Spacer />
    <SkeletonCard />
    <Spacer size="lg" />
    <SkeletonCard />
  </Container>
);
