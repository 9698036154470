import { tenantIcons } from 'enums/tenantIcons';

import closeAccount from './default/Dark/close_account.svg';
import IconLoader from './IconLoader';

export const CloseAccount = ({
  style = {
    width: '32px'
  }
}) => (
  <IconLoader tenantIconName={tenantIcons.closeAccount} src={closeAccount} imageStyle={style} />
);
