import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import MuiInputBase from '@mui/material/InputBase';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import styled from 'styled-components';
import { theme } from 'theme';

export const DialogButton = styled(IconButton)`
  && {
    position: absolute;
    top: 3px;
    right: 8px;
  }
`;

export const DialogTitle = styled(MuiDialogTitle)`
  && {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }
`;

export const DialogContent = styled(MuiDialogContent)`
  && {
    padding: 10px 15px;
    .highlight {
      background-color: yellow;
    }
  }
`;

export const EmptySpace = styled.span`
  display: inline-block;
  height: 1.5rem;
  width: 1px;
`;

export const SearchInput = styled.div`
  position: fixed;
  background-color: #fff;
  width: inherit;
  input {
    padding: 6px;
  }

  svg {
    color: ${({ theme: { palette } }) => palette.greyishBrown};
  }
`;

export const SearchControls = styled.div`
  background-color: ${({ theme: { palette } }) => palette.offWhite};
  font-size: ${({ theme: { typography } }) => typography.fontSizes.XS};
  font-weight: ${({ theme: { typography } }) => typography.fontWeights.semiBold};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  padding: 5px 10px;
  box-shadow: ${({ theme: { customShadows } }) => customShadows.tinyBoxShadow};
  position: absolute;
  width: 150px;
  z-index: 1;
  left: 90px;
  right: 0;
`;

export const InputBase = withStyles({
  root: {
    borderRadius: 10,
    backgroundColor: '#eee',
    padding: '2px 4px'
  },
  inputTypeSearch: {
    width: 200,
    fontSize: theme.typography.fontSizes.XS,
    transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  }
})(MuiInputBase);

export const ModalStyles = makeStyles(theme => ({
  inputInput: {
    [theme.breakpoints.up('sm')]: {
      '&:focus': {
        width: 150
      }
    }
  }
}));
