import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from '@reduxjs/toolkit';
import { routes, publicRoutes } from 'routes';
import { t } from 'locale';

import { Creators as BankCreators } from 'actions/banks';
import { AppBar } from 'components/AppBar/AppBar';
import { appMenuSelector, appSelector } from 'selectors/appSelectors';
import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';

import { config } from 'config';

import { localeKeys } from 'enums/localeKeys';

import { goBackToPreviousPage } from 'utils/history';
import { isPublicRoute } from 'utils/routes';

class NavigationContainer extends Component {
  navigateToPreviousPage = () => {
    const { id: routeId, clearBankSelection } = this.props;
    if (routeId === routes.signupIban.id) {
      clearBankSelection();
    }
    goBackToPreviousPage();
  };

  componentDidUpdate(prevProps) {
    const { location: nextLocation } = this.props;
    const { location } = prevProps;
    if (nextLocation.pathname !== location.pathname) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    requestAnimationFrame(() => {
      window.scroll(0, 0);
    });
  }

  shouldShowBackButton() {
    const {
      location: { pathname },
      id
    } = this.props;

    const publicRoutesIDs = Object.keys(publicRoutes);

    return pathname !== '/' && !publicRoutesIDs.includes(id);
  }

  render() {
    const {
      title,
      isAuthenticated,
      isRegisteredFromLink,
      tenantSettings,
      appMenuHidden,
      isMenuOpen,
      toggleDrawer,
      fullName,
      hasBankData,
      isManualSwitch
    } = this.props;

    const {
      faviconUrl,
      mobileAsk = {
        tenantVisuals: {
          font: {}
        }
      }
    } = tenantSettings;

    const {
      tenantVisuals: { font = {} },
      headTitle: tenantHeadTitle,
      customCss: tenantCustomCss
    } = mobileAsk;

    const appBarProps = {
      isMenuOpen,
      isAuthenticated,
      isRegisteredFromLink,
      toggleDrawer,
      tenantSettings,
      fullName,
      hasBankData,
      appMenuHidden,
      isManualSwitch,
      isAuthorized: isAuthenticated && !isPublicRoute(),
      clearBankAndGoToPreviousPage: this.navigateToPreviousPage,
      showBackButton: this.shouldShowBackButton()
    };

    return (
      Boolean(title) && (
        <>
          <AppBar title={t(title)} appBarProps={appBarProps} />
          <Helmet>
            {!tenantHeadTitle && <title>{`${t(localeKeys.accountSwitchKit)} - ${t(title)}`}</title>}
            <link
              rel="icon"
              type="image/x-icon"
              href={faviconUrl ? faviconUrl : config.defaultFaviconPath}
            />
            <link rel="stylesheet" href={config.defaultFontUrl} />
            {font.url && <link rel="stylesheet" type="text/css" href={font.url} />}
            {tenantCustomCss ? (
              <style data-meta="tenant-custom-css">{tenantCustomCss}</style>
            ) : null}
          </Helmet>
        </>
      )
    );
  }
}

const mapStateToProps = state => {
  const { locale, route } = state;
  const { isManualSwitch } = appSelector(state);

  return {
    locale,
    isManualSwitch,
    ...route,
    ...tenantSettingsSelector(state),
    ...appMenuSelector(state)
  };
};

export const Navigation = compose(
  connect(mapStateToProps, {
    clearBankSelection: BankCreators.clearBankSelection
  }),
  withRouter
)(NavigationContainer);
