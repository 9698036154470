import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from '@reduxjs/toolkit';
import { clearBankSelection } from 'slices/banks';

import { AppBarContext } from 'components/AppBar/AppBarContext';
import { getAppBarMobileClasses } from 'components/AppBar/appBarMobileUtils';
import { AppBarMenu } from 'components/AppBar/SideMenu/AppBarMenu';
import { appMenuSelector, appSelector } from 'selectors/appSelectors';
import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';

import { config } from 'config';

import { isPublicRoute } from 'utils/routes';

const NavigationContainer = ({
  isAuthenticated,
  isRegisteredFromLink,
  tenantSettings,
  appMenuHidden,
  isMenuOpen,
  toggleDrawer,
  fullName,
  hasBankData,
  isManualSwitch
}) => {
  const classes = getAppBarMobileClasses();
  const appBarProps = {
    isMenuOpen,
    isAuthenticated,
    isRegisteredFromLink,
    toggleDrawer,
    tenantSettings,
    fullName,
    hasBankData,
    appMenuHidden,
    isManualSwitch,
    isAuthorized: isAuthenticated && !isPublicRoute()
  };
  const { tenantId } = tenantSettings;
  const showMenuAtBottom = config.tenantsWithSlideMenu.includes(tenantId);

  return (
    <AppBarContext.Provider value={appBarProps}>
      <AppBarMenu classes={classes} showMenuAtBottom={showMenuAtBottom} />
    </AppBarContext.Provider>
  );
};

const mapStateToProps = state => {
  const { locale, route } = state;
  const { isManualSwitch } = appSelector(state);

  return {
    locale,
    isManualSwitch,
    ...route,
    ...tenantSettingsSelector(state),
    ...appMenuSelector(state)
  };
};

export const NavigationLeft = compose(
  connect(mapStateToProps, {
    clearBankSelection
  }),
  withRouter
)(NavigationContainer);
