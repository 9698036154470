import { Reducer } from '@reduxjs/toolkit';

import { Translations } from 'locale/types';

import { createReducer } from 'utils/redux';

import { SetLangAction, SetLocaleAction, Types } from './actions';

export type Locale = 'en-GB' | 'de-DE' | 'de-AT' | 'ca' | 'es-ES';

export type Language = 'en' | 'de' | 'gem' | 'ca' | 'es';

export type LocaleState = {
  locale: Locale;
  translations?: Translations;
  lang: Language;
  informalLang: Language;
  tenantInformal: boolean;
};

const INITIAL_STATE: LocaleState = {
  locale: 'de-DE',
  translations: undefined,
  lang: 'de',
  informalLang: 'gem',
  tenantInformal: false
};

const handlers = {
  [Types.SET_LOCALE]: (state = INITIAL_STATE, action: SetLocaleAction) => {
    const { locale, translations } = action;

    return {
      ...state,
      locale,
      translations
    };
  },
  [Types.SET_LANG]: (state = INITIAL_STATE, action: SetLangAction) => {
    const { lang, informalLang, tenantInformal } = action.payload;

    return {
      ...state,
      lang,
      informalLang,
      tenantInformal
    };
  }
};

export const locale: Reducer<LocaleState> = createReducer(INITIAL_STATE, handlers);
