import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

import { Types as AccountTypes } from 'actions/account';
import { Types } from 'actions/mandates';

import { calculateTotalLengthOfArrays } from 'utils/array';
import { removeAdditionalFields } from 'utils/mandate';

export const getDirectDebits = ({ api }) => {
  const directDebits = get(api, [Types.GET_DIRECT_DEBITS, 'data'], {});

  return removeAdditionalFields(directDebits, ['birth_date']);
};

export const directDebitsMandates = state => {
  const {
    toAddAddress = [],
    toAdvice = [],
    toSign = [],
    transferred = [],
    ignored = []
  } = getDirectDebits(state);

  return {
    active: {
      toAddAddress,
      toAdvice,
      toSign
    },
    done: {
      transferred,
      ignored
    }
  };
};

export const directDebitProgress = state => {
  const {
    toAddAddress = [],
    toAdvice = [],
    toSign = [],
    transferred = [],
    ignored = []
  } = getDirectDebits(state);

  return {
    amount: calculateTotalLengthOfArrays(transferred, ignored),
    length: calculateTotalLengthOfArrays(toAddAddress, toAdvice, toSign, transferred, ignored)
  };
};

export const getTransferredDirectDebits = state => {
  const { transferred = [] } = getDirectDebits(state);

  return transferred;
};

const getAnalyzedDaysCount = ({ api }) => {
  const { daysAnalyzedCount } = get(api, [AccountTypes.GET_PERSONAL_DETAILS, 'data'], {});

  return {
    daysAnalyzedCount: daysAnalyzedCount || 0
  };
};

export const directDebitsSelector = createSelector(
  [directDebitsMandates, getAnalyzedDaysCount],
  (mandates, analyzedDaysCount) => {
    return {
      mandates,
      analyzedDaysCount
    };
  }
);

export const getNotifiedSingleDirectDebit = ({ api }) =>
  get(api, [Types.NOTIFY_DIRECT_DEBIT, 'data'], {});
