import { Reducer } from '@reduxjs/toolkit';

import { Types } from 'actions/mandates';
import { TransferAmountAction } from 'actions/transferAmount';

import { createReducer } from 'utils/redux';

export interface TransferAmountState {
  amount: number | null;
}

const INITIAL_STATE = {
  amount: null
};

const handlers = {
  [Types.SET_TRANSFER_AMOUNT]: (state = INITIAL_STATE, action: TransferAmountAction) => ({
    ...state,
    amount: action.amount
  })
};

export const transferAmount: Reducer<TransferAmountState, TransferAmountAction> = createReducer(
  INITIAL_STATE,
  handlers
);
