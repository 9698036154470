import { Router as BrowserRouter } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';

import { AppLayout } from 'components/Base/AppLayout';
import { LoaderLayout } from 'components/Loaders/LoaderLayout';
import { NetworkLoaderContainer } from 'components/Loaders/NetworkLoader';
import { SnackbarContextProvider } from 'components/Snackbar/SnackbarContext';
import { LocaleProvider } from 'locale/LocaleProvider';

import { withPrepareApp } from 'routes/withPrepareApp';

import { history } from './history';

const RouterComponent = ({ isAuthenticated, isFetching, isRegisteredFromLink, route }) =>
  isFetching ? (
    <LoaderLayout showHeader />
  ) : (
    <LocaleProvider>
      <BrowserRouter history={history}>
        <ScrollMemory />
        <NetworkLoaderContainer />
        <SnackbarContextProvider>
          <AppLayout
            isAuthenticated={isAuthenticated}
            isRegisteredFromLink={isRegisteredFromLink}
            route={route}
          />
        </SnackbarContextProvider>
      </BrowserRouter>
    </LocaleProvider>
  );

export const Router = withPrepareApp(RouterComponent);
