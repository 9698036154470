import { LocaleKey } from 'locale/types';

import { localeKeys } from 'enums/localeKeys';

export const tenantsWithCustomWelcomeText: Record<string, LocaleKey> = {
  evobank: localeKeys.overviewEvobankPageTitle
};

export const tenantsWithCustomLandingText: Record<string, LocaleKey> = {
  evobank: localeKeys.welcomeEvobankText
};
