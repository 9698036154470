import { amber, green } from '@mui/material/colors';
import MuiSnackbar from '@mui/material/Snackbar';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

export const snackbarStyles = makeStyles(theme => ({
  content: {
    flexWrap: 'nowrap'
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: theme.typography.fontSizes.L
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const SnackbarContainer = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  right: 0;
  justify-content: space-between;
  z-index: 1101;
`;

export const Snackbar = styled(MuiSnackbar)`
  && {
    position: relative;
    margin-right: 14px;
  }
`;
