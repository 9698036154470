import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { history } from 'routes';

import { getAppBarMobileClasses } from 'components/AppBar/appBarMobileUtils';
import OnboardingProgressBar from 'components/OnboardingProgressBar/OnboardingProgressBarContainer';

import { onboardingPages } from 'utils/onboardingStepsCalculator';

import { ProtectedRouteLayout, PublicRouteLayout, PublicRouteLayoutStyledDiv } from './BaseStyled';

interface AppLayoutBuilderProps {
  isProtectedRouteLayout: boolean;
  isAuthenticated: boolean;
}

export const AppLayoutBuilder = ({
  children,
  isProtectedRouteLayout
}: PropsWithChildren<AppLayoutBuilderProps>) => {
  const classes = getAppBarMobileClasses();
  const currentPath = history.location.pathname;
  const hasProgressBar = onboardingPages.includes(currentPath);

  if (isProtectedRouteLayout) {
    return (
      <ProtectedRouteLayout className={clsx(classes.content, classes.contentShift)}>
        {children}
      </ProtectedRouteLayout>
    );
  }

  return (
    <PublicRouteLayout>
      <OnboardingProgressBar />
      <PublicRouteLayoutStyledDiv hasProgressBar={hasProgressBar} data-id="public-layout-container">
        {children}
      </PublicRouteLayoutStyledDiv>
    </PublicRouteLayout>
  );
};
