import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { getAppError } from 'slices/errors';

import { DIALOG } from 'api/fetchSaga';
import { RootState } from 'store/store';

interface Error {
  message: string;
  reload: boolean;
  redirected: boolean;
  networkUnavailable: boolean;
}

const getErrorPops = (error: Error) => {
  return {
    reload: error.reload,
    redirected: error.redirected,
    networkUnavailable: error.networkUnavailable
  };
};

const apiErrors = ({ api, error }: RootState) => {
  const data = get(api, 'error.data.errors');

  const errorType = get(api, 'errorType');

  if (data && errorType === DIALOG) {
    return {
      ...getErrorPops(error),
      content: data
    };
  }
};

const apiStatus = ({ api }: RootState) => ({
  status: get(api, 'error.status')
});

export const errorStatusSelector = createSelector([apiStatus], status => ({
  ...status
}));

export const errorSelector = createSelector([apiErrors, getAppError], (apiErrors, appError) => {
  return {
    ...(apiErrors || appError)
  };
});
