import { ErrorBoundary as SentryErrorBoundary, ErrorBoundaryProps } from '@sentry/react';
import React from 'react';
import WarningOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { t } from 'locale';
import { IGNORED_ERRORS } from 'apm/sentryAgent';
import { FallbackRender } from '@sentry/react/types/errorboundary';

import { FullButton } from 'components/Buttons/ButtonsStyled';
import { PublicRouteLayoutStyledDiv } from 'components/Base/BaseStyled';
import { ErrorBoundaryWrapper } from 'components/ErrorBoundary/ErrorBoundaryStyled';

import { localeKeys } from 'enums/localeKeys';
import { colors } from 'enums/button';

const ErrorBoundaryFallback: FallbackRender = errorData => {
  if (IGNORED_ERRORS.some(regex => regex.test(errorData.error.message))) {
    return <div />;
  }

  let errorMessage = t(localeKeys.errorBoundaryMessage);
  let reloadButtonText = t(localeKeys.errorReloadButton);

  if (errorMessage === localeKeys.errorBoundaryMessage) {
    errorMessage = 'Something went wrong.';
  }

  if (reloadButtonText === localeKeys.errorReloadButton) {
    reloadButtonText = 'Reload';
  }

  return (
    <PublicRouteLayoutStyledDiv>
      <ErrorBoundaryWrapper>
        <WarningOutlinedIcon color={colors.error} />
        <h4>{errorMessage}</h4>
        <FullButton fullWidth onClick={() => window.location.reload()}>
          {reloadButtonText}
        </FullButton>
      </ErrorBoundaryWrapper>
    </PublicRouteLayoutStyledDiv>
  );
};

export const ErrorBoundary = ({
  children,
  fallback = ErrorBoundaryFallback,
  ...rest
}: ErrorBoundaryProps) => {
  return (
    <SentryErrorBoundary fallback={fallback} {...rest}>
      {children}
    </SentryErrorBoundary>
  );
};
