import { FormikHelpers as FormikActions } from 'formik';

import { createActions } from 'utils/redux';

export interface SignHereValues {
  signature: string | null;
}

export type SignHereActionCreator = (
  payload: { signature: string; token: string },
  actions: FormikActions<SignHereValues>
) => void;

export type SaveSignatureActionCreator = (payload: { signature: string }) => void;

export const { Types, Creators } = createActions({
  saveSignature: ['payload', 'action'],
  sendSmsForLink: ['payload', 'action'],
  getSignature: [],
  signHere: ['payload', 'action']
});
