import { ApmRoute } from '@elastic/apm-rum-react';

import { RouteContainer } from './RouteContainer';

export const Routes = ({ routes, isAuthenticated, isRegisteredFromLink }) =>
  Object.entries(routes).map(([id, route]) => {
    return (
      <ApmRoute
        key={route.id}
        exact={!route.basePath}
        {...route}
        component={props => (
          <RouteContainer
            {...props}
            {...route}
            component={route.component}
            isRegisteredFromLink={isRegisteredFromLink}
            isAuthenticated={isAuthenticated}
          />
        )}
      />
    );
  });
