import { Route } from 'routes';

import { LoaderLayout } from 'components/Loaders/LoaderLayout';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const standingOrdersRoutes: Record<string, Route> = {
  standingOrders: {
    id: 'standingOrders',
    title: 'navigationStandingOrders',
    path: '/standing-orders',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./StandingOrdersContainer')
    })
  }
};

export default standingOrdersRoutes;
