import { Reducer } from '@reduxjs/toolkit';

import { ToggleModalAction, Types } from 'actions/modal';

import { createReducer } from 'utils/redux';

export type ModalState = {
  isSignatureModalOpen: boolean;
  isIdCardModalOpen: boolean;
};

const INITIAL_STATE: ModalState = {
  isSignatureModalOpen: false,
  isIdCardModalOpen: false
};

const handlers = {
  [Types.TOGGLE_MODAL]: (state = INITIAL_STATE, { payload }: ToggleModalAction) => ({
    ...state,
    ...payload
  })
};

export const modal: Reducer<ModalState, ToggleModalAction> = createReducer(INITIAL_STATE, handlers);
