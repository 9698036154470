import styled from 'styled-components';

export const ErrorBoundaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    font-size: 4rem;
  }
`;
