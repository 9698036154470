import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material';

import { SkeletonLayout } from 'components/Skeleton/Skeleton';

import { CircularProgress } from './CircularLoader';

export interface LoaderLayoutProps {
  showHeader?: boolean;
}

export const LoaderLayout = ({ showHeader = false }: LoaderLayoutProps) => {
  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return isMobileView ? <SkeletonLayout showHeader={showHeader} /> : <CircularProgress />;
};
