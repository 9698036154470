import { fetchSaga } from 'api/fetchSaga';
import { Types } from 'actions/account';

import { config } from 'config';

export function* getTransferringAccounts() {
  const params = {
    path: config.apis.transferringAccounts
  };

  return yield fetchSaga({
    params,
    actionType: Types.GET_TRANSFERRING_ACCOUNTS,
    fields: 'accounts',
    isExactApiPath: true
  });
}
