import { takeEvery } from 'redux-saga/effects';

import { Types as AccountTypes } from 'actions/account';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

import { clearStore } from './app';

const { apis } = config;

const { SIGN_OUT } = AccountTypes;

export function* signOut() {
  const params = {
    path: apis.signOut,
    method: httpMethods.DELETE
  };

  yield fetchSaga({
    params,
    actionType: SIGN_OUT
  });

  yield clearStore();
}

export function* signOutSaga() {
  yield takeEvery(SIGN_OUT, signOut);
}
