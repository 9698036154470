import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import noop from 'lodash/noop';
import React from 'react';
import { t } from 'locale';

import { PrimaryButton, TextButton } from 'components/Buttons/ButtonsStyled';
import { Dialog, DialogActions } from 'components/Dialog/DialogStyled';
import { modalTitleId } from 'enums/modal';

import { localeKeys } from 'enums/localeKeys';

import { ModalSearchInput, SearchProps } from './ModalSearchInput';
import { DialogContent, DialogTitle } from './ModalStyled';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

interface ModalProps {
  isOpen?: boolean;
  title?: string;
  children: React.ReactNode;
  onClose?: () => void;
  closeLabel?: string;
  onConfirm?: () => void;
  confirmLabel?: string;
  showClose?: boolean;
  showConfirm?: boolean;
  showContentDividers?: boolean;
  searchProps?: SearchProps;
}

export const Modal = ({
  isOpen = false,
  title = '',
  children,
  onClose = noop,
  closeLabel = '',
  onConfirm = noop,
  confirmLabel = '',
  showClose = false,
  showConfirm = false,
  showContentDividers = true,
  searchProps
}: ModalProps): React.ReactElement => {
  const shouldDisplayDialogActions = showClose === true || showConfirm === true;

  return (
    <Dialog
      TransitionComponent={Transition}
      open={isOpen}
      onClose={onClose}
      aria-labelledby={modalTitleId.modalTitle}
    >
      <DialogTitle>
        <span id={modalTitleId.modalTitle}>{title}</span>
        <IconButton
          size="large"
          id="closeModal"
          aria-label={t(localeKeys.closeButton)}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={showContentDividers}>
        {searchProps && <ModalSearchInput searchProps={searchProps} />}
        <div>{children}</div>
      </DialogContent>
      {shouldDisplayDialogActions && (
        <DialogActions>
          {showClose === true ? (
            <TextButton onClick={onClose} data-id="modal-action-close-button">
              {closeLabel}
            </TextButton>
          ) : null}
          {showConfirm === true ? (
            <PrimaryButton onClick={onConfirm} data-id="modal-action-confirm-button">
              {confirmLabel}
            </PrimaryButton>
          ) : null}
        </DialogActions>
      )}
    </Dialog>
  );
};
