import { tenantIcons } from 'enums/tenantIcons';

import standingOrders from './default/Dark/standing_orders.svg';
import IconLoader from './IconLoader';

export const StandingOrders = ({
  style = {
    width: '32px'
  }
}) => (
  <IconLoader tenantIconName={tenantIcons.standingOrders} src={standingOrders} imageStyle={style} />
);
