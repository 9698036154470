export const inputVariants = {
  default: 'standard', // `default` is App custom variant
  standard: 'standard',
  outlined: 'outlined',
  filled: 'filled'
} as const;

export const textFieldLabel = {
  included: 'included',
  excluded: 'excluded'
} as const;

export const textVariants = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  paragraph: 'p',
  subtitle1: 'subtitle1',
  subtitle2: 'subtitle2',
  body1: 'body1',
  body2: 'body2',
  caption: 'caption',
  button: 'button',
  overline: 'overline',
  srOnly: 'srOnly',
  inherit: 'inherit'
} as const;

export const textAlign = {
  left: 'left',
  center: 'center',
  right: 'right',
  justify: 'justify'
} as const;

export const progressbarVariants = {
  default: 'buffer', // `default` is App custom variant
  indeterminate: 'indeterminate',
  buffer: 'buffer',
  query: 'query',
  determinate: 'determinate'
} as const;

export const edge = {
  start: 'start',
  end: 'end'
} as const;

export const position = {
  fixed: 'fixed',
  relative: 'relative',
  static: 'static',
  sticky: 'sticky',
  absolute: 'absolute'
} as const;

export enum anchorOrigin {
  top = 'top',
  bottom = 'bottom',
  middle = 'middle',
  left = 'left',
  right = 'right'
}

export const anchorReference = {
  anchorPosition: 'anchorPosition'
} as const;

export const drawer = {
  temporary: 'temporary',
  permanent: 'permanent',
  persistent: 'persistent'
} as const;

export const alignItems = {
  flexStart: 'flex-start',
  center: 'center'
} as const;

export const divider = {
  fullWidth: 'fullWidth',
  inset: 'inset',
  middle: 'middle'
} as const;

export const elementType = {
  hr: 'hr',
  li: 'li',
  anchor: 'a'
} as const;

export const wrap = {
  nowrap: 'nowrap',
  wrap: 'wrap',
  wrapReverse: 'wrap-reverse'
} as const;

export enum fontSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export const underline = {
  none: 'none',
  hover: 'hover',
  always: 'always'
} as const;

export const animation = {
  pulse: 'pulse',
  wave: 'wave',
  false: 'false'
} as const;

export const sideMenuVariants = {
  slide: 'slide',
  overlay: 'overlay',
  sticky: 'sticky'
} as const;

export const popperPlacement = {
  bottomEnd: 'bottom-end',
  bottomStart: 'bottom-start'
} as const;
