import { takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';

import { Types } from 'actions/mandates';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

import { getPersonalDetails } from './personalDetails';

const { apis } = config;
const { GET_STANDING_ORDERS, ACKNOWLEDGE_STANDING_ORDERS, DONE_IGNORE_STANDING_ORDERS } = Types;

export function* getStandingOrders() {
  const params = {
    path: apis.standingOrders
  };

  return yield fetchSaga({
    params,
    actionType: GET_STANDING_ORDERS,
    fields: 'standingOrders'
  });
}

export function* doneOrIgnoreStandingOrders({ payload }) {
  const { id, ignore } = payload;

  const params = {
    path: apis.ignoreStandingOrders(id),
    method: httpMethods.PATCH,
    data: {
      ignore: ignore
    }
  };

  const response = yield fetchSaga({
    params,
    fields: ''
  });

  if (response) {
    yield getStandingOrders();
  }
}

export function* acknowledgeStandingOrders() {
  const params = {
    path: apis.acknowledgeStandingOrders,
    method: httpMethods.POST
  };

  const response = yield fetchSaga({
    params,
    actionType: ACKNOWLEDGE_STANDING_ORDERS,
    fields: ''
  });

  // reload user details to get the updated status of `acknowledgedStandingOrders`
  if (response) {
    yield getPersonalDetails();
  }
  return history.push(routes.overview.path);
}

export function* getStandingOrdersSaga() {
  yield takeEvery(GET_STANDING_ORDERS, getStandingOrders);
  yield takeEvery(ACKNOWLEDGE_STANDING_ORDERS, acknowledgeStandingOrders);
  yield takeEvery(DONE_IGNORE_STANDING_ORDERS, doneOrIgnoreStandingOrders);
}
