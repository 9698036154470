import React, { useState } from 'react';
import { Route } from 'slices/routes';

import BannerContainer from 'components/Banner/Banner';
import { AppLayoutBuilder } from 'components/Base/AppLayoutBuilder';
import { BodyElement } from 'components/Base/BaseStyled';
import ErrorDialogContainer from 'components/Dialog/ErrorDialogContainer';
import { Footer } from 'components/Footer/Footer';
import { Snackbar } from 'components/Snackbar/Snackbar';

import { history } from 'routes/history';
import { Navigation } from 'routes/Navigation';
import { NavigationLeft } from 'routes/NavigationLeft';
import { ProtectedRoutes } from 'routes/ProtectedRoutes';
import { PublicRoutes } from 'routes/PublicRoutes';

interface AppLayoutProps {
  isAuthenticated: boolean;
  isRegisteredFromLink: boolean;
  route: Route;
}

export const AppLayout = ({ isAuthenticated, isRegisteredFromLink, route }: AppLayoutProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleDrawer = () => setIsMenuOpen(!isMenuOpen);

  const isProtectedRouteLayout = isAuthenticated && route.type === 'protected';

  return (
    <React.Fragment>
      <Snackbar />
      <ErrorDialogContainer />
      <Navigation
        toggleDrawer={toggleDrawer}
        isAuthenticated={isAuthenticated}
        isRegisteredFromLink={isRegisteredFromLink}
      />
      <BannerContainer />
      <BodyElement>
        <NavigationLeft
          isMenuOpen={isMenuOpen}
          toggleDrawer={toggleDrawer}
          isAuthenticated={isAuthenticated}
          isRegisteredFromLink={isRegisteredFromLink}
        />
        <AppLayoutBuilder
          isProtectedRouteLayout={isProtectedRouteLayout}
          isAuthenticated={isAuthenticated}
        >
          {isAuthenticated ? (
            <ProtectedRoutes
              path={history.location.pathname}
              isRegisteredFromLink={isRegisteredFromLink}
              isAuthenticated={isAuthenticated}
            />
          ) : (
            <PublicRoutes
              path={history.location.pathname}
              isRegisteredFromLink={isRegisteredFromLink}
            />
          )}
        </AppLayoutBuilder>
      </BodyElement>
      <Footer isProtectedRouteLayout={isProtectedRouteLayout} />
    </React.Fragment>
  );
};
