import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import React from 'react';
import { t } from 'locale';
import { Link } from 'react-router-dom';
import { routes } from 'routes';

import { TitleAndCaption } from 'components/PageHeading/PageHeading';
import { Spacer } from 'components/Separator/Separator';
import { TextWithButton, TextWithButtonWrapper } from 'components/Text/TextStyled';
import { LinkButton } from 'components/Buttons/ButtonsStyled';

import { localeKeys } from 'enums/localeKeys';

import { RestrictAppContainer } from './RestrictAppStyled';

interface RestrictAppProps {
  restrictAppMessage: string;
  isWithLoginLink: boolean;
  isWithTitle: boolean;
}

export const RestrictApp = ({
  restrictAppMessage,
  isWithLoginLink,
  isWithTitle
}: RestrictAppProps) => (
  <RestrictAppContainer>
    <Spacer size="lg" />
    <PortableWifiOffIcon />
    <Spacer size="xxxl" />
    <TitleAndCaption
      title={isWithTitle ? t(localeKeys.welcomeBankText) : ''}
      caption={restrictAppMessage}
    />
    <Spacer size="lg" />
    {isWithLoginLink && (
      <TextWithButtonWrapper data-id="link-container">
        <TextWithButton>
          <span data-id="text-already-signed-up">{t(localeKeys.alreadySignedUp)}</span>
          <LinkButton
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={Link}
            to={routes.login.path}
            data-id="login-link"
          >
            {t(localeKeys.loginButtonText)}
          </LinkButton>
        </TextWithButton>
      </TextWithButtonWrapper>
    )}
  </RestrictAppContainer>
);
