import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LinearProgressStyled } from './NetworkLoaderStyled';

export const NetworkLoader = ({ display }) => {
  return display ? <LinearProgressStyled variant="query" /> : null;
};

NetworkLoader.propTypes = {
  display: PropTypes.bool.isRequired
};

const mapStateToProps = ({ api }) => ({ display: api.isFetching || false });

export const NetworkLoaderContainer = connect(mapStateToProps)(NetworkLoader);
