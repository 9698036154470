import { put, takeEvery } from 'redux-saga/effects';

import { Types } from 'actions/account';
import { Creators as AppCreators } from 'actions/app';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

const { apis } = config;
const { USER_LOGIN } = Types;

export function* loginRequest(payload) {
  const params = {
    path: apis.signIn,
    method: httpMethods.POST,
    data: payload
  };

  return yield fetchSaga({ params, actionType: USER_LOGIN });
}

export function* userLogin({ payload, action }) {
  action.setSubmitting(true);
  const response = yield loginRequest(payload);
  action.setSubmitting(false);

  if (response) {
    yield put(AppCreators.initializeApp());
  }
}

export function* userLoginSaga() {
  yield takeEvery(USER_LOGIN, userLogin);
}
