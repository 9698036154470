import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

import { tenantVisualSettingsMUIOverridesPrefix } from 'enums/keys';

export const capitalizeObjectKeys = (object: Record<string, unknown>) =>
  Object.keys(object).reduce<Record<string, unknown>>((transformedObject, key) => {
    const transformedKey = key.charAt(0).toUpperCase().concat(key.slice(1));

    transformedObject[transformedKey] = object[key];

    return transformedObject;
  }, {});

// remove null fields from an object
export const removeObjectNullables = (object: Record<string, unknown>) =>
  Object.keys(object).forEach(key => object[key] === null && delete object[key]);

export const replaceKeysDeep = (obj: Record<string, unknown>, keysMap: Record<string, string>) => {
  // keysMap = { oldKey1: newKey1, oldKey2: newKey2, etc...
  return transform(obj, function (result: Record<string, unknown>, value, key) {
    // transform to a new object
    const currentKey = keysMap[key] || key; // if the key is in keysMap use the replacement, if not use the original key
    result[currentKey] = isObject(value)
      ? replaceKeysDeep(value as Record<string, unknown>, keysMap)
      : value; // if the key is an object run it through the inner function - replaceKeys
  });
};

/**
 * tenant can customise UI by overriding MaterialUI (MUI).
 * See sample in "src\__mocks__\atlasTenantSettingsMock.js"
 * few properties contains special keys e.g. `&$focused`
 * the string transformation on each response ignores special characters e.g. `&$focused` will be renamed to `focused`
 * to avoid that we reiterate object deeply and add back original keys by replacing placeholders
 */
export const mapMUIOverridesPrefix = (object: Record<string, unknown>) =>
  replaceKeysDeep(object, tenantVisualSettingsMUIOverridesPrefix);

/**
 * MUI V5 contains breaking changes in defining theme overrides.
 * Previously (in < V5) theme overrides were under "overrides" property whereas starting from V5 the structure is:
 * e.g.
 * {
 *   components: {
 *     MuiButton: {
 *       styleOverrides: {
 *         root: {
 *           color: "blue"
 *         }
 *       }
 *     }
 *   }
 * }
 * This method transforms tenant visual settings from old "overrides" (MUI V4) interface to the new one (MUI v5)
 * @param overridesObject
 */
export const mapMUIv4OverridesToMUIv5 = (overridesObject: Record<string, unknown>) => {
  return Object.entries(overridesObject).reduce<Record<string, unknown>>(
    (accumulator, [key, styleOverrides]) => {
      accumulator[key] = {
        styleOverrides
      };

      return accumulator;
    },
    {}
  );
};
