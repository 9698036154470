import { put, select, take, takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';
import { clearError, triggerError } from 'slices/errors';
import { registerRoute } from 'slices/routes';

import { Creators as AppCreators } from 'actions/app';
import { clearStore } from 'sagas/app';
import { appSelector } from 'selectors/appSelectors';
import { errorSelector, errorStatusSelector } from 'selectors/errorSelector';
import { routeSelector } from 'selectors/routeSelector';

import { errorMessages } from 'enums/errorMessages';
import { routeTypes } from 'enums/routeTypes';

import { Creators as ApiCreators, Types as ApiTypes } from './actions';

const UNAUTHORIZED = 401;
const SERVER_ERROR = 500;

const shouldRedirectToOldBankSelection = (isAuthenticated, route, status) => {
  return (
    status === UNAUTHORIZED &&
    !isAuthenticated &&
    route.type === routeTypes.default &&
    route.id !== routes.oldBankSelection.id
  );
};

export function* handleApiErrors() {
  let routeData = yield select(routeSelector);

  if (!routeData.route.id) {
    yield take(registerRoute.type);
    routeData = yield select(routeSelector);
  }

  const { route } = routeData;
  const { isAuthenticated } = yield select(appSelector);
  const { status } = yield select(errorStatusSelector);
  const { content } = yield select(errorSelector);

  if (status === UNAUTHORIZED) {
    if (isAuthenticated) {
      yield put(AppCreators.setSession(false));
      history.push(routes.login.path);
      yield put(
        triggerError({
          message: content,
          options: {
            redirected: true
          }
        })
      );
    }

    yield clearStore();
  }

  /* The user should be redirected to /old-bank if it's not authenticated, so we don't want to redirect him
   to /old-bank if the user is already there */
  if (shouldRedirectToOldBankSelection(isAuthenticated, route, status)) {
    history.push(routes.oldBankSelection.path);
    yield put(triggerError({ message: content, options: { redirected: true } }));
    yield clearStore();
  }

  if (status === SERVER_ERROR) {
    yield put(triggerError({ message: errorMessages.serverError }));
  }
}

export function* resetApiError() {
  yield put(ApiCreators.apiErrorReset());
}

export function* apiErrorHandlingSaga() {
  yield takeEvery(ApiTypes.API_ERROR, handleApiErrors);
  yield takeEvery(clearError.type, resetApiError);
}
