import React from 'react';
import { Link } from 'react-router-dom';

import { history } from 'routes/history';

import { getIsMenuActive } from 'utils/getIsMenuActive';

import {
  DrawerColumnContent,
  DrawerColumnLayout,
  DrawerIconWrapper,
  DrawerRowContent,
  DrawerRowLayout,
  DrawerTitle
} from './DrawerStyled';

interface DrawerItemProps {
  title: string;
  to: string;
  icon?: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isColumnLayout?: boolean;
  dataId?: string;
}

export const DrawerItem = ({
  title,
  to: toLink,
  icon = null,
  onClick: drawerItemAction,
  isColumnLayout = false,
  dataId = ''
}: DrawerItemProps) => {
  const { pathname } = history.location;

  const isActive = getIsMenuActive(pathname, toLink);

  const conditionalProps = {
    to: toLink,
    component: Link,
    selected: isActive
  };

  const DrawerContents = () => (
    <>
      {icon && <DrawerIconWrapper>{icon}</DrawerIconWrapper>}
      <DrawerTitle>{title}</DrawerTitle>
    </>
  );

  return (
    <div data-id={dataId}>
      {isColumnLayout ? (
        <DrawerColumnLayout active={isActive} onClick={drawerItemAction} {...conditionalProps}>
          <DrawerColumnContent>
            <DrawerContents />
          </DrawerColumnContent>
        </DrawerColumnLayout>
      ) : (
        <DrawerRowLayout active={isActive} onClick={drawerItemAction} {...conditionalProps}>
          <DrawerRowContent>
            <DrawerContents />
          </DrawerRowContent>
        </DrawerRowLayout>
      )}
    </div>
  );
};
