import React from 'react';
import { t } from 'locale';

import { ProgressBar } from 'components/ProgressBar/ProgressBar';

import { localeKeys } from 'enums/localeKeys';

import { ProgressBarContainer } from './OnboardingProgressBarStyled';

export interface OnboardingProgressBarProps {
  currentStep: number;
  totalSteps: number;
  isVisible: boolean;
}

export const OnboardingProgressBar = ({
  currentStep,
  isVisible,
  totalSteps
}: OnboardingProgressBarProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <ProgressBarContainer>
      <ProgressBar
        length={totalSteps}
        amount={currentStep}
        title={t(localeKeys.onboardingProgressBarTitle)}
      />
    </ProgressBarContainer>
  );
};
