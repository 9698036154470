import React, { Suspense, lazy } from 'react';

import { CircularProgress } from 'components/Loaders/CircularLoader';

interface LoadableProps {
  loading?: React.ComponentType;
  loader: Parameters<typeof lazy>[0];
}

const Loadable =
  ({ loading, loader }: LoadableProps) =>
  () => {
    const Component = lazy(loader);
    const Loading = loading ?? CircularProgress;

    return (
      <Suspense fallback={<Loading />}>
        <Component />
      </Suspense>
    );
  };

export default Loadable;
