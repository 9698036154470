import get from 'lodash/get';
import { createSelector, Selector } from '@reduxjs/toolkit';

import { TenantSettings, Types } from 'actions/app';
import { RootState } from 'store/store';

export const tenantSettingsResponseData: Selector<RootState, TenantSettings> = ({ api }) =>
  get(api, [Types.GET_TENANT_SETTINGS, 'data'], {});

export const tenantIconsResponseData: Selector<RootState, Record<string, unknown>> = ({ api }) =>
  get(api, [Types.GET_TENANT_ICONS, 'data'], {});

export const getAllowedLanguages: Selector<RootState, { label: string; value: string }[]> = ({
  api
}) => {
  const options: TenantSettings['allowedLanguages'] | [] = get(
    api,
    [Types.GET_TENANT_SETTINGS, 'data', 'allowedLanguages'],
    []
  );

  return options.map(({ code, language }) => ({
    label: language,
    value: code
  }));
};

export const tenantIconsSelector: Selector<RootState, Record<string, unknown>> = createSelector(
  [tenantIconsResponseData],
  tenantIcons => ({
    tenantIcons
  })
);

export const tenantSettingsSelector: Selector<RootState, { tenantSettings: TenantSettings }> =
  createSelector([tenantSettingsResponseData], tenantSettings => {
    return {
      tenantSettings: {
        ...tenantSettings,
        enablePersonal: tenantSettings.enablePersonal ?? true
      }
    };
  });
