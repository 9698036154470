import get from 'lodash/get';

import { LocaleKey, TranslationsData } from 'locale/types';
import { Language } from 'locale/reducer';

import { config } from 'config';

let __LANG__: TranslationsData | null = null;
const { langLocalStorageKey, langLocalStorageHashKey } = config;

export const t = (key: LocaleKey): string | LocaleKey =>
  get(__LANG__, ['translations', key, 'message'], key);

export const storeLangTranslationsToLocalStorage = (lang: Language, data: TranslationsData) => {
  __LANG__ = data;
  window.localStorage.setItem(langLocalStorageKey(lang), JSON.stringify(data));
};

export const getLangTranslationsFromLocalStorage = (lang: Language) => {
  const storedLang = window.localStorage.getItem(langLocalStorageKey(lang));
  if (storedLang) {
    __LANG__ = JSON.parse(storedLang);

    return __LANG__;
  }
  __LANG__ = null;

  return null;
};

export const getLangHashFromLocalStorage = (lang: Language) =>
  window.localStorage.getItem(langLocalStorageHashKey(lang));

export const storeLangHashToLocalStorage = (lang: Language, hash: string) => {
  window.localStorage.setItem(langLocalStorageHashKey(lang), JSON.stringify(hash));
};

export const getSelectedLanguageFromLocalStorage = () =>
  window.localStorage.getItem('__MASK_SELECTED_LANG__');

export const storeSelectedLanguageToLocalStorage = (lang: Language) =>
  window.localStorage.setItem('__MASK_SELECTED_LANG__', lang);

const formatId = (id: string) => `__MASK_${id.toUpperCase()}`;

export const getStorageItem = (id: string) => window.localStorage.getItem(formatId(id));

export const setStorageItem = (id: string, value: string | boolean) =>
  window.localStorage.setItem(formatId(id), String(value));

export const removeStorageItem = (id: string) => window.localStorage.removeItem(formatId(id));
