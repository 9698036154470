import { delay, put, takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';

import { Types } from 'actions/account';
import { Creators as AppCreators } from 'actions/app';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';

import { getPersonalDetails } from './personalDetails';

const { apis } = config;
const { REGISTER_LOGIN_DETAILS, AUTO_LOGIN } = Types;

export function* autoLogin({ payload }) {
  const { data, iv } = payload;
  const params = {
    path: apis.autoLogin(data, iv)
  };

  const response = yield fetchSaga({
    params,
    actionType: AUTO_LOGIN,
    fields: 'user'
  });

  if (response) {
    yield put(AppCreators.initializeApp());
    return history.replace(routes.overview.path);
  }

  return history.replace(routes.oldBankSelection.path);
}

export function* createLoginDetails({ payload, action }) {
  const { email, password } = payload;

  const updateUserParams = {
    path: apis.accountEmail,
    method: httpMethods.PUT,
    data: {
      user: { email }
    }
  };

  const passwordParams = {
    path: apis.accountPassword,
    method: httpMethods.POST,
    data: {
      user: { password }
    }
  };

  action.setSubmitting(true);

  const responseEmail = yield fetchSaga({
    params: updateUserParams,
    actionType: REGISTER_LOGIN_DETAILS
  });

  if (responseEmail) {
    const responsePassword = yield fetchSaga({
      params: passwordParams,
      payload: {
        email,
        password
      },
      actionType: REGISTER_LOGIN_DETAILS
    });

    const { isManualSwitch } = yield getPersonalDetails();

    if (isManualSwitch) {
      yield put(AppCreators.initializeApp());
      yield delay(1000);
      history.replace(routes.overview.path);
    } else {
      responsePassword && history.replace(routes.accountAnalysis.path);
    }
  }

  action.setSubmitting(false);
}

export function* loginDetailsSagas() {
  yield takeEvery(REGISTER_LOGIN_DETAILS, createLoginDetails);
  yield takeEvery(AUTO_LOGIN, autoLogin);
}
