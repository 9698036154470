import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

import { animation } from 'enums/variants';

export const SkeletonCard = () => (
  <Card>
    <CardHeader
      avatar={<Skeleton animation={animation.wave} variant="circular" width={40} height={40} />}
      title={
        <Skeleton animation={animation.wave} height={10} width="80%" style={{ marginBottom: 6 }} />
      }
      subheader={<Skeleton animation={animation.wave} height={10} width="40%" />}
    />
    <Skeleton animation={animation.wave} height={120} variant="rectangular" />
    <CardContent>
      <React.Fragment>
        <Skeleton animation={animation.wave} height={10} style={{ marginBottom: 6 }} />
        <Skeleton animation={animation.wave} height={10} width="80%" />
      </React.Fragment>
    </CardContent>
  </Card>
);
