import { delay, put, takeEvery } from 'redux-saga/effects';
import { t } from 'locale';

import { Creators as ModalCreators } from 'actions/modal';
import { Types } from 'actions/signature';
import { Creators as SnackbarCreators } from 'actions/snackbar';
import { fetchSaga } from 'api/fetchSaga';
import { toggleModalTypes } from 'enums/modal';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';
import { localeKeys } from 'enums/localeKeys';

const { apis } = config;

const { GET_SIGNATURE, SAVE_SIGNATURE, SEND_SMS_FOR_LINK, SIGN_HERE } = Types;

export function* getSignature() {
  const params = {
    path: apis.accountSignature
  };

  return yield fetchSaga({
    params,
    actionType: GET_SIGNATURE,
    fields: 'signature.image'
  });
}

export function* saveSignature({ payload, action }) {
  action.setSubmitting(true);

  const params = {
    method: httpMethods.POST,
    path: apis.accountSignature,
    data: {
      signature: {
        image: payload.signature
      }
    }
  };

  yield fetchSaga({
    params,
    payload,
    actionType: SAVE_SIGNATURE,
    fields: 'signature'
  });

  action.setSubmitting(false);

  yield put(ModalCreators.toggleModal(toggleModalTypes.signature(false)));
  yield getSignature();
}

export function* signHere({ payload, action }) {
  action.setSubmitting(true);

  const params = {
    method: httpMethods.POST,
    path: apis.accountSignature,
    data: {
      signature: {
        image: payload.signature
      },
      token: payload.token
    }
  };

  yield fetchSaga({
    params,
    payload,
    actionType: SAVE_SIGNATURE,
    fields: 'signature'
  });

  action.setSubmitting(false);

  yield put(SnackbarCreators.triggerSnack(t(localeKeys.signatureUploadedText)));
  yield getSignature();
}

export function* sendSmsForLink({ payload, action }) {
  action.setSubmitting(true);

  const params = {
    method: httpMethods.POST,
    path: apis.accountSignatureLink,
    data: {
      ...payload,
      signatureUrl: `${window.location.origin}/sign_here`
    }
  };

  const response = yield fetchSaga({
    params,
    payload,
    actionType: SEND_SMS_FOR_LINK
  });

  action.setSubmitting(false);

  if (response) {
    yield put(SnackbarCreators.triggerSnack(t(localeKeys.smsSentText)));
    yield put(ModalCreators.toggleModal(toggleModalTypes.signature(false)));

    const lastSignature = yield getSignatureImage();
    yield poolSignature(0, lastSignature);
  }
}

function* getSignatureImage() {
  const params = {
    path: apis.accountSignature
  };

  return yield fetchSaga({
    params,
    fields: 'signature.image'
  });
}

function* poolSignature(counter, lastSignature) {
  const MAX_DATA_POLLING = 10;
  const newSignature = yield getSignatureImage();

  if (
    counter > MAX_DATA_POLLING ||
    JSON.stringify(newSignature) !== JSON.stringify(lastSignature)
  ) {
    return yield getSignature();
  }

  counter++;
  yield delay(config.accountStatusPollingDelay);
  yield delay(config.accountStatusPollingDelay);
  yield poolSignature(counter, lastSignature);
}

export function* signatureSaga() {
  yield takeEvery(GET_SIGNATURE, getSignature);
  yield takeEvery(SAVE_SIGNATURE, saveSignature);
  yield takeEvery(SEND_SMS_FOR_LINK, sendSmsForLink);
  yield takeEvery(SIGN_HERE, signHere);
}
