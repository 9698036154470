import Divider from '@mui/material/Divider';
import React, { useState } from 'react';
import { t } from 'locale';

import { DrawerItem } from 'components/Drawer/DrawerItem';
import { Faq } from 'components/Icons/Faq';
import { Imprint } from 'components/Icons/Imprint';
import { Privacy } from 'components/Icons/Privacy';
import { MarkDown } from 'components/MarkDown';
import { Modal } from 'components/Modal/Modal';

import { config } from 'config';

import { localeKeys } from 'enums/localeKeys';

import { getPrivacyPolicyUrlAndTitle } from 'utils/url';
import { isEmbeddedView } from 'utils/util';
import { parseStringAsTemplate } from 'utils/stringTemplate';

import { AppBarLanguageMenu } from '../Header/AppBarLanguage';

const INITIAL_MODAl_STATE = {
  modalTitle: '',
  modalContent: '',
  unHighlightedModalContent: ''
};

export const AppBarHelp = ({ bankName, closeMenu }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(INITIAL_MODAl_STATE);
  const { modalTitle, modalContent } = modalData;

  const imprintTitle = t(localeKeys.textsImprintTitle);
  const imprintContent = t(localeKeys.textsImprint);

  const faqTitle = t(localeKeys.textsFaqTitle);
  const faqContent = parseStringAsTemplate(
    'textsFaq',
    {
      new_bank: bankName
    },
    config.defaultStringTemplateDelimiter
  );

  const { pp: privacyPolicy } = config.oldBankLoginFormLinkIdsToModal;
  const { title: privacyPolicyTitle, url: privacyPolicyUrl } = getPrivacyPolicyUrlAndTitle(
    t(privacyPolicy)
  );

  const openModal = (modalTitle, modalContent) => event => {
    event.preventDefault();
    setIsModalOpen(true);
    setModalData({
      modalTitle,
      modalContent,
      unHighlightedModalContent: modalContent
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    closeMenu && closeMenu();
    setModalData(INITIAL_MODAl_STATE);
  };

  const openLinkInNewTab = url => event => {
    event.preventDefault();
    closeMenu && closeMenu();
    window.open(url);
  };

  const updateModalData = content => {
    setModalData(prevState => ({
      ...prevState,
      modalContent: content
    }));
  };

  return (
    <React.Fragment>
      <DrawerItem
        to=""
        dataId="sidebar-item-faq"
        title={faqTitle}
        onClick={openModal(faqTitle, faqContent)}
        icon={<Faq />}
      />
      <DrawerItem
        to=""
        dataId="sidebar-item-imprint"
        title={imprintTitle}
        onClick={openModal(imprintTitle, imprintContent)}
        icon={<Imprint />}
      />
      <DrawerItem
        to=""
        dataId="sidebar-item-privacy"
        onClick={openLinkInNewTab(privacyPolicyUrl)}
        title={privacyPolicyTitle}
        icon={<Privacy />}
      />
      {isEmbeddedView() && (
        <React.Fragment>
          <Divider />
          <AppBarLanguageMenu showLabel />
        </React.Fragment>
      )}
      <Modal
        title={modalTitle}
        isOpen={isModalOpen}
        onClose={closeModal}
        searchProps={{
          updateModalData,
          modalData
        }}
      >
        <MarkDown text={modalContent} />
      </Modal>
    </React.Fragment>
  );
};
