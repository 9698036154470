import { Types } from 'actions/app';

import { createReducer } from 'utils/redux';

const INITIAL_STATE = {
  isAuthenticated: false,
  isFetching: true,
  appMenuHidden: false,
  showLoader: false
};

const handlers = {
  [Types.SET_SESSION]: (state = INITIAL_STATE, action) => ({
    ...state,
    isAuthenticated: action.payload.isAuthenticated
  }),
  [Types.REQUEST_STATUS]: (state = INITIAL_STATE, action) => ({
    ...state,
    isFetching: action.isFetching
  }),
  [Types.HIDE_APP_MENU]: (state = INITIAL_STATE, action) => ({
    ...state,
    appMenuHidden: action.disable
  }),
  [Types.SHOW_LOADER]: (state = INITIAL_STATE, action) => ({
    ...state,
    showLoader: action.isLoading
  })
};

export const app = createReducer(INITIAL_STATE, handlers);
