import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { Creators as SnackbarCreators } from 'actions/snackbar';
import { snackbarSelector } from 'selectors/snackbarSelector';

import { config } from 'config';

import { anchorOrigin } from 'enums/variants';

import { useAfterRender } from 'hooks/useAfterRender';
import { useIsUpdated } from 'hooks/useIsUpdated';

import { SnackbarContent } from './SnackbarContent';
import { SnackbarContext } from './SnackbarContext';
import { Snackbar as StyledMuiSnackbar, SnackbarContainer } from './SnackbarStyled';

const _Snackbar = ({ snackMessage, snackType, clearSnack }) => {
  const { snacks, enqueueSnack, dequeueSnack } = useContext(SnackbarContext);

  useAfterRender(() => {
    setTimeout(dequeueSnack, config.snackbarAutoHideDuration);
  });

  useIsUpdated(() => {
    if (snackMessage) {
      enqueueSnack(snackMessage, snackType);
      clearSnack();
    }
  }, snackMessage);

  return (
    <SnackbarContainer>
      {snacks.map(({ message, type }, index) => (
        <React.Fragment key={index}>
          <StyledMuiSnackbar
            open={snacks.length > 0}
            anchorOrigin={{
              vertical: anchorOrigin.top,
              horizontal: anchorOrigin.right
            }}
          >
            <SnackbarContent type={type} message={message} onClose={dequeueSnack} />
          </StyledMuiSnackbar>
        </React.Fragment>
      ))}
    </SnackbarContainer>
  );
};

const mapStateToProps = state => snackbarSelector(state);
export const Snackbar = connect(mapStateToProps, {
  clearSnack: SnackbarCreators.clearSnack
})(_Snackbar);
