import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';

import { MarkDown } from 'components/MarkDown';
import { Caption, H1Title, H2Title, H3Title, H5Title } from 'components/Text/TextStyled';

interface TitleAndCaptionProps {
  title?: string;
  caption?: string;
  dataId?: string;
  heading?: number;
}

const headingTitleComponentMap: Record<number, OverridableComponent<TypographyTypeMap>> = {
  1: H1Title,
  2: H2Title,
  3: H3Title,
  5: H5Title
};

export const TitleAndCaption = ({
  title,
  caption,
  dataId = '',
  heading = 2
}: TitleAndCaptionProps) => {
  const TitleComponent = headingTitleComponentMap[heading] ?? H2Title;

  return (
    <>
      {title && <TitleComponent data-id={`${dataId}-title`}>{title}</TitleComponent>}
      {caption && (
        <Caption data-id={`${dataId}-caption`}>
          <MarkDown text={caption} />
        </Caption>
      )}
    </>
  );
};
