import { connect, ConnectedProps } from 'react-redux';
import { clearError } from 'slices/errors';

import { errorSelector } from 'selectors/errorSelector';
import { RootState } from 'store/store';

import { ErrorDialog } from './ErrorDialog';

const mapStateToProps = (state: RootState) => errorSelector(state);

const connector = connect(mapStateToProps, {
  clearError
});

export type ErrorDialogPropsFromRedux = ConnectedProps<typeof connector>;

const ErrorDialogContainer = connector(ErrorDialog);

export default ErrorDialogContainer;
