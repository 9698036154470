import { all, call, put, takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';
import { removeStorageItem } from 'locale';
import { clearBankSelection } from 'slices/banks';
import { triggerError } from 'slices/errors';

import { AccountStatus } from 'actions/account';
import { Creators, Types } from 'actions/app';
import { Creators as IdCardCreators } from 'actions/idCard';
import { Creators as ApiCreators } from 'api/actions';
import { fetchSaga } from 'api/fetchSaga';
import { Types as LocaleTypes } from 'locale/actions';
import { initializeLocale } from 'locale/localeSaga';
import { accountStatus } from 'sagas/accountAnalysis';
import { getTransferringAccounts } from 'sagas/accounts';

import { config } from 'config';

import { errorMessages } from 'enums/errorMessages';
import { sessionStatusText } from 'enums/keys';

import { getCloseAccountStatus } from './closeAccount';
import { getIban } from './iban';
import { getIdCard } from './idCard';
import { getCreditorMandates } from './mandates';
import { getPersonalDetails } from './personalDetails';
import { getSignature } from './signature';
import { getStandingOrders } from './standingOrders';
import { getTransferBalance } from './transferBalance';

const { apis } = config;
const { INITIALIZE_APP, CLEAR_STORE, SESSION_STATUS } = Types;

const except = [Types.GET_TENANT_SETTINGS, LocaleTypes.GET_LOCALE_HASH];

export function* clearStore() {
  removeStorageItem('embedded');
  removeStorageItem('manual_account_switch');
  yield put(Creators.setSession(false));
  yield put(ApiCreators.apiReset(except));
  yield put(clearBankSelection());
}

export function* clearKeysFromStore(keysToReset) {
  yield put(ApiCreators.apiResetByKeys(keysToReset));
}

export const hasIdCard = tenantSettings => {
  const { idCardIntegrationStyle = '' } = tenantSettings;
  return (
    idCardIntegrationStyle !== config.idCardIntegrationStyleToDisable &&
    tenantSettings.uploadIdentification
  );
};

export function* handleIdCard(tenantSettings) {
  if (hasIdCard(tenantSettings)) {
    yield getIdCard();
  } else {
    yield put(IdCardCreators.enableIdCard(false));
  }
}

export const checkAccountStatus = status => {
  const accountStatusError = AccountStatus.ERROR === status;
  const accountStatusStillInProgress =
    AccountStatus.ANALYZING === status || AccountStatus.OPTIONS === status;
  const accountStatusIsCompleted = AccountStatus.COMPLETE === status;

  if (accountStatusError) {
    const queryParams = ['/auto-login', '/register_user'].includes(history.location.pathname)
      ? ''
      : history.location.search;

    history.replace(`${routes.oldBankSelection.path}${queryParams}`);
    return false;
  }

  if (accountStatusStillInProgress) {
    history.replace(routes.accountAnalysis.path);
    return false;
  }

  return accountStatusIsCompleted;
};

export function* getBankData(tenantSettings) {
  yield all([call(getCreditorMandates), call(getTransferBalance), call(getTransferringAccounts)]);

  const { disableCloseAccount, disableStandingOrders } = tenantSettings;

  if (!disableStandingOrders) {
    yield getStandingOrders();
  }

  if (!disableCloseAccount) {
    yield getCloseAccountStatus();
  }
}

export function* loadFeatureToggles() {
  const params = {
    path: apis.featureToggles
  };

  return yield fetchSaga({
    params,
    actionType: Types.GET_FEATURE_TOGGLES
  });
}

export function* loadTenantSettings() {
  const params = {
    path: apis.tenantSettings
  };

  return yield fetchSaga({
    params,
    actionType: Types.GET_TENANT_SETTINGS,
    fields: 'tenant'
  });
}

export function* initializeTenantFeatures() {
  yield loadFeatureToggles();
}

export function* loadTenantIcons() {
  const params = {
    path: apis.tenantIcons
  };

  return yield fetchSaga({
    params,
    actionType: Types.GET_TENANT_ICONS
  });
}

/**
 * InitApp
 * @description This saga handles application initialization. It basically
 * loads tenantSettings and accountDetails and based on that initializes locale
 * and loads account and bank data needed for application
 * @returns {IterableIterator<*>}
 */
function* initApp() {
  yield put(Creators.requestStatus(true));
  const tenantSettings = yield loadTenantSettings();
  yield initializeLocale(tenantSettings);

  if (tenantSettings) {
    yield initializeTenantFeatures();
    yield loadTenantIcons();

    const accountDetails = yield getPersonalDetails();

    if (accountDetails) {
      const { isRegistrationComplete, isManualSwitch } = accountDetails;
      yield put(
        Creators.setSession({
          isAuthenticated: isRegistrationComplete
        })
      );

      yield getIban();

      if (isRegistrationComplete) {
        yield getSignature();
        yield handleIdCard(tenantSettings);

        const accountStatusCompleted = isManualSwitch || checkAccountStatus(yield accountStatus());
        const isOldBank = history.location.pathname.includes('old-bank');

        if (accountStatusCompleted) {
          yield getBankData(tenantSettings);
          isOldBank && history.replace(routes.overview.path);
        }
      }
    }
  } else {
    yield put(
      triggerError({
        message: errorMessages.tenantErrorText,
        options: {
          reload: true
        }
      })
    );
  }

  return yield put(Creators.requestStatus(false));
}

function* pollSessionStatus() {
  const params = {
    path: apis.sessionStatus
  };

  const response = yield fetchSaga({
    params,
    isBackgroundCall: true,
    actionType: Types.SESSION_STATUS
  });

  if (response && response.message !== sessionStatusText.sessionActive) {
    yield clearStore();
    history.push(routes.login.path);
    yield put(
      triggerError({
        message: errorMessages.unauthorizedError,
        options: {
          redirected: true
        }
      })
    );
  }
}

export function* appSaga() {
  yield takeEvery(INITIALIZE_APP, initApp);
  yield takeEvery(CLEAR_STORE, clearStore);
  yield takeEvery(SESSION_STATUS, pollSessionStatus);
}
