import styled from 'styled-components';

export const FooterStyled = styled.footer`
  &,
  .powered-by-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .powered-by-footer {
    p,
    span {
      margin: 0;
      font-weight: ${({ theme }) => theme.typography.fontWeights.normal};
      font-size: ${({ theme }) => theme.typography.fontSizes.XS};
      color: ${({ theme }) => theme.palette.greyishBrown};
    }
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.palette.greyishBrown};
      &:hover {
        text-decoration: underline;
      }
      &:visited {
        color: ${({ theme }) => theme.palette.greyishBrown};
      }
    }

    .powered-by-footer-text {
      padding-top: 10px;
    }
  }

  .lock-icon-container {
    display: flex;
    align-items: center;
    width: 300px;
    hr {
      width: 100%;
    }
    svg {
      margin: 0 5px;
      color: ${({ theme }) => theme.palette.greyishBrown};
    }
  }

  .tenant-custom-footer {
    padding-bottom: 15px;
  }
`;
