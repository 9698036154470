import styled from 'styled-components';

import { Container } from 'components/Container/ContainerStyled';
import { TextWithButtonWrapper } from 'components/Text/TextStyled';

export const RestrictAppContainer = styled(Container)`
  text-align: center;
  svg {
    font-size: 56px;
  }

  & ${TextWithButtonWrapper} {
    margin: 0;
    padding-bottom: 0;
  }
`;
