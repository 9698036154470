import * as Sentry from '@sentry/react';
import { nanoid } from 'nanoid';
import { APM_USER_ID } from 'apm/apmAgent';
import { getStorageItem, setStorageItem } from 'locale';

import { version } from '../../package.json';

// Here more details why we have to ignore "Failed to fetch dynamically imported module" error https://qwist.atlassian.net/browse/KIT2-2166
export const IGNORED_ERRORS = [
  /Failed to fetch dynamically imported module/, //on Chrome
  /error loading dynamically imported module/, //on Firefox
  /'text\/html' is not a valid JavaScript MIME type/, //on Safari
  /Importing a module script failed/ //on Safari Mobile
];

export const initializeSentryAgent = () => {
  const { SENTRY_ENABLED, SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_DEBUG, SENTRY_SAMPLE_RATE } =
    window?.serverEnvConfig ?? {};

  if (SENTRY_ENABLED === 'true' && SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      ignoreErrors: IGNORED_ERRORS,
      /*
      Configures the sample rate for error events, in the range of 0.0 to 1.0.
      The default is 1.0 which means that 100% of error events are sent.
      If set to 0.1 only 10% of error events will be sent. Events are picked randomly.
      */
      sampleRate: parseFloat(SENTRY_SAMPLE_RATE),
      debug: SENTRY_DEBUG === 'true',
      environment: SENTRY_ENVIRONMENT,
      release: version
    });

    setSentryUserContext();
  }
};

const setSentryUserContext = (userContext = {}) => {
  let apmUserID = getStorageItem(APM_USER_ID);

  if (!apmUserID) {
    apmUserID = nanoid();
    setStorageItem(APM_USER_ID, apmUserID);
  }

  Sentry.setUser({
    id: apmUserID,
    ...userContext
  });
};
