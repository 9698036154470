import LockIcon from '@mui/icons-material/Lock';

import { tenantIcons } from 'enums/tenantIcons';

import IconLoader from './IconLoader';

export const Lock = ({
  style = {
    height: '24px'
  }
}) => <IconLoader tenantIconName={tenantIcons.lock} src={LockIcon} imageStyle={style} isMuiIcon />;
