import { Route } from 'routes';

import Loadable from 'utils/Loadable';

const resetPasswordRoutes: Record<string, Route> = {
  resetPassword: {
    id: 'resetPassword',
    title: 'navigationAccountSwitchText',
    path: '/reset-password',
    isProtected: false,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('./ResetPasswordContainer')
    })
  },
  resetPasswordToken: {
    id: 'resetPasswordToken',
    title: 'navigationAccountSwitchText',
    path: '/reset-password/set-password',
    isProtected: false,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('./ResetPasswordTokenContainer')
    })
  },
  resetPasswordSuccess: {
    id: 'resetPasswordSuccess',
    title: 'navigationAccountSwitchText',
    path: '/reset-password/success',
    isProtected: false,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('components/ResetPassword/ResetPasswordSuccess')
    })
  },
  resetPasswordTokenSuccess: {
    id: 'resetPasswordTokenSuccess',
    title: 'navigationAccountSwitchText',
    path: '/reset-password/token-sent',
    isProtected: false,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('components/ResetPassword/ResetPasswordTokenSuccess')
    })
  }
};

export default resetPasswordRoutes;
