import { Route } from 'routes';

import { LoaderLayout } from 'components/Loaders/LoaderLayout';

import { routeTypes } from 'enums/routeTypes';

import Loadable from 'utils/Loadable';

const manualMandateRoutes: Record<string, Route> = {
  manualIncomingPayments: {
    id: 'manualIncomingPayments',
    title: 'navigationManualIncomingPayments',
    path: '/incoming-payments-manual',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./ManualMandateContainer')
    })
  },
  manualDirectDebits: {
    id: 'manualDirectDebits',
    title: 'navigationManualDirectDebits',
    path: '/direct-debits-manual',
    isProtected: true,
    type: routeTypes.protected,
    redirectOnUnauthorized: true,
    component: Loadable({
      loading: LoaderLayout,
      loader: () => import('./ManualMandateContainer')
    })
  }
};

export default manualMandateRoutes;
