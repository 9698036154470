import axios from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';

import { httpMethods } from 'enums/httpMethods';
import { transformCase } from 'enums/keys';

import { transformKeys } from 'utils/transformKeys';

export let httpRequest = options => {
  const {
    path: url,
    method = httpMethods.GET,
    headers,
    transformData = true,
    keyToSuppressTransformation,
    ...otherOptions
  } = options;

  const getTransformedData = data => {
    // CAUTION: THIS `transformKeys` NEEDS TO BE REMOVED.
    const transformedData = transformKeys(data, transformCase.SnakeCase);

    if (keyToSuppressTransformation) {
      // If API is expecting to transform all keys except the one.

      // Step 1: Get object state before transformation
      const objectBeforeTransformation = get(data, keyToSuppressTransformation);
      if (objectBeforeTransformation) {
        // Step 2: Re Set transformed object state with the previous value
        set(transformedData, keyToSuppressTransformation, objectBeforeTransformation);
      }
    }

    return transformedData;
  };

  if (transformData && otherOptions.data) {
    otherOptions.data = getTransformedData(otherOptions.data);
  }

  if (transformData && otherOptions.params) {
    otherOptions.params = getTransformedData(otherOptions.params);
  }

  return axios({
    url,
    method,
    headers: {
      ...headers
    },
    ...otherOptions
  }).then(response => {
    response.data = transformKeys(response.data);
    return response;
  });
};
