import React from 'react';
import { routes } from 'routes';

import { AppBarContext } from 'components/AppBar/AppBarContext';
import { ImageLoader } from 'components/Icons/ImageLoader';
import { PageTitle } from 'components/Text/TextStyled';

import { redirectToPath } from 'utils/util';

import { getAppBarMobileClasses } from './appBarMobileUtils';
import { LogoWrapper } from './AppBarStyled';
import { AppBarHeader } from './Header/AppBarHeader';

export const AppBar = ({ title, appBarProps }) => {
  const classes = getAppBarMobileClasses();
  const {
    appMenuHidden,
    tenantSettings: { logo }
  } = appBarProps;

  const appLogo = (
    <React.Fragment>
      {!appMenuHidden ? (
        <LogoWrapper onClick={() => redirectToPath(routes.overview.path)}>
          <ImageLoader src={logo} />
        </LogoWrapper>
      ) : (
        <PageTitle onClick={() => redirectToPath(routes.overview.path)}>{title}</PageTitle>
      )}
    </React.Fragment>
  );

  return (
    <AppBarContext.Provider value={appBarProps}>
      <AppBarHeader classes={classes} logoComponent={appLogo} />
    </AppBarContext.Provider>
  );
};
