import MuiDrawer from '@mui/material/Drawer';
import React, { useContext } from 'react';

import { anchorOrigin, drawer } from 'enums/variants';

import { AppBarHelp } from './AppBarHelp';

import { AppBarContext } from '../AppBarContext';
import { ToolbarBottom } from '../AppBarStyled';

export const AppBarSlideMenu = ({ classes, children: menuItems }) => {
  const {
    toggleDrawer,
    isMenuOpen,
    isAuthenticated,
    tenantSettings: { bankName }
  } = useContext(AppBarContext);

  return (
    <React.Fragment>
      <MuiDrawer
        open={isMenuOpen}
        onClose={toggleDrawer}
        className={classes.drawer}
        anchor={anchorOrigin.bottom}
        variant={drawer.temporary}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <AppBarHelp bankName={bankName} />
      </MuiDrawer>
      {isAuthenticated && <ToolbarBottom>{menuItems}</ToolbarBottom>}
    </React.Fragment>
  );
};
