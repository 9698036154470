import get from 'lodash/get';
import { createSelector, Selector } from '@reduxjs/toolkit';

import { Types } from 'actions/account';
import { RootState } from 'store/store';
import { Iban } from 'models/Iban';

import { formatIban } from 'utils/iban';

const registerIbanFormData: Selector<RootState, Iban> = ({ api }) =>
  get(api, [Types.REGISTER_IBAN, 'requestData'], {});

export const registerIbanResponseData: Selector<RootState, Iban> = ({ api }) => {
  const data = get(api, [Types.GET_IBAN, 'data'], {});

  return {
    ...data,
    bankName: data.newBankName
  };
};

export const ibanSelector: Selector<RootState, { formValues: Iban }> = createSelector(
  [registerIbanFormData, registerIbanResponseData],
  (registerIbanFormData, ibanResponseData) => {
    const formValues = {
      ...ibanResponseData,
      ...registerIbanFormData
    };

    formValues.iban = formatIban(formValues.iban ?? '');

    return {
      formValues
    };
  }
);
