import get from 'lodash/get';
import {
  all,
  call,
  put,
  select,
  PutEffect,
  CallEffect,
  SelectEffect,
  AllEffect
} from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { triggerError } from 'slices/errors';

import { localeSelector } from 'selectors/localeSelector';
import { httpRequest } from 'utils/request';

import { config } from 'config';

import { errorMessages } from 'enums/errorMessages';

import { Creators } from './actions';

const { apiRequest, apiError, apiErrorReset, apiResponse } = Creators;
export const INLINE = 'INLINE';
export const DIALOG = 'DIALOG';
export const NETWORK_ERROR = 'Network Error';

type ErrorType = 'INLINE' | 'DIALOG';

interface FetchSaga {
  params: {
    headers?: Record<string, string>;
    [key: string]: unknown;
  };
  actionType: string;
  payload?: Record<string, unknown> | null;
  fields?: string;
  errorType?: ErrorType;
  isBackgroundCall: boolean;
  isExactApiPath: boolean;
}

const { apiBaseUrl } = config;

export function* fetchSaga({
  params,
  actionType,
  payload = null,
  fields = '',
  errorType = DIALOG,
  isBackgroundCall,
  isExactApiPath = false
}: FetchSaga): Generator<
  PutEffect | CallEffect | SelectEffect | AllEffect<PutEffect>,
  Record<string, unknown> | true | null
> {
  if (!isBackgroundCall) {
    yield put(apiRequest(actionType, payload));
  }

  const { headers = {}, path } = params;
  // @ts-ignore
  const { lang } = yield select(localeSelector);
  headers['Accept-Language'] = lang ? lang : config.defaultLanguage;
  params.headers = { ...headers, ...config.headers };
  params.path = isExactApiPath ? path : `${apiBaseUrl}/${path}`;

  try {
    // @ts-ignore
    const { data } = yield call(httpRequest, params);
    const responseData = fields ? get(data, fields) : data;
    if (!isBackgroundCall) {
      yield put(apiResponse(actionType, responseData));
    }
    return responseData || true;
  } catch (error) {
    // @ts-ignore
    const { response = {}, message } = error as Error | AxiosError;

    if (message === NETWORK_ERROR) {
      yield all([
        put(apiErrorReset()),
        put(
          triggerError({
            message: errorMessages.networkUnavailable,
            options: {
              reload: true,
              redirected: true,
              networkUnavailable: true
            }
          })
        )
      ]);
    } else {
      const { data = {} } = response;
      const type = data.errors ? errorType : INLINE;

      if (!response.data && response.statusText) {
        const message = `${response.status} - ${response.statusText}`;
        yield put(triggerError({ message, options: { redirected: true } }));
      } else {
        yield put(apiError(actionType, response, type));
      }
    }
  }

  return null;
}
