import { isEmpty } from 'lodash';

import { Mandate } from 'models/Mandate';
import { Address } from 'models/Address';

import { localeKeys } from 'enums/localeKeys';

type SuggestedPaymentPartner = {
  id: string;
  name: string;
  logoUrl: string | null;
  address: {
    name: string;
    street: string;
    number: string;
    city: string;
    postal_code: string;
    country: string;
  };
};

export const getPaymentPartnerId = (
  suggestedPaymentPartners: SuggestedPaymentPartner[],
  paymentPartnerId: string
) => {
  const paymentPartner = suggestedPaymentPartners.find(partner => partner.id === paymentPartnerId);
  return paymentPartner?.id ?? '';
};

type Mandates = Record<string, Mandate[]>;

export const removeAdditionalFields = (mandateGroups: Mandates, fieldsToRemove: string[]) =>
  Object.entries(mandateGroups).reduce<Mandates>((accumulator, [groupName, mandates]) => {
    accumulator[groupName] = mandates.map(mandate => {
      return {
        ...mandate,
        additionalFields: mandate.additionalFields.filter(
          item => !fieldsToRemove.includes(item.name)
        )
      };
    });

    return accumulator;
  }, {});

interface GetButtonMandateLabel {
  switchedByAdvice: boolean;
  isIncomingPaymentPartner?: boolean;
  address: Address | null;
}

export const getButtonMandateLabel = ({
  switchedByAdvice,
  address,
  isIncomingPaymentPartner
}: GetButtonMandateLabel) => {
  if (switchedByAdvice) {
    return localeKeys.instructionsText;
  }

  if (isEmpty(address) || isIncomingPaymentPartner) {
    return localeKeys.identifyText;
  }

  return localeKeys.notifyText;
};
