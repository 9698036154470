import { Language, Locale } from 'locale/reducer';
import { LocaleKey } from 'locale/types';

type ApiValue = string | ((id: string) => string) | ((data: string, iv: string) => string);

type Apis = Record<string, ApiValue>;

export type DefaultConfig = {
  pageAnimationTimeout: number;
  langLocalStorageKey: (lang: Language) => string;
  langLocalStorageHashKey: (lang: Language) => string;
  defaultDelay: number;
  accountStatusPollingDelay: number;
  accountPollingDelay: number;
  registrationStatusPollingDelay: number;
  transferBalanceStatusPollingDelay: number;
  networkConnectionCheckDelay: number;
  networkConnectionErrorReloadDelay: number;
  errorDialogCloseClearErrorDelay: number;
  snackbarAutoHideDuration: number;
  sessionStatusPollingDelay: number;
  apiBaseUrl: string;
  defaultLanguage: string;
  informalLanguages: Record<string, string>;
  defaultLocale: Locale;
  defaultDateFormat: string;
  idCardIntegrationStyleToDisable: string;
  oldBankLoginFormLinkIdsToModal: Record<string, LocaleKey>;
  idCardAllowedFileTypes: string[];
  defaultStringTemplateDelimiter: RegExp;
  defaultFaviconPath: string;
  defaultFontFamily: string;
  defaultDrawerWidth: number;
  defaultLayoutWidth: string;
  defaultFontUrl: string;
  apis: Apis;
  tenantsWithoutCarousal: string[];
  tenantsWithSlideMenu: string[];
};

export const defaultConfig: DefaultConfig = {
  pageAnimationTimeout: 250,
  langLocalStorageKey: lang => `__MASK_${lang.toUpperCase()}_LANG__`,
  langLocalStorageHashKey: lang => `__MASK_${lang.toUpperCase()}_HASH__`,
  defaultDelay: 2000,
  accountStatusPollingDelay: 3000,
  accountPollingDelay: 10000,
  registrationStatusPollingDelay: 3000,
  transferBalanceStatusPollingDelay: 2000,
  networkConnectionCheckDelay: 2000,
  networkConnectionErrorReloadDelay: 500,
  errorDialogCloseClearErrorDelay: 500,
  snackbarAutoHideDuration: 6000,
  sessionStatusPollingDelay: 10000,
  apiBaseUrl: '/api/v1',
  defaultLanguage: 'en',
  informalLanguages: { de: 'gem' }, // PhraseAppLanguage is de-x-informal with "gem" (Germanic Language) as language code needed for api /locales/{id}
  defaultLocale: 'en-GB',
  defaultDateFormat: 'DD/MM/YYYY',
  idCardIntegrationStyleToDisable: 'caixa',
  oldBankLoginFormLinkIdsToModal: {
    pp: 'textsPrivacy',
    tos: 'textsTos',
    tosShort: 'textsTosShort'
  },
  idCardAllowedFileTypes: ['image/jpeg', 'image/png', 'application/pdf'],
  /**
   * String template default delimiter
   * @desc Delimiter is used to extract variable parts from string
   * templates. In this case default one is %{some_variable_name}
   */
  defaultStringTemplateDelimiter: /%{([\s\S]+?)}/g,
  defaultFaviconPath: '/defaultFavicon.ico',
  defaultFontFamily: `'Open Sans', sans-serif`,
  defaultDrawerWidth: 240,
  defaultLayoutWidth: '1400px',
  defaultFontUrl: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800',
  apis: {
    banks: 'banks',
    bankLogins: (id: string) => `bank_logins/${id}`,
    signIn: 'sign_in',
    signOut: 'sign_out',
    resetPassword: 'account/reset_password',
    passwordWithToken: 'account/password_with_token',
    registrations: 'registrations',
    registrationsStatus: 'registrations/status',
    registrationsMfa: 'registrations/mfa',
    registrationsRegShieldStart: 'registrations/regshield/start',
    registrationsRegShieldComplete: 'registrations/regshield/complete',
    account: 'account',
    accountIban: 'account/iban',
    accountEmail: 'account/email',
    accountPassword: 'account/password',
    accountStatus: 'account_status',
    accountSelect: 'account_select',
    accountSignature: 'account/signature',
    accountSignatureLink: 'account/signature/send_link',
    closeAccount: 'close_account',
    closeOldBankAccount: 'banks/close_account',
    idCard: 'account/signature',
    accountIdCard: 'account/id_card',
    tenantSettings: 'tenant',
    standingOrders: 'standing_orders',
    ignoreStandingOrders: (id: string) => `standing_orders/${id}/ignore`,
    acknowledgeStandingOrders: 'standing_orders/acknowledge',
    directDebits: 'direct_debits',
    directDebitsMultiple: 'mandates/transfer_multiple',
    paymentPartners: 'payment_partners',
    incomingPayments: 'incoming_payments',
    transferBalance: 'balance',
    balanceTransfers: 'balance_transfers',
    balanceTransfersFinalize: (id: string) => `balance_transfers/${id}/finalize`,
    transferringAccounts: '/api/v2/transferring_accounts',
    getLetter: (id: string) => `letters/${id}/pdf`,
    letterSummary: 'letters/summary',
    autoLogin: (data: string, iv: string) => `registrations?data=${data}&iv=${iv}`,
    hints: (id: string) => `hints/${id}`,
    createMandate: 'mandates',
    mandates: (id: string) => `mandates/${id}`,
    mandatesTransfer: (id: string) => `mandates/${id}/transfer`,
    mandatesMarkUndone: (id: string) => `mandates/${id}/mark_undone`,
    mandatesMarkDone: (id: string) => `mandates/${id}/mark_done`,
    mandatesIgnore: (id: string) => `mandates/${id}/ignore`,
    mandatesStatuses: (id: string) => `mandates/${id}/statuses`,
    locale: (lang: string) => `locales/${lang}`,
    locales: 'locales',
    featureToggles: 'feature_toggles',
    tenantIcons: 'tenant_icons',
    sessionStatus: 'session_status',
    redirectChallenge: 'redirect_challenge' // Proxy server endpoint
  },
  tenantsWithoutCarousal: ['n26'], // do not show carousal for these tenants
  tenantsWithSlideMenu: [] // show slide menu for these tenants
};
