import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SignOutIcon from '@mui/icons-material/PowerSettingsNew';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { history, routes } from 'routes';
import { t } from 'locale';

import { Creators } from 'actions/account';
import { DialogContentText } from 'components/Dialog/DialogStyled';
import { DrawerIconWrapper, DrawerTitle } from 'components/Drawer/DrawerStyled';
import { User } from 'components/Icons/User';
import { Modal as SignOutModal } from 'components/Modal/Modal';
import { Text } from 'components/Text/TextStyled';
import { appMenuSelector } from 'selectors/appSelectors';

import { localeKeys } from 'enums/localeKeys';
import { anchorOrigin, edge } from 'enums/variants';

import { AppBarContext } from '../AppBarContext';
import { MenuItemWrapper } from '../AppBarStyled';

const MenuItemContent = ({ icon, title, dataId }) => (
  <MenuItemWrapper>
    <DrawerIconWrapper>{icon}</DrawerIconWrapper>
    <DrawerTitle data-id={dataId}>{title}</DrawerTitle>
  </MenuItemWrapper>
);

const ProfileMenu = ({ fullName, signOut }) => {
  const {
    isRegisteredFromLink,
    tenantSettings: { magicLinkSettings }
  } = useContext(AppBarContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  const allowEditingPersonalData = isRegisteredFromLink
    ? magicLinkSettings.allowEditingPersonalData
    : true;
  const allowConventionalLogin = isRegisteredFromLink
    ? magicLinkSettings.allowConventionalLogin
    : true;

  const openProfileMenu = ({ currentTarget }) => setAnchorEl(currentTarget);
  const closeProfileMenu = () => setAnchorEl(null);
  const openPageAndCloseMenu = path => {
    history.push(path);
    closeProfileMenu();
  };
  const closeSignOutModal = () => {
    setIsSignOutModalOpen(false);
    closeProfileMenu();
  };

  return (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="User"
        edge={edge.end}
        onClick={openProfileMenu}
        data-id="app-bar-profile-menu"
      >
        <User style={{ height: '20px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeProfileMenu}
        anchorOrigin={{
          vertical: anchorOrigin.bottom,
          horizontal: anchorOrigin.right
        }}
      >
        <MenuItem divider disabled>
          <div>
            <Text data-id="app-bar-profile-menu-welcome-text">
              {t(localeKeys.appMenuHelloText)}
            </Text>
            <Text data-id="app-bar-profile-menu-username-text">{fullName}</Text>
          </div>
        </MenuItem>
        {allowEditingPersonalData && (
          <MenuItem
            data-id="app-bar-profile-menu-item-personal-details"
            onClick={() => openPageAndCloseMenu(routes.personalDetails.path)}
          >
            <MenuItemContent
              icon={<User />}
              title={t(localeKeys.appMenuPersonalDetailsText)}
              dataId="app-bar-profile-menu-item-personal-details-text"
            />
          </MenuItem>
        )}
        {allowConventionalLogin && (
          <MenuItem
            data-id="app-bar-profile-menu-item-email-and-password"
            onClick={() => openPageAndCloseMenu(routes.updateEmailPassword.path)}
          >
            <MenuItemContent
              icon={<LockOpenIcon data-id="app-bar-profile-menu-item-email-and-password-icon" />}
              title={t(localeKeys.appMenuChangeEmailPasswordText)}
              dataId="app-bar-profile-menu-item-email-and-password-text"
            />
          </MenuItem>
        )}
        <MenuItem
          data-id="app-bar-profile-menu-item-sign-out"
          onClick={() => setIsSignOutModalOpen(true)}
        >
          <MenuItemContent
            icon={<SignOutIcon data-id="app-bar-profile-menu-item-sign-out-icon" />}
            title={t(localeKeys.appMenuSignOut)}
            dataId="app-bar-profile-menu-item-sign-out-text"
          />
        </MenuItem>
      </Menu>
      <SignOutModal
        onClose={closeSignOutModal}
        isOpen={isSignOutModalOpen}
        title={t(localeKeys.signOutTitle)}
        showClose
        closeLabel={t(localeKeys.signOutCancelButton)}
        showConfirm
        confirmLabel={t(localeKeys.signOutContinueButton)}
        onConfirm={() => signOut()}
        showContentDividers={false}
      >
        <DialogContentText id="signout-dialog-description">
          {t(localeKeys.signOutMessage)}
        </DialogContentText>
      </SignOutModal>
    </React.Fragment>
  );
};

const mapStateToProps = state => appMenuSelector(state);

export const AppBarProfileMenu = connect(mapStateToProps, {
  signOut: Creators.signOut
})(ProfileMenu);
