import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { routes } from 'routes';
import { t } from 'locale';

import { MarkDown } from 'components/MarkDown';
import { H1Title, H2Title } from 'components/Text/TextStyled';
import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';
import { RootState } from 'store/store';

import { localeKeys } from 'enums/localeKeys';
import { tenantsWithCustomLandingText } from 'enums/tenantSpecificKeys';

import { parseStringAsTemplate, parseStringTranslationKeys } from 'utils/stringTemplate';

import { Content, Icon, ImageAndTitle, TitleDiv, getClasses } from './BannerStyled';

const Banner = ({ tenantSettings, location }: BannerPropsFromRedux & RouteComponentProps) => {
  const classes = getClasses();
  const tenantId = tenantSettings.tenantId;
  const defaultWelcomeText = parseStringAsTemplate(localeKeys.welcomeBankText, {
    bank_name: tenantSettings.bankName
  });

  const tenantCustomWelcomeText = t(tenantsWithCustomLandingText[tenantId]);

  const welcomeText = tenantCustomWelcomeText ? tenantCustomWelcomeText : defaultWelcomeText;

  const { pathname } = location;
  const showBanner = pathname === routes.oldBankSelection.path;

  const { mobileAsk: { loginCoverHtml } = {} } = tenantSettings;

  return (
    <>
      {showBanner && (
        <>
          <ImageAndTitle className={classes.banner} data-id="banner">
            <Icon data-id="banner-icon-wrapper">
              <Content data-id="banner-title" />
              <H2Title data-id="banner-welcome-text">{welcomeText}</H2Title>
            </Icon>
          </ImageAndTitle>
          {loginCoverHtml ? (
            <MarkDown
              text={parseStringTranslationKeys(loginCoverHtml)}
              htmlTag="div"
              data-id="tenant-login-cover"
              className="tenant-login-cover"
            />
          ) : (
            <TitleDiv className={classes.title} data-id="banner-welcome-text-wrapper">
              <H1Title data-id="banner-welcome-text">{welcomeText}</H1Title>
            </TitleDiv>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => tenantSettingsSelector(state);
const connector = connect(mapStateToProps, null);
type BannerPropsFromRedux = ConnectedProps<typeof connector>;

const BannerContainer = connector(withRouter(Banner));

export default BannerContainer;
