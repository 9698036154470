import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContentText from '@mui/material/DialogContentText';
import withStyles from '@mui/styles/withStyles';
import styled from 'styled-components';

const styles = {
  MuiDialog: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)'
    },
    paper: {
      width: '100%',
      margin: '48px 20px'
    }
  }
};

export const Dialog = withStyles(styles.MuiDialog)(MuiDialog);
export const DialogContentText = styled(MuiDialogContentText)`
  && {
    margin-bottom: 0;
    font-size: ${({ theme }) => theme.typography.fontSizes.SM};
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  && {
    padding: 12px 24px;
  }
`;
