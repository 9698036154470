export const storageKeys = {
  overview: 'overview_hint_closed',
  transferBalance: 'transfer_balance_hint_closed',
  directDebits: 'direct_debits_hint_closed',
  standingOrders: 'standing_orders_hint_closed',
  incomingPayments: 'incoming_payments_hint_closed',
  noBalance: 'no_balance_hint_closed',
  signUpCarousal: 'signUp_carousal',
  embedded: 'embedded_view',
  cookieConsent: '_MASK_COOKIE_CONSENT',
  HAS_IBAN_SCREEN: 'HAS_IBAN_SCREEN'
};
