import { Types } from 'actions/snackbar';

import { createReducer } from 'utils/redux';

const INITIAL_STATE = {};

const handlers = {
  [Types.TRIGGER_SNACK]: (state = INITIAL_STATE, action) => ({
    ...state,
    message: action.message,
    snackType: action.snackType
  }),
  [Types.CLEAR_SNACK]: (state = INITIAL_STATE) => INITIAL_STATE
};

export const snackbar = createReducer(INITIAL_STATE, handlers);
