import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { MainContainer } from './MainStyled';

export const Main = props => {
  const { children, theme } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <MainContainer>
          <CssBaseline />
          {children}
        </MainContainer>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

Main.propTypes = {
  theme: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

Main.defaultProps = {
  theme: {}
};
