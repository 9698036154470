import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';

export const StyledImage = styled.img`
  max-width: 100%;
  width: auto;
  height: auto;
`;

export class ImageLoader extends Component {
  state = {
    style: {
      display: 'none'
    }
  };

  showImage = () => {
    this.setState({
      style: {}
    });
  };

  hideImage = () => {
    this.setState({
      style: { display: 'none' }
    });
  };

  render() {
    const { src, imageStyle } = this.props;
    let { style } = this.state;

    style = { ...imageStyle, ...style };

    return (
      <StyledImage
        alt=""
        src={src}
        style={style}
        onLoad={this.showImage}
        onError={this.hideImage}
      />
    );
  }
}

ImageLoader.defaultProps = {
  imageStyle: {},
  src: ''
};

ImageLoader.propTypes = {
  imageStyle: PropTypes.object,
  src: PropTypes.string
};
