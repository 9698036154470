import styled from 'styled-components';
import color from 'color';

import { LinearProgress } from 'components/ProgressBar/ProgressBarStyled';

import { themeBreakPoints } from 'enums/keys';

export const ProgressBarContainer = styled.div`
  margin: 30px auto 0 auto;
  width: 100%;
  max-width: ${({ theme: { appMainContainer } }) => appMainContainer.maxWidth};
  ${LinearProgress} {
    height: 8px;
    width: 100%;
    border-radius: 0;
    @media (max-width: ${({ theme: { breakpoints } }) =>
        `${breakpoints.values[themeBreakPoints.sm]}px`}) {
      height: 6px;
    }

    .MuiLinearProgress-barColorPrimary {
      background-color: ${({ theme: { palette } }) => {
        return color(palette.primary.main).alpha(0.5);
      }};
    }
  }
`;
