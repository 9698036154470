const NAVIGATION_MESSAGE_HEIGHT = 40;

export const scrollToElementInModal = (elem: HTMLElement) => {
  const modal = elem.closest('div[role="dialog"]');

  if (modal) {
    const modalHeader = modal.querySelector('.MuiDialogTitle-root') as HTMLElement | null;
    const modalBody = modal.querySelector('.MuiDialogContent-root') as HTMLElement | null;
    if (modalBody && modalHeader) {
      modalBody.scrollTop = elem.offsetTop - modalHeader.offsetHeight - NAVIGATION_MESSAGE_HEIGHT;
    }
  }
};
