import { createActions } from 'utils/redux';

type ToggleSignatureModalPayload = {
  isSignatureModalOpen: boolean;
};

type ToggleIdCardModalPayload = {
  isIdCardModalOpen: boolean;
};

export type ToggleModalAction = {
  type: 'TOGGLE_MODAL';
  payload: ToggleSignatureModalPayload | ToggleIdCardModalPayload;
};

export interface ToggleModalActionCreator {
  (payload: ToggleSignatureModalPayload | ToggleIdCardModalPayload): void;
}

export const { Types, Creators } = createActions({
  toggleModal: ['payload']
});
