import styled from 'styled-components';
import { customColors } from 'theme';
import { ButtonProps } from '@mui/material/Button';

import { Button } from 'components/Buttons/ButtonsStyled';
import { Text } from 'components/Text/TextStyled';

interface DrawerRowLayoutProps extends ButtonProps {
  active: boolean;
  selected: boolean;
}

export const DrawerRowLayout = styled(
  ({ active: _active, selected: _selected, ...props }: DrawerRowLayoutProps) => (
    <Button {...props} />
  )
)`
  && {
    width: 100%;
    text-align: left;
    border-radius: 0;
    display: block;
    height: 60px;
    background-color: ${({ active }) => (active ? `${customColors.veryLightPink}` : '')};
    svg {
      ${({ theme: { palette }, selected }) => (selected ? `color: ${palette.primary.main}` : '')};
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      svg {
        color: ${({ theme: { palette } }) => palette.primary.main};
      }
    }
  }
`;

export const DrawerColumnLayout = styled(DrawerRowLayout)`
  && {
    background-color: transparent;
  }
`;

export const DrawerRowContent = styled.div`
  display: flex;
  padding: 10px 10px;
  align-items: center;
  img,
  svg {
    margin-right: 10px;
    width: 24px !important;
  }
`;

export const DrawerColumnContent = styled(DrawerRowContent)`
  flex-direction: column;
  text-align: center;
  padding: 0;
  ${Text} {
    font-size: ${({ theme }) => theme.typography.fontSizes.XS};
  }
`;

export const DrawerIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 32px;
  color: ${({ theme }) => theme.palette.greyishBrown};
`;

export const DrawerTitle = styled(Text).attrs({ variant: 'h3' })`
  && {
    display: flex;
    align-items: center;
    white-space: normal;
    img {
      margin-right: 10px;
    }
  }
`;

export const DrawerSubtitle = styled.span`
  font-size: ${({ theme: { typography } }) => typography.fontSizes.XS};
  color: ${({ theme: { palette } }) => palette.darkGrey};
`;
