import { defaultConfig, DefaultConfig } from './default';
import { DevelopmentConfig, developmentConfig } from './development';
import { ProductionConfig, productionConfig } from './production';

type Config = DefaultConfig & (DevelopmentConfig | ProductionConfig);

function getEnvConfig() {
  const config = import.meta.env.MODE === 'development' ? developmentConfig : productionConfig;

  return {
    ...defaultConfig,
    ...config
  };
}

export const config: Readonly<Config> = Object.freeze(getEnvConfig());
