export const colors = {
  default: 'default',
  inherit: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  error: 'error'
} as const;

export const sizes = {
  small: 'small',
  medium: 'medium'
} as const;
