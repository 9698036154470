import { drawer } from 'enums/variants';

import { Drawer } from '../AppBarStyled';

export const AppBarStickyMenu = ({ classes, modalProps, children: menuItems, isOpen }) => {
  return (
    <Drawer
      open={isOpen}
      className={classes.drawer}
      variant={drawer.persistent}
      ModalProps={modalProps}
    >
      {menuItems}
    </Drawer>
  );
};
