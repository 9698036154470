import { Translations } from 'locale/types';

import { createActions } from 'utils/redux';

export type SetLangAction = {
  payload: {
    lang: string;
    informalLang: string;
    tenantInformal: boolean;
  };
};

export type SetLocaleAction = {
  locale: string;
  translations: Translations;
};

export interface GetLocale {
  (lang: string): void;
}

export const { Types, Creators } = createActions({
  getLocaleHash: [],
  setLocale: ['locale', 'translations'],
  setLang: ['payload'],
  getLocale: ['lang']
});
