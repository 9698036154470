import { all } from 'redux-saga/effects';

import { apiErrorHandlingSaga } from 'api/apiErrorHandlingSaga';
import { getLocaleSaga } from 'locale/localeSaga';
import { getMandateSagas } from 'sagas/mandates';
import { userLoginSaga } from 'sagas/userLogin';

import { accountAnalysisSagas } from './accountAnalysis';
import { appSaga } from './app';
import { banksSaga } from './banks';
import { closeAccountSaga } from './closeAccount';
import { ibanSagas } from './iban';
import { idCardSaga } from './idCard';
import { loginDetailsSagas } from './loginDetails';
import { manualAccountSwitchSaga } from './manualAccountSwitch';
import { mfaSagas } from './mfa';
import { oldBankDataSaga } from './oldBankLoginData';
import { oldBankSagas } from './oldBankSagas';
import { paymentPartnerSaga } from './paymentPartner';
import { personalDetailsSagas } from './personalDetails';
import { resetPasswordSaga } from './resetPassword';
import { signatureSaga } from './signature';
import { signOutSaga } from './signout';
import { getStandingOrdersSaga } from './standingOrders';
import { getTransferBalanceSaga } from './transferBalance';

export function* sagas() {
  yield all([
    appSaga(),
    accountAnalysisSagas(),
    banksSaga(),
    oldBankSagas(),
    userLoginSaga(),
    oldBankDataSaga(),
    mfaSagas(),
    ibanSagas(),
    personalDetailsSagas(),
    loginDetailsSagas(),
    getStandingOrdersSaga(),
    getTransferBalanceSaga(),
    paymentPartnerSaga(),
    signatureSaga(),
    idCardSaga(),
    resetPasswordSaga(),
    getLocaleSaga(),
    closeAccountSaga(),
    signOutSaga(),
    apiErrorHandlingSaga(),
    getMandateSagas(),
    manualAccountSwitchSaga()
  ]);
}
